import React from 'react';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmDocument from '../../components/CrmDocument';
import policyPDF from '../../images/policyPDF.pdf';

const Policy = () => {
    return (
        <CrmDocument>
            <CrmCenteredTitle
                title={'Политика обработки  персональных данных'}
            />
            <iframe
                src={`${policyPDF}#view=fitH`}
                title={'Политика обработки  персональных данных'}
                className={'crm-pdf'}
            ></iframe>
        </CrmDocument>
    );
};

export default Policy;
