import React from 'react';
import {
    MailOutlined,
    PhoneOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';

const getPhoneLink = (phoneNumber) => {
    let pn = '';
    if (phoneNumber !== null) {
        pn = phoneNumber.replace(/[^0-9]/g, '');
    }
    return `tel://${pn}`;
};
const getMailink = (mail) => {
    return `mailto:${mail}`;
};
const getWhatsappLink = (phoneNumber) => {
    let pn = '';
    if (phoneNumber !== null) {
        pn = phoneNumber.replace(/[^0-9]/g, '');
    }
    return `https://wa.me/${pn}`;
};

export default function CrmContact(props) {
    const { type, value } = props;

    let button = null;

    if (type === 'phoneWhatsapp') {
        button = (
            <>
                <Button
                    className={'buttonContactPhone'}
                    size={'large'}
                    type={'primary'}
                    shape={'circle'}
                    icon={<PhoneOutlined />}
                    onClick={() => {
                        const link = getPhoneLink(value);
                        window.open(link, '_self').focus();
                        return;
                    }}
                />
                <Button
                    className={'buttonContactWhatsapp'}
                    size={'large'}
                    type={'primary'}
                    shape={'circle'}
                    icon={<WhatsAppOutlined />}
                    onClick={() => {
                        const link = getWhatsappLink(value);
                        window.open(link, '_blank').focus();
                        return;
                    }}
                />
            </>
        );
    }
    if (type === 'phone') {
        button = (
            <Button
                className={'buttonContactPhone'}
                size={'large'}
                type={'primary'}
                shape={'circle'}
                icon={<PhoneOutlined />}
                onClick={() => {
                    const link = getPhoneLink(value);
                    window.open(link, '_self').focus();
                    return;
                }}
            />
        );
    }
    if (type === 'email') {
        button = (
            <Button
                className={'buttonContactMail'}
                size={'large'}
                type={'primary'}
                shape={'circle'}
                icon={<MailOutlined />}
                onClick={() => {
                    const link = getMailink(value);
                    window.open(link, '_self').focus();
                    return;
                }}
            />
        );
    }
    if (type === 'whatsapp') {
        button = (
            <Button
                className={'buttonContactWhatsapp'}
                size={'large'}
                type={'primary'}
                shape={'circle'}
                icon={<WhatsAppOutlined />}
                onClick={() => {
                    const link = getWhatsappLink(value);
                    window.open(link, '_blank').focus();
                    return;
                }}
            />
        );
    }

    return (
        <Space size={10}>
            {button}
            <span>{value}</span>
        </Space>
    );
}
