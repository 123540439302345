import React, { useState } from 'react';
import {
    MailOutlined,
    MinusCircleOutlined,
    PhoneOutlined,
    PlusOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Button, DatePicker, Divider, Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import debounce from 'debounce-promise';
import moment from 'moment';

import SelectSubjects from '../../components/Form/SelectSubjects';
import translit from '../../helpers/translit';

const QUERY_EMAIL = gql`
    query email($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(filters: $filters) {
            data {
                id
                attributes {
                    email
                }
            }
        }
    }
`;

export default function TrainerForm({
    data,
    onSubmit,
    loading,
    withEmail,
    withSubject,
    actionName,
    actionIcon,
}) {
    const [_formData, setFormData] = useState(data);
    const [customEmail, setCustomEmail] = useState(false);

    const [form] = Form.useForm();

    const queryEmail = useQuery(QUERY_EMAIL, { skip: true });

    const onFinish = (data) => {
        onSubmit(data);
        form.resetFields();
    };

    const onFormChange = () => {
        setFormData(form.getFieldValue('contacts'))
    };

    const onNameChange = () => {
        const firstName = form.getFieldValue('firstName');
        const lastName = form.getFieldValue('lastName');

        if (firstName && lastName) {
            const username = `${translit(firstName)}-${translit(lastName)}`;
            if (!customEmail) {
                form.setFieldsValue({ email: `${username}@proschoolnsk.ru` });
                form.validateFields(['email']);
            }
        }
    };

    const request = debounce(async (value) => {
        const res = await queryEmail.refetch({
            filters: { email: { eq: value } },
        });
        if (res.data.usersPermissionsUsers.data.length > 0) {
            return Promise.resolve({ exist: true });
        } else {
            return Promise.resolve({ exist: false });
        }
    }, 1000);

    let newData = { ...data };
    newData.birthDate = data && data.birthDate && moment(data.birthDate);

    return (
        <Form
            name="child"
            form={form}
            onFinish={onFinish}
            initialValues={newData}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'lastName'}
                label={'Фамилия'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input placeholder="Иванов" onChange={onNameChange} />
            </Form.Item>

            <Form.Item
                name={'firstName'}
                label={'Имя'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input placeholder="Константин" onChange={onNameChange} />
            </Form.Item>

            <Form.Item name={'middleName'} label={'Отчество'}>
                <Input placeholder="Сергеевич" onChange={onNameChange} />
            </Form.Item>

            {withEmail && (
                <>
                    <Divider>Аккаунт для входа</Divider>

                    <Form.Item
                        name={'email'}
                        label={'Email для входа'}
                        required
                        hasFeedback
                        validateFirst={true}
                        rules={[
                            { required: true, message: 'Заполните поле' },
                            {
                                type: 'email',
                                message: 'Введите корректный email',
                            },
                            () => ({
                                validator(rule, value) {
                                    return new Promise((resolve, reject) => {
                                        request(value).then((value) => {
                                            if (value.exist) {
                                                reject(
                                                    'Пользователь с таким email уже есть',
                                                );
                                            } else {
                                                resolve();
                                            }
                                        });
                                    });
                                },
                            }),
                        ]}
                    >
                        <Input
                            placeholder="name@example.com"
                            onChange={() => {
                                setCustomEmail(true);
                            }}
                        />
                    </Form.Item>
                </>
            )}

            <Divider>Личные контакты</Divider>

            <Form.List name="contacts">
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <div key={key} className={'form-contact'}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'type']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Выберите тип контакта',
                                        },
                                    ]}
                                    className={'form-contact-select'}
                                >
                                    <Select onSelect={onFormChange}>
                                        <Select.Option value="phoneWhatsapp">
                                            <PhoneOutlined />{' '}
                                            <WhatsAppOutlined /> Телефон +
                                            WhatsApp
                                        </Select.Option>
                                        <Select.Option value="phone">
                                            <PhoneOutlined /> Телефон
                                        </Select.Option>
                                        <Select.Option value="whatsapp">
                                            <WhatsAppOutlined /> WhatsApp
                                        </Select.Option>
                                        <Select.Option value="email">
                                            <MailOutlined /> Почта
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                {form.getFieldValue('contacts')[key] &&
                                form.getFieldValue('contacts')[key].type !==
                                    'email' ? (
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Заполните поле',
                                                    },
                                                ]}
                                                className={'form-contact-input'}
                                                required
                                            >
                                                <MaskedInput
                                                    onChange={onFormChange}
                                                    mask={'+7 (111) 111-11-11'}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Заполните поле',
                                                    },
                                                    {
                                                        type: 'email',
                                                        message:
                                                    'Введите корректный email',
                                                    },
                                                ]}
                                                className={'form-contact-input'}
                                                required
                                            >
                                                <Input onChange={onFormChange}/>
                                            </Form.Item>
                                        )}

                                <Form.Item>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => remove(name)}
                                        type={'dashed'}
                                    />
                                </Form.Item>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            />
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>

            {withSubject && (
                <Form.Item name={'subjects'} label={'Предметы'}>
                    <SelectSubjects />
                </Form.Item>
            )}

            <Form.Item name={'birthDate'} label={'Дата рождения'}>
                <DatePicker format={'DD.MM.YYYY'} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
