import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Empty, Modal, Skeleton, Space, Tabs } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmAvatar from '../../components/CrmAvatar';
import CrmCard from '../../components/CrmCard';
import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmContact from '../../components/CrmContact';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { getValidatedContacts } from '../../helpers/contactsValidate';
import roleLocation from '../../helpers/roleLocation';
import {
    child_edit,
    CHILDREN,
    NOT_FOUND,
    parent_info,
    progress,
} from '../../routes';
import ClientAddComment from '../Client/ClientAddComment';
import ClientMarksAndVisits from '../Client/ClientMarksAndVisits';
import ClientMessage from '../Client/ClientMessage';

import IikoInfo from './iiko/IikoInfo';
import ChildAccount from './ChildAccount';
import ChildParentsSelectAndAdd from './ChildParentsSelectAndAdd';

import classes from './Child.module.scss';

const GET_CLIENT_INFO = gql`
    query client(
        $id: ID
        $parfilters: ParentFiltersInput
        $mesfilter: MessageFiltersInput
    ) {
        client(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    birthDate
                    avatarUrl
                    enabled
                    type
                    color
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    account {
                        data {
                            id
                            attributes {
                                contacts {
                                    type
                                    value
                                }
                            }
                        }
                    }
                    anket {
                        contraindications
                        experience
                        fullFamily
                        gadgetTime
                        mainGoal
                    }
                    enabled
                    indicator(pagination: { limit: 200 }) {
                        id
                        name
                        startValue
                        goal
                        result(pagination: { limit: 200 }) {
                            id
                            date
                            value
                        }
                    }
                    skill {
                        id
                        name
                        own
                    }
                    messages(filters: $mesfilter) {
                        data {
                            id
                            attributes {
                                author {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                message
                                fileUrl
                                date
                            }
                        }
                    }
                    account {
                        data {
                            id
                            attributes {
                                email
                                blocked
                                contacts {
                                    type
                                    value
                                }
                                group {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        parents(filters: $parfilters) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    enabled
                    role
                    birthDate
                    color
                    avatar {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    account {
                        data {
                            attributes {
                                contacts {
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_QUARTER = gql`
    query quarters($filters: QuarterFiltersInput) {
        quarters(filters: $filters) {
            data {
                id
                attributes {
                    startDate
                    endDate
                }
            }
        }
    }
`;

const DELETE_CLIENT = gql`
    mutation updateClient($data: ClientInput!, $id: ID!) {
        updateClient(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const { TabPane } = Tabs;

export default function ChildInfo() {
    const {     id } = useParams();

    const navigate = useNavigate();

    const query = useQuery(GET_CLIENT_INFO, {
        variables: {
            id: id,
            parfilters: {
                clients: {
                    id: {
                        eq: id,
                    },
                },
            },
            subfilter: {
                and: [
                    {
                        user: {
                            id: {
                                eq: id,
                            },
                        },
                    },
                    {
                        endDate: {
                            gte: moment()
                                .subtract(1, 'month')
                                .format('YYYY-MM-DD'),
                        },
                    },
                ],
            },
            mesfilter: {
                or: [
                    {
                        enabled: { eq: true },
                    },
                    {
                        enabled: { null: true },
                    },
                ],
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const queryQuarter = useQuery(GET_QUARTER, {
        variables: {
            filters: {
                and: [
                    { startDate: { lte: moment().format('YYYY-MM-DD') } },
                    { endDate: { gte: moment().format('YYYY-MM-DD') } },
                ],
            },
        },
    });

    const [deleteMutation] = useMutation(DELETE_CLIENT);
    const onDelete = function () {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: `Действительно удалить клиента ${query.data.client.data.attributes.firstName} ${query.data.client.data.attributes.lastName}?`,
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete();
            },
        });
    };

    async function onConfirmedDelete() {
        await deleteMutation({
            variables: {
                id: id,
                data: {
                    enabled: false,
                },
            },
        });
        navigate(CHILDREN);
    }

    let displayTitle = '–';
    let contacts = null;
    let messages = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    if (query.data) {
        // если такого клиента нет, то отправляем в 404
        if (!query.data.client.data) {
            navigate(NOT_FOUND, { replace: true });
            return null;
        }

        const client = query.data.client.data;

        const firstName = client.attributes.firstName;
        const lastName = client.attributes.lastName;
        const middleName = client.attributes.middleName;

        displayTitle = middleName
            ? `${lastName} ${firstName} ${middleName}`
            : `${firstName} ${lastName}`;

        // Контакты
        if (
            query.data.client.data.attributes.account.data.attributes.contacts
                .length > 0
        ) {
            contacts = (
                <>
                    {/*<Divider>Контакты</Divider>*/}
                    <Space direction={'vertical'} size={10}>
                        {_.map(
                            query.data.client.data.attributes.account.data
                                .attributes.contacts,
                            (value, index) => {
                                return (
                                    <CrmContact
                                        key={index}
                                        type={value.type}
                                        value={getValidatedContacts(
                                            value.value,
                                        )}
                                    />
                                );
                            },
                        )}
                    </Space>
                </>
            );
        }

        // Комментарии
        messages = query.data.client.data.attributes.messages.data.map(
            function (value, index) {
                return (
                    <ClientMessage
                        key={index}
                        message={value.attributes}
                        id={value.id}
                        onUpdate={query.refetch}
                    />
                );
            },
        );
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={displayTitle}
                editAction={
                    RoleFunction.updateClient() &&
                    (() => {
                        navigate(child_edit(id));
                    })
                }
                deleteAction={
                    RoleFunction.deleteClient() &&
                    (() => {
                        onDelete();
                    })
                }
            />

            {query.loading && <Skeleton active />}

            {query.data && (
                <Space direction="vertical" size={20} className="block">
                    <div className={classes.avatar_wrap}>
                        <CrmAvatar
                            className={classes.child_avatar}
                            size={'large'}
                            firstName={
                                query.data.client.data.attributes.firstName
                            }
                            lastName={
                                query.data.client.data.attributes.lastName
                            }
                            type={'child'}
                            color={query.data.client.data.attributes.color}
                            avatarUrl={
                                query.data.client.data.attributes.avatar.data &&
                                query.data.client.data.attributes.avatar.data
                                    .attributes.previewUrl
                            }
                        />
                        <Button
                            size={'large'}
                            type={'link'}
                            className={classes.avatar_button}
                            onClick={() =>
                                navigate(
                                    progress(
                                        queryQuarter.data.quarters.data[0].id,
                                        query.data.client.data.attributes
                                            .account.data.attributes.group.data
                                            .id,
                                        id,
                                    ),
                                )
                            }
                        >
                            Успеваемость
                        </Button>
                    </div>

                    {contacts}

                    {query.data.client.data.attributes.account.data.attributes
                        .group.data && (
                        <CrmDisplayData
                            label="Класс"
                            value={
                                query.data.client.data.attributes.account.data
                                    .attributes.group.data.attributes.name
                            }
                        ></CrmDisplayData>
                    )}

                    {query.data.client.data.attributes.birthDate && (
                        <CrmDisplayData
                            label={'Дата рождения'}
                            value={
                                moment(
                                    query.data.client.data.attributes.birthDate,
                                ).format('DD MMMM YYYY') +
                                ', ' +
                                moment().diff(
                                    query.data.client.data.attributes.birthDate,
                                    'years',
                                ) +
                                ' лет'
                            }
                        />
                    )}

                    <div>
                        <Divider>Родители</Divider>

                        <Space
                            direction={'vertical'}
                            size={10}
                            className={'block'}
                        >
                            {_.map(query.data.parents.data, (value) => {
                                return (
                                    <CrmCard
                                        key={value.id}
                                        className={'crm-contact'}
                                    >
                                        <Space direction={'vertical'} size={10} className={classes.parent_card}>
                                            <Link
                                                to={parent_info(value.id)}
                                            >
                                                <CrmAvatar
                                                    className={classes.parent_avatar}
                                                    size={'large'}
                                                    firstName={
                                                        value.attributes
                                                            .firstName
                                                    }
                                                    lastName={
                                                        value.attributes
                                                            .lastName
                                                    }
                                                    middleName={
                                                        value.attributes
                                                            .middleName
                                                    }
                                                    color={
                                                        value.attributes.color
                                                    }
                                                    avatarUrl={
                                                        value.attributes.avatar
                                                            .data &&
                                                        value.attributes.avatar
                                                            .data.attributes
                                                            .previewUrl
                                                    }
                                                    caption={roleLocation(
                                                        value.attributes.role,
                                                    )}
                                                />
                                            </Link>

                                            {_.map(
                                                value.attributes.account.data
                                                    .attributes.contacts,
                                                (value, index) => {
                                                    return (
                                                        <CrmContact
                                                            key={index}
                                                            type={value.type}
                                                            value={getValidatedContacts(
                                                                value.value,
                                                            )}
                                                        />
                                                    );
                                                },
                                            )}
                                        </Space>
                                    </CrmCard>
                                );
                            })}
                        </Space>
                    </div>

                    <ChildParentsSelectAndAdd
                        className={classes.ChildParentsSelectAndAdd}
                        childId={id}
                        onUpdate={query.refetch}
                        parents={query.data.parents.data}
                    />

                    <Tabs defaultActiveKey="markAndVisits">
                        <TabPane key="markAndVisits" tab={'Оценки и посещения'}>
                            {query.data.client.data.attributes.account.data
                                .attributes.group.data && (
                                <ClientMarksAndVisits
                                    groupId={
                                        query.data.client.data.attributes
                                            .account.data.attributes.group.data
                                            .id
                                    }
                                    clientId={id}
                                />
                            )}
                        </TabPane>

                        <TabPane key="comments" tab={'Комментарии'}>
                            <Space
                                direction="vertical"
                                size={20}
                                className="block"
                            >
                                {messages}
                                <ClientAddComment
                                    clientId={id}
                                    onUpdate={query.refetch}
                                />
                            </Space>
                        </TabPane>

                        {RoleFunction.updateClientAccount() && (
                            <TabPane key="account" tab={'Аккаунт'}>
                                <ChildAccount
                                    data={
                                        query.data.client.data.attributes
                                            .account.data
                                    }
                                    onUpdate={query.refetch}
                                />
                            </TabPane>
                        )}

                        {RoleFunction.readClientNutrition() && (
                            <TabPane key="iiko" tab={'Питание'}>
                                <IikoInfo clientId={id} />
                            </TabPane>
                        )}
                    </Tabs>
                </Space>
            )}
        </CrmLayout>
    );
}
