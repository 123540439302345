import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Divider, Empty, message, Skeleton } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import CrmAvatar from '../../components/CrmAvatar';
import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import FilterGroups from '../../components/Filters/FilterGroups';
import Filters from '../../components/Filters/Filters';
import FilterSearch from '../../components/Filters/FilterSearch';
import { child_info, group_info } from '../../routes';

import '../../styles/grid.scss';

const QUERY = gql`
    query query($filters: ClientFiltersInput) {
        clients(
            filters: $filters
            pagination: { limit: 10000 }
            sort: ["lastName:ASC", "firstName:ASC"]
        ) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                    account {
                        data {
                            id
                            attributes {
                                group {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function getFilters(group, search) {
    let filters = {
        enabled: { eq: true },
    };

    if (group !== '0') {
        filters.account = { group: { id: { eq: group } } };
    }

    if (search !== '') {
        filters.or = [
            { firstName: { contains: search } },
            { lastName: { contains: search } },
        ];
    }

    return filters;
}

function Children() {
    const [filterGroup, setFilterGroup] = useState('0');
    const [filterSearch, setFilterSearch] = useState('');

    const [data, setData] = useState([]);

    const query = useQuery(QUERY, {
        variables: { filters: getFilters(filterGroup, filterSearch) },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            // let newData = [...data.usersPermissionsUsers.data];
            //
            const groupedUsers = _.groupBy(
                data.clients.data,
                'attributes.account.data.attributes.group.data.id',
            );

            let newData = [];
            _.map(data.clients.data, (client) => {
                const user = client.attributes.account.data;
                const group = user.attributes.group.data;
                const groupId = group ? group.id : undefined;

                if (_.find(newData, { id: groupId }) === undefined) {
                    newData.push({
                        id: groupId,
                        name:
                            groupId === undefined
                                ? 'Не указан класс'
                                : group.attributes.name,
                        clients: groupedUsers[groupId],
                    });
                }
            });

            setData(newData);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Ученики'} />

            <Filters>
                <FilterGroups
                    className={'line-3-8col'}
                    value={filterGroup}
                    withAll
                    onChange={(value) => setFilterGroup(value)}
                />
                <FilterSearch
                    className={'line-5-8col'}
                    onSearch={(value) => setFilterSearch(value)}
                />
            </Filters>

            {query.loading && <Skeleton active />}
            {data.length === 0 && !query.loading && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {_.map(data, (group, index) => {
                // console.log('group', group);
                const clients = group.clients;

                return (
                    <React.Fragment key={index}>
                        {group.id ? (
                            <Link to={group_info(group.id)}>
                                <Divider>
                                    {group.name} ({clients.length})
                                </Divider>
                            </Link>
                        ) : (
                            <Divider>
                                {group.name} ({clients.length})
                            </Divider>
                        )}
                        {_.map(clients, (client) => {
                            return (
                                <div
                                    className="group_user_item"
                                    key={client.id}
                                >
                                    <Link to={child_info(client.id)}>
                                        <CrmAvatar
                                            size={'large'}
                                            firstName={
                                                client.attributes.firstName
                                            }
                                            lastName={
                                                client.attributes.lastName
                                            }
                                            color={client.attributes.color}
                                        />
                                    </Link>
                                </div>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </CrmLayout>
    );
}

export default observer(Children);
