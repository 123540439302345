import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

import logo from '../../images/Logo.svg';
import { child_info } from '../../routes';

export default function PaymentError() {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <div className="crm-login-layout dissolved">
            <div className="crm-login-logo">
                <img src={logo} className="App-logo" alt="logo" />
            </div>

            <Space direction="vertical" size={20}>
                <div className={'crm-payment-icon color-danger'}>
                    <CloseCircleOutlined />
                </div>

                <div className={'title color-danger'}>Ошибка при оплате</div>

                <p>
                    Попробуйте еще раз <br />
                    или обратитесь к администратору
                    <br />
                    для повторной оплаты
                </p>

                <Button
                    type={'primary'}
                    size={'large'}
                    onClick={() => navigate(child_info(id))}
                >
                    Хорошо
                </Button>
            </Space>
        </div>
    );
}
