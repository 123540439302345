import { useInstance } from 'react-ioc';
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';

import { Store } from '../../model/store/Store';

const ME = gql`
    query usersPermissionsUser($id: ID!) {
        usersPermissionsUser(id: $id) {
            data {
                id
                attributes {
                    parent {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                    trainer {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                    firstName
                    lastName
                    color
                }
            }
        }
        me {
            role {
                id
                name
            }
        }
    }
`;

function AboutMe ({children}) {
    const { auth } = useInstance(Store);

    const skip = auth.id === null;

    useQuery(ME, {
        variables: {id: auth.id},
        skip: skip,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.usersPermissionsUser.data && data.me) {

                const user = data.usersPermissionsUser.data;
                const me = data.me;

                const role = me.role.name;
                let person = null;
                if (role === 'Parent') {
                    person = user.attributes.parent.data;
                }
                if (role === 'Child') {
                    person = user.attributes.client.data;
                }
                if (
                    role === 'Teacher' ||
                    role === 'Admin' ||
                    role === 'Curator'
                ) {
                    person = user.attributes.trainer.data;
                }
                if (role === 'Authenticated') {
                    person = user;
                }

                const authData = {
                    firstName: person.attributes.firstName,
                    lastName: person.attributes.lastName,
                    color: person.attributes.color || '',
                    personId: person.id,
                };

                auth.setRoleAndData(role, authData);

                auth.setRoleAndData(role, authData);

            } else {
                console.log('Ошибка авторизации');
                auth.setAuthError(true);
            }

        },
        onError: () => {
            console.log('Ошибка авторизации');
            auth.setAuthError(true);
        }
    });

    return children;
}

export default observer(AboutMe);
