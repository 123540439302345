import React from 'react';
import { Button, Form, Input } from 'antd';

import SelectTeacher from '../../components/Form/SelectTeacher';

export default function GroupForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            name="group"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'name'}
                label={'Название'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item name={'curator'} label={'Куратор'}>
                <SelectTeacher />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
