import React from 'react';
import {Button, Form, Input} from 'antd';

import SelectSameTrainings from '../../components/Form/SelectSameTrainings';

export default function HomeworkForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
}) {
    const [form] = Form.useForm();

    const {TextArea} = Input;

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            name="task"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'name'}
                label={'Задание'}
                rules={[{required: true, message: 'Заполните поле'}]}
            >
                <Input placeholder={'Текст задания'}/>
            </Form.Item>

            <Form.Item name={'comment'} label={'Комментарий'}>
                <TextArea
                    autoSize={{minRows: 2, maxRows: 5}}
                    placeholder={'Комментарий'}
                />
            </Form.Item>

            <Form.Item
                name={'trainingTo'}
                label={'К уроку'}
                rules={[{required: true, message: 'Заполните поле'}]}
            >
                <SelectSameTrainings/>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
