import React from 'react';
import { Space } from 'antd';
import cn from 'classnames';

export default function CrmDisplayData({ label, value, children, block }) {
    return (
        <div className={cn([block && 'block', 'crm-display-data'])}>
            <Space direction="vertical" size={2} className={block && 'block'}>
                <span className="crm-display-data-key">{label}</span>
                <span className="crm-display-data-value">{value}</span>
                {children}
            </Space>
        </div>
    );
}
