import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Input, message, Modal, Skeleton, Space } from 'antd';
import _ from 'lodash';
import map from 'lodash/map';
import moment from 'moment';

import CrmAvatar from '../../components/CrmAvatar';
import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';
import { lessons } from '../../config/lessons';
import RoleFunction from '../../config/roles';
import {
    INDEX,
    journal,
    NOT_FOUND,
    trainer_info,
    training_edit,
} from '../../routes';
import { ChildHomework } from '../Homework/ChildHomework';

import TrainingVisits from './Visit/TrainingVisits';

const QUERY = gql`
    query training ($id: ID!) {
        getTraining(id: $id) {
            training {
                id
                attributes {
                    name
                    startDate
                    comment
                    enabled
                    trainers {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            middleName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    room {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    subject {
                        data {
                            id
                            attributes {
                                name
                                color
                            }
                        }
                    }
                    group {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
            children {
                id
                attributes {
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                }
            }
        }
    }
`;


const UPDATE_TRAINING = gql`
    mutation updateTraining($data: TrainingInput!, $id: ID!) {
        updateTraining(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;
const UPDATE_TRAININGS = gql`
    mutation updateTraining($data: TrainingInput!, $id: ID!, $massEdit: Boolean!) {
        updateTrainings(data: $data, id: $id, massEdit: $massEdit) {
            data {
                id
            }
        }
    }
`;

export default function TrainingInfo() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [data, setData] = useState();

    const [state, setState] = useState({
        name: '',
        comment: '',
        lessonId: 0,
        groupId: 0,
        subjectId: 0,
    });

    // запрос
    const query = useQuery(QUERY, {
        variables: {
            id: id,
        },
        onCompleted: (data) => {
            setData(data.getTraining)
            setState({
                name: data.getTraining.training.attributes.name,
                comment: data.getTraining.training.attributes.comment,
                subjectId: data.getTraining.training.attributes.subject.data.id,
                lessonId: id,
                groupId: data.getTraining.training.attributes.group.data.id,
            });
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка загрузки данных урока');
        },
    });

    const [updateTraining, updateTrainingData] = useMutation(UPDATE_TRAINING);
    const [updateTrainings] = useMutation(UPDATE_TRAININGS);

    useEffect(() => {
        query.refetch();
    }, []);

    let displayName = '...';
    let displayDate = null;

    let displayUser = null;

    let displayRoom = null;
    let displaySubject = null;
    let displayGroup = null;

    function setDate(queryDate) {
        let datOfWeek = moment(queryDate).format('dddd');
        datOfWeek = datOfWeek.charAt(0).toUpperCase() + datOfWeek.slice(1);

        let lessonNumber = 0;
        _.find(lessons, ({ start }, i) => {
            if (moment(queryDate).format('H:mm') === start) {
                lessonNumber = i;
                return true;
            }
        });

        const lessonDate = moment(queryDate).startOf('week');
        const todayWeekDate = moment().startOf('week');
        if (lessonDate.diff(todayWeekDate, 'week') === 0) {
            return `${datOfWeek}, ${lessons[lessonNumber].name}`;
        }

        return `${datOfWeek} (${moment(queryDate).format('DD MMM YYYY')}), ${
            lessons[lessonNumber].name
        }`;
    }

    if (data !== undefined) {
        if (data.training !== null) {
            const training = data.training;

            const room = training.attributes.room.data;
            const subject = training.attributes.subject.data;
            const group = training.attributes.group.data;

            if (training.attributes.enabled === false) {
                navigate(NOT_FOUND, { replace: true });
                return null;
            }

            console.log('subject', subject);

            displayName =
                training.attributes.name ||
                subject ? subject.attributes.name : 'Урок';

            displayDate = setDate(
                query.data.getTraining.training.attributes.startDate,
            );

            if (room) {
                displayRoom = (
                    <CrmDisplayData
                        label="Кабинет"
                        value={`${room.attributes.name}`}
                    />
                );
            }

            if (subject) {
                displaySubject = (
                    <CrmDisplayData
                        label="Предмет"
                        value={subject.attributes.name}
                    />
                );
            }

            if (group) {
                let trainerId = 0;
                const trainers =
                    query.data.getTraining.training.attributes.trainers.data;
                if (trainers.length) {
                    const trainer = trainers[0];
                    trainerId = trainer.id;
                }
                const groupId = group.id;
                const subjectId = subject ? subject.id : 0;

                displayGroup = (
                    <div className={'crm-line'}>
                        <CrmDisplayData
                            label="Класс"
                            value={group.attributes.name}
                        />
                        {RoleFunction.readJournal() && (
                            <Button
                                onClick={() =>
                                    navigate(
                                        journal(
                                            0,
                                            trainerId,
                                            groupId,
                                            subjectId,
                                        ),
                                    )
                                }
                            >
                                Перейти в журнал
                            </Button>
                        )}
                    </div>
                );
            }

            if (training.attributes.trainers.data !== null) {
                displayUser = (
                    <CrmDisplayData
                        block
                        label="Преподаватель"
                        value={
                            <Space direction={'vertical'} className={'block'}>
                                {map(
                                    training.attributes.trainers
                                        .data,
                                    (user, index) => {
                                        const trainer =
                                            user.attributes.trainer.data;

                                        return (
                                            <Link
                                                key={index}
                                                to={trainer_info(trainer.id)}
                                            >
                                                <CrmAvatar
                                                    color={
                                                        trainer.attributes.color
                                                    }
                                                    firstName={
                                                        trainer.attributes
                                                            .firstName
                                                    }
                                                    lastName={
                                                        trainer.attributes
                                                            .lastName
                                                    }
                                                    middleName={
                                                        trainer.attributes
                                                            .middleName
                                                    }
                                                />
                                            </Link>
                                        );
                                    },
                                )}
                            </Space>
                        }
                    />
                );
            }
        } else {
            navigate(NOT_FOUND, { replace: true });
            return null;
        }
    }

    // Delete

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить урок?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                Modal.confirm({
                    title: 'Удалить',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Удалить до конца четверти?',
                    okText: 'До конца четверти',
                    cancelText: 'Только этот урок',
                    onOk: () => {
                        onConfirmedDelete(true);
                    },
                    onCancel: () => {
                        onConfirmedDelete(false);
                    }
                });
            },
        });
    }

    async function onConfirmedDelete(massEdit) {
        await updateTrainings({
            variables: {
                id: id,
                data: {
                    enabled: false,
                },
                massEdit: massEdit,
            },
        });
        navigate(INDEX);
    }

    // Save Theme

    async function saveNewName() {
        await updateTraining({
            variables: {
                id: id,
                data: {
                    name: state.name,
                },
            },
        });
        query.refetch();
    }

    async function saveNewComment() {
        await updateTraining({
            variables: {
                id: id,
                data: {
                    comment: state.comment,
                },
            },
        });
        query.refetch();
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={displayName}
                editAction={
                    RoleFunction.updateTraining() &&
                    (() => {
                        navigate(training_edit(id));
                    })
                }
                deleteAction={
                    RoleFunction.deleteTraining() &&
                    (() => {
                        onDelete();
                    })
                }
            />

            {query.loading && <Skeleton active />}

            {data && (
                <Space direction="vertical" size={20} className="block">
                    {RoleFunction.updateTrainingName() ? (
                        <>
                            <CrmDisplayData
                                block
                                label="Тема урока"
                                value={
                                    <Input
                                        size={'large'}
                                        placeholder={'Не указана'}
                                        // bordered={false}
                                        value={state.name}
                                        onChange={(e) =>
                                            setState({
                                                ...data,
                                                name: e.target.value,
                                            })
                                        }
                                        onPressEnter={() => saveNewName()}
                                        suffix={
                                            <Button
                                                type={'link'}
                                                icon={<SaveOutlined />}
                                                onClick={() => saveNewName()}
                                                loading={
                                                    updateTrainingData.loading
                                                }
                                            />
                                        }
                                    />
                                }
                            />
                            <CrmDisplayData
                                block
                                label="Комментарий"
                                value={
                                    <Input
                                        size={'large'}
                                        placeholder={'Не написан'}
                                        // bordered={false}
                                        value={state.comment}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                comment: e.target.value,
                                            })
                                        }
                                        onPressEnter={() => saveNewComment()}
                                        suffix={
                                            <Button
                                                type={'link'}
                                                icon={<SaveOutlined />}
                                                onClick={() => saveNewComment()}
                                                loading={
                                                    updateTrainingData.loading
                                                }
                                            />
                                        }
                                    />
                                }
                            />
                        </>
                    ) : (
                        <>
                            <CrmDisplayData
                                label="Тема урока"
                                value={
                                    <span className={'bold'}>
                                        {state.name || '—'}
                                    </span>
                                }
                            />
                            {state.comment && (
                                <CrmDisplayData
                                    label="Комментарий"
                                    block={true}
                                    value={
                                        <div
                                            className={
                                                'crm-lesson-comment normal'
                                            }
                                        >
                                            {state.comment}
                                        </div>
                                    }
                                />
                            )}
                        </>
                    )}

                    <CrmDisplayData label="Начало" value={displayDate} />

                    {displaySubject}

                    {displayRoom}

                    {displayUser}

                    {displayGroup}

                    <TrainingVisits
                        trainingId={id}
                        users={data.children}
                        onUpdate={() => {
                            query.refetch;
                        }}
                    />

                    {state.lessonId && (
                        <ChildHomework
                            trainingId={id}
                        />
                    )}
                </Space>
            )}
        </CrmLayout>
    );
}
