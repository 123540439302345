import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Divider, Skeleton } from 'antd';
import _ from 'lodash';

import RoleFunction from '../../config/roles';
import { homework_new } from '../../routes';

import { HomeworkCard } from './HomeworkCard';

import './Homework.scss';

const GET_TASK = gql`
    query tasks($filters: TaskFiltersInput) {
        tasks(filters: $filters, pagination: { limit: 200 }) {
            data {
                id
                attributes {
                    name
                    comment
                    trainingTo {
                        data {
                            attributes {
                                subject {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function task(data) {
    return _.map(data, (task) => {
        const { comment, name } = task.attributes;
        const subject =
            task.attributes.trainingTo.data.attributes.subject.data.attributes
                .name;
        return (
            <HomeworkCard
                key={task.id}
                subject={subject}
                title={name}
                comment={comment}
                id={task.id}
            />
        );
    });
}

export const ChildHomework = (props) => {
    const { trainingId } = props;
    
    const [data, setData] = useState([]);

    const query = useQuery(GET_TASK, {
        variables: {
            filters: {
                trainingTo: {
                    id: { eq: trainingId },
                },
            },
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.tasks.data);
        },
    });

    function button() {
        return (
            <div className={'training_task_button'}>
                <Link to={homework_new(trainingId)}>
                    Выдать задание
                </Link>
            </div>
        );
    }

    return (
        <div>
            <Divider>Домашнее задание</Divider>

            {query.loading && <Skeleton active />}

            {RoleFunction.updateTask() ? (
                data.length ? (
                    <div className={'training_task'}>
                        {task(data)}

                        {button()}
                    </div>
                ) : (
                    button()
                )
            ) : data.length ? (
                task(data)
            ) : (
                <div className={'bold'}>К этому уроку заданий нет</div>
            )}
        </div>
    );
};

