import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { ROOM } from '../../routes';

import RoomForm from './RoomForm';

const QUERY = gql`
    query query($id: ID) {
        room(id: $id) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: RoomInput!, $id: ID!) {
        updateRoom(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function RoomEdit() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: { id: id },
        onCompleted: (data) => {
            setData({
                name: data.room.data.attributes.name,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        await update({ variables: { id: id, data: data } });
        navigate(ROOM);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={`Изменить кабинет ${data && data.name}`} />

            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <RoomForm
                        onSubmit={onSubmit}
                        loading={loading}
                        data={data}
                        actionName={'Сохранить'}
                    />
                </>
            )}
        </CrmLayout>
    );
}
