import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

const QUERY = gql`
    query users($filters: GroupFiltersInput) {
        groups(
            filters: $filters
            pagination: { pageSize: 200 }
            sort: ["name:ASC"]
        ) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

const { Option } = Select;

export default function FilterGroups(field) {
    const [data, setData] = useState([]);

    // Query
    const query = useQuery(QUERY, {
        onCompleted: (data) => {
            setData(data.groups.data);
        },
    });

    let fieldData = {
        ...field,
    };
    delete fieldData.withAll;
    delete fieldData.withNull;

    return (
        <span className={cn([field.className, 'filter'])}>
            <Select
                {...fieldData}
                size={'small'}
                bordered={false}
                placeholder="Выберите класс"
                filterOption={false}
                notFoundContent={query.loading ? <Spin size="small" /> : null}
                loading={query.loading}
                className={'block'}
                dropdownMatchSelectWidth={false}
            >
                {field.withAll && (
                    <Option key={'0'} value={'0'}>
                        Все классы
                    </Option>
                )}

                {field.withNull && (
                    <Option key={'0'} value={'0'}>
                        Класс не выбран
                    </Option>
                )}

                {_.map(data, (item) => {
                    return (
                        <Option key={item.id} value={item.id}>
                            {item.attributes.name} Класс
                        </Option>
                    );
                })}
            </Select>
        </span>
    );
}
