import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Divider, message, Skeleton, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment/moment';

import FilterRange from '../../components/Filters/FilterRange';
import Filters from '../../components/Filters/Filters';
import SubjectListRow from '../Main/Visit/SubjectListRow';

const QUERY = gql`
    query trainings(
        $filters: TrainingFiltersInput
        $pagination: PaginationArg = {}
        $sort: [String] = []
    ) {
        trainings(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    startDate
                    subject {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

const ClientMarksAndVisits = ({ groupId, clientId }) => {
    // main data
    const [data, setData] = useState([]);

    const [transactionsRange, setTransactionsRange] = useState([
        moment().subtract(1, 'week'),
        moment(),
    ]);

    const query = useQuery(QUERY, {
        variables: {
            filters: {
                group: {id: {eq: groupId}},
                startDate: {
                    between: [
                        transactionsRange[0].startOf('day').format(),
                        transactionsRange[1].endOf('day').format(),
                    ],
                },
            },
            pagination: {
                pageSize: 200,
            },
            sort: ['startDate:ASC'],
        },
        onCompleted: (newData) => {
            setData([...newData.trainings.data]);
        },
        onError: (error) => {
            message.error('Ошибка загрузки уроков');
            console.error(error);
        },
    });

    let today = 0;
    function setDate(training) {
        const target = moment(
            training.attributes.startDate,
        );
        if (today !== target.date()) {
            today = target.date();
            return <Divider>{target.format('DD MMMM YYYY')}</Divider>;
        }
        return null;
    }

    return (
        <>
            <Filters withoutOverflow={true}>
                <FilterRange
                    value={transactionsRange}
                    onChange={(range) => setTransactionsRange(range)}
                />
            </Filters>

            {data.length ? (
                <>
                    <Space direction={'vertical'} size={20} className={'block'}>
                        <div className={'visit-list-row'}>
                            <div className={'line-2col ellipsis'}>
                                <span className={'caption color-gray-3'}>
                                    Предмет
                                </span>
                            </div>

                            <div className={'line-1col'}>
                                <span className={'caption color-gray-3'}>
                                    Пропуски
                                </span>
                            </div>

                            <div className={'line-1col'}>
                                <span className={'caption color-gray-3'}>
                                    Оценки
                                </span>
                            </div>
                        </div>
                    </Space>

                    {query.loading && <Skeleton active />}

                    <Space size={0} direction={'vertical'} className="block">
                        {_.map(data, (training) => {
                            return (
                                <div key={training.id}>
                                    {setDate(training)}

                                    {training.attributes.subject.data &&
                                        <SubjectListRow
                                            training={training}
                                            clientId={clientId}
                                        />
                                    }
                                </div>
                            );
                        })}
                    </Space>
                </>
            ) : (
                <div>В эти дни уроков не было</div>
            )}
        </>
    );
};

export default ClientMarksAndVisits;
