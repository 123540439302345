import React from 'react';
import { Button } from 'antd';

import './filters.scss';

export default function FilterButton(props) {
    let fieldData = {
        ...props,
    };
    delete fieldData.children;

    return (
        <span className={'filter info'}>
            <Button {...fieldData} type={'link'} size={'small'}>
                {props.children}
            </Button>
        </span>
    );
}
