import React from 'react';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';

import { training_info } from '../../routes';

export default function CalendarTraining({
    id,
    trainers,
    subject,
    room,
    group,
}) {
    const navigate = useNavigate();

    function go() {
        navigate(training_info(id));
    }

    const displayTrainers = map(trainers, (value) => {
        const trainer = value.attributes.trainer.data;
        const userColor = trainer.attributes.color || '#F0F0F0';

        return (
            <div
                key={value.id}
                className={'calendar-training-user'}
                style={{ backgroundColor: userColor }}
            >
                {trainer.attributes.firstName.charAt(0)}
                {trainer.attributes.lastName.charAt(0)}
            </div>
        );
    });

    const color = (subject && subject.attributes.color) || '#F0F0F0';

    return (
        <div
            className="calendar-training"
            style={{ backgroundColor: color }}
            onClick={go}
        >
            <div>{subject && subject.attributes.name}</div>
            <div>
                {group && (
                    <div className={'calendar-training-user'}>
                        {group.attributes.name}
                    </div>
                )}

                {displayTrainers}

                {room && (
                    <div className={'calendar-training-user'}>
                        🚪 {room.attributes.name}
                    </div>
                )}
            </div>
        </div>
    );
}
