import React from 'react';
import {provider, useInstance} from 'react-ioc';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {observer} from 'mobx-react-lite';

import CrmWindowSpin from './components/CrmWindowSpin';
import ScrollToTop from './hooks/scrollToTop';
import ApolloClientDI from './model/graphql/ApolloClientDI';
import {Store} from './model/store/Store';
import StoreDI from './model/store/StoreDI';
import Activities from './pages/Activities/Activities';
import ActivityEdit from './pages/Activities/ActivityEdit';
import ActivityNew from './pages/Activities/ActivityNew';
import AboutMe from './pages/Auth/AboutMe';
import ForgetPassword from './pages/Auth/ForgetPassword';
import Login from './pages/Auth/Login';
import Logout from './pages/Auth/Logout';
import ChildEdit from './pages/Children/ChildEdit';
import ChildInfo from './pages/Children/ChildInfo';
import Children from './pages/Children/Children';
import ParentEdit from './pages/Client/Parent/ParentEdit';
import ParentInfo from './pages/Client/Parent/ParentInfo';
import GroupEdit from './pages/Group/GroupEdit';
import GroupInfo from './pages/Group/GroupInfo';
import GroupNew from './pages/Group/GroupNew';
import Groups from './pages/Group/Groups';
import {Homework} from './pages/Homework/Homework';
import {HomeworkEdit} from './pages/Homework/HomeworkEdit';
import {HomeworkNew} from './pages/Homework/HomeworkNew';
import {Homeworks} from './pages/Homework/Homeworks';
import Catering from './pages/Info/Catering';
import Policy from './pages/Info/Policy';
import Journal from './pages/Journal/Journal';
import Calendar from './pages/Main/Calendar';
import Index from './pages/Main/Index';
import TrainingEdit from './pages/Main/TrainingEdit';
import TrainingInfo from './pages/Main/TrainingInfo';
import TrainingNew from './pages/Main/TrainingNew';
import NotFound from './pages/NotFound';
import {Orders} from './pages/Orders/Orders';
import PaymentError from './pages/Payment/Error';
import PaymentOffer from './pages/Payment/Offer';
import PaymentSuccess from './pages/Payment/Success';
import {Plan} from './pages/Plan/Plan';
import Journal_N from './pages/Progress/Progress';
import RoomEdit from './pages/Room/RoomEdit';
import RoomNew from './pages/Room/RoomNew';
import Rooms from './pages/Room/Rooms';
import SubjectEdit from './pages/Subject/SubjectEdit';
import SubjectNew from './pages/Subject/SubjectNew';
import Subjects from './pages/Subject/Subjects';
import Test from './pages/Test';
import AdminNew from './pages/Trainer/Admin/AdminNew';
import Admins from './pages/Trainer/Admin/Admins';
import TeacherNew from './pages/Trainer/Teacher/TeacherNew';
import Teachers from './pages/Trainer/Teacher/Teachers';
import TrainerEdit from './pages/Trainer/TrainerEdit';
import TrainerInfo from './pages/Trainer/TrainerInfo';
import {Year} from './pages/Years/Year';
import {YearEdit} from './pages/Years/YearEdit';
import {YearNew} from './pages/Years/YearNew';
import {Years} from './pages/Years/Years';
import {
    ACTIVITIES,
    ACTIVITIES_EDIT,
    ACTIVITIES_NEW,
    ADMIN,
    ADMIN_NEW,
    CALENDAR,
    CHILD_EDIT,
    CHILD_INFO,
    CHILDREN,
    FORGET_PASSWORD,
    GROUP,
    GROUP_EDIT,
    GROUP_INFO,
    GROUP_NEW,
    HOMEWORK,
    HOMEWORK_EDIT,
    HOMEWORK_NEW,
    HOMEWORKS,
    INDEX,
    INFO_CATERING,
    INFO_PERSONAL_DATA,
    JOURNAL,
    LOGIN,
    LOGOUT,
    NOT_FOUND,
    ORDERS,
    PARENT_EDIT,
    PARENT_INFO,
    PAYMENT_ERROR,
    PAYMENT_OFFER,
    PAYMENT_SUCCESS,
    PLAN,
    PROGRESS,
    ROOM,
    ROOM_EDIT,
    ROOM_NEW,
    SUBJECT,
    SUBJECT_EDIT,
    SUBJECT_NEW,
    TEACHER,
    TEACHER_NEW,
    TRAINER_EDIT,
    TRAINER_INFO,
    TRAINING_EDIT,
    TRAINING_INFO,
    TRAINING_NEW,
    YEAR,
    YEAR_EDIT,
    YEAR_NEW,
    YEARS,
} from './routes';

// Routes & Pages

const queryClient = new QueryClient();

const App = () => {
    const { hydrated, auth } = useInstance(Store);

    const { isAuthenticated } = auth;
    const apolloClient = useInstance(ApolloClient);

    if (!hydrated) {
        return (
            <div className="App">
                <CrmWindowSpin spinning />
            </div>
        );
    }

    console.log('isAuthenticated', isAuthenticated);

    return (
        <ApolloProvider client={apolloClient}>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <ScrollToTop />
                    <AboutMe>
                        <Routes>
                            <React.Fragment>
                                <Route
                                    path={PAYMENT_SUCCESS}
                                    element={<PaymentSuccess />}
                                />
                                <Route
                                    path={PAYMENT_ERROR}
                                    element={<PaymentError />}
                                />
                                <Route
                                    path={PAYMENT_OFFER}
                                    element={<PaymentOffer />}
                                />
                            </React.Fragment>

                            <React.Fragment>
                                <Route
                                    path={INFO_CATERING}
                                    element={<Catering />}
                                />
                                <Route
                                    path={INFO_PERSONAL_DATA}
                                    element={<Policy />}
                                />
                            </React.Fragment>

                            {!isAuthenticated && (
                                auth.authError ?
                                    <Route path='*' element={<Login/>}/>
                                : (
                                <React.Fragment>
                                    <Route path="*" element={<Login/>}/>
                                    <Route path={LOGOUT} element={<Navigate to={LOGIN}/>}/>
                                    <Route path={LOGIN} element={<Login/>}/>
                                    <Route path={FORGET_PASSWORD} element={<ForgetPassword/>}/>
                                    <Route path={NOT_FOUND} element={<NotFound/>}/>
                                </React.Fragment>
                            ))}

                            {isAuthenticated && (
                                <React.Fragment>
                                    <Route path={INDEX} element={<Index />} />
                                    <Route path={CALENDAR} element={<Calendar />} />

                                    <Route
                                        path={TRAINING_INFO}
                                        element={<TrainingInfo />}
                                    />
                                    <Route
                                        path={TRAINING_NEW}
                                        element={<TrainingNew />}
                                    />
                                    <Route
                                        path={TRAINING_EDIT}
                                        element={<TrainingEdit />}
                                    />

                                    <Route path={JOURNAL} element={<Journal />} />
                                    <Route
                                        path={PROGRESS}
                                        element={<Journal_N />}
                                    />

                                    <Route path={CHILDREN} element={<Children />} />
                                    <Route
                                        path={CHILD_INFO}
                                        element={<ChildInfo />}
                                    />
                                    <Route
                                        path={CHILD_EDIT}
                                        element={<ChildEdit />}
                                    />

                                    <Route
                                        path={PARENT_INFO}
                                        element={<ParentInfo />}
                                    />
                                    <Route
                                        path={PARENT_EDIT}
                                        element={<ParentEdit />}
                                    />

                                    <Route path={GROUP} element={<Groups />} />
                                    <Route
                                        path={GROUP_NEW}
                                        element={<GroupNew />}
                                    />
                                    <Route
                                        path={GROUP_INFO}
                                        element={<GroupInfo />}
                                    />
                                    <Route
                                        path={GROUP_EDIT}
                                        element={<GroupEdit />}
                                    />

                                    <Route path={SUBJECT} element={<Subjects />} />
                                    <Route
                                        path={SUBJECT_NEW}
                                        element={<SubjectNew />}
                                    />
                                    <Route
                                        path={SUBJECT_EDIT}
                                        element={<SubjectEdit />}
                                    />

                                    <Route path={ROOM} element={<Rooms />} />
                                    <Route path={ROOM_NEW} element={<RoomNew />} />
                                    <Route
                                        path={ROOM_EDIT}
                                        element={<RoomEdit />}
                                    />

                                    <Route path={TEACHER} element={<Teachers />} />
                                    <Route
                                        path={TEACHER_NEW}
                                        element={<TeacherNew />}
                                    />
                                    <Route path={ADMIN} element={<Admins />} />
                                    <Route
                                        path={ADMIN_NEW}
                                        element={<AdminNew />}
                                    />

                                    <Route
                                        path={TRAINER_INFO}
                                        element={<TrainerInfo />}
                                    />
                                    <Route
                                        path={TRAINER_EDIT}
                                        element={<TrainerEdit />}
                                    />

                                    <Route
                                        path={ACTIVITIES}
                                        element={<Activities />}
                                    />
                                    <Route
                                        path={ACTIVITIES_NEW}
                                        element={<ActivityNew />}
                                    />
                                    <Route
                                        path={ACTIVITIES_EDIT}
                                        element={<ActivityEdit />}
                                    />

                                    <Route path={YEARS} element={<Years />} />
                                    <Route path={YEAR} element={<Year />} />
                                    <Route path={YEAR_NEW} element={<YearNew />} />
                                    <Route
                                        path={YEAR_EDIT}
                                        element={<YearEdit />}
                                    />

                                    <Route path={PLAN} element={<Plan />} />

                                    <Route path={HOMEWORK} element={<Homework />} />
                                    <Route
                                        path={HOMEWORKS}
                                        element={<Homeworks />}
                                    />
                                    <Route
                                        path={HOMEWORK_NEW}
                                        element={<HomeworkNew />}
                                    />
                                    <Route
                                        path={HOMEWORK_EDIT}
                                        element={<HomeworkEdit />}
                                    />

                                    <Route
                                        path={ORDERS}
                                        element={<Orders />}
                                    />

                                    <Route path={LOGOUT} element={<Logout />} />
                                    <Route
                                        path="*"
                                        element={<Navigate to={INDEX} />}
                                    />
                                    <Route
                                        path={NOT_FOUND}
                                        element={<NotFound />}
                                    />

                                    <Route path={'/test'} element={<Test />} />
                                </React.Fragment>
                            )}
                        </Routes>
                    </AboutMe>
                </Router>
            </QueryClientProvider>
        </ApolloProvider>
    );
};

export default provider(StoreDI(), ApolloClientDI())(observer(App));
