import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { message, Select } from 'antd';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import FilterDate from '../../components/Filters/FilterDate';
import FilterGroups from '../../components/Filters/FilterGroups';
import Filters from '../../components/Filters/Filters';
import { homeworks } from '../../routes';

import { HomeworkOnDay } from './HomeworkOnDay';
import { HomeworkOnWeek } from './HomeworkOnWeek';

const GET_TASKS = gql`
    query tasks($filters: TaskFiltersInput) {
        tasks(filters: $filters, pagination: { limit: 200 }) {
            data {
                id
                attributes {
                    name
                    comment
                    dueDate
                    trainingTo {
                        data {
                            attributes {
                                subject {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const { Option } = Select;

export const Homeworks = () => {
    const { group, mode, date } = useParams();

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    useQuery(GET_TASKS, {
        variables: { filters: getFilters(group, date) },
        onCompleted: (data) => {
            setData(data.tasks.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    // изменение класса
    function onChangeGroup(value) {
        navigate(homeworks(value, mode, date));
    }

    // изменение режима
    function onChangeMode(value) {
        navigate(homeworks(group, value, date));
    }

    // изменение даты
    function onChangeDate(value) {
        navigate(homeworks(group, mode, moment(value).format('YYYY-MM-DD')));
    }

    function getFilters(group, date) {
        return {
            ...(+mode === 0
                ? {
                    dueDate: {
                        eq: moment(date).format('YYYY-MM-DD'),
                    },
                }
                : {
                    dueDate: {
                        between: [
                            moment(date).startOf('week').format('YYYY-MM-DD'),
                            moment(date).endOf('week').format('YYYY-MM-DD'),
                        ],
                    },
                }),
            ...(group !== '0'
                ? {
                    group: { id: { eq: group } },
                }
                : {}),
        };
    }

    return (
        <>
            <CrmLayout without_padding>
                <CrmCenteredTitle title={'Задания'}></CrmCenteredTitle>

                <Filters>
                    <FilterGroups
                        value={group}
                        withAll
                        onChange={(value) => onChangeGroup(value)}
                    />
                    <span className={'filter'}>
                        <Select
                            className={'block'}
                            filterOption={false}
                            size={'small'}
                            bordered={false}
                            value={+mode}
                            onChange={(value) => onChangeMode(value)}
                            dropdownMatchSelectWidth={false}
                        >
                            <Option value={0}>День</Option>
                            <Option value={1}>Неделя</Option>
                        </Select>
                    </span>
                    {+mode === 0 ? (
                        <FilterDate
                            value={moment(date)}
                            onChange={onChangeDate}
                        />
                    ) : (
                        <FilterDate
                            value={moment(date)}
                            picker="week"
                            onChange={onChangeDate}
                        />
                    )}
                </Filters>
            </CrmLayout>
            <>
                {+mode === 0 ? (
                    data.length ? (
                        <div
                            className={
                                'crm-layout crm-layout-content dissolved'
                            }
                        >
                            <HomeworkOnDay data={data} />
                        </div>
                    ) : (
                        <div
                            className={
                                'crm-layout crm-layout-content dissolved'
                            }
                        >
                            <div>Заданий на этот день нет</div>
                        </div>
                    )
                ) : data.length ? (
                    <HomeworkOnWeek data={data} range={date} />
                ) : (
                    <div className={'crm-layout crm-layout-content dissolved'}>
                        <div>Заданий на эту неделю нет</div>
                    </div>
                )}
            </>
        </>
    );
};
