import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../../components/CrmCenteredTitle';
import CrmLayout from '../../../components/CrmLayout';
import {setValidatedContacts} from '../../../helpers/contactsValidate';
import {parent_info} from '../../../routes';

import ParentForm from './ParentForm';

const QUERY = gql`
    query query (
        $id: ID,
    ) {
        parent(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    birthDate
                    middleName
                    enabled
                    account {
                        data {
                            id
                            attributes {
                                email
                                blocked
                                contacts {
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDDATE_USER = gql`
    mutation users ($id: ID!, $data: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE_PARENT = gql`
    mutation users ($id: ID!, $data: ParentInput!) {
        updateParent (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentEdit() {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: (data) => {

            const parent = data.parent.data;
            const account = parent.attributes.account.data;
            const contacts = account.attributes.contacts;

            setData({
                firstName: parent.attributes.firstName,
                lastName: parent.attributes.lastName,
                middleName: parent.attributes.middleName,
                birthDate: parent.attributes.birthDate && moment(parent.attributes.birthDate),
                contacts: _.map(contacts, (value) => {return {type: value.type, value: value.value}}),
            });

            setAccountId(account.id);

        },
    });

    const [updateUser] = useMutation(UPDDATE_USER);
    const [updateParent] = useMutation(UPDATE_PARENT);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        console.log(setValidatedContacts(data.contacts))
        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            contacts: setValidatedContacts(data.contacts),
        }
        await updateUser({variables: {id: accountId, data: userData}});

        const parentData = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            birthDate: data.birthDate && moment(data.birthDate).format('YYYY-MM-DD'),
        }
        await updateParent({variables: {id: id, data: parentData}});

        navigate(parent_info(id));

    }

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={query.data ? `${query.data.parent.data.attributes.firstName} ${query.data.parent.data.attributes.lastName}` : '—'}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <ParentForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Сохранить'} withGym/>

            </>
            }

        </CrmLayout>
    );
}
