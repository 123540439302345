import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import RoleFunction from '../../config/roles';
import { activity_edit } from '../../routes';

import './activities.scss';

const ActivityRow = (props) => {
    const { abbr, name, coefficient, deleteActivity, activityId } = props;

    const navigate = useNavigate();

    return (
        <div className={'activity-list-row'}>
            <div className={'activity-abbr'}>{abbr}</div>
            <div className={'activity-name'}>{name}</div>
            <div className={'activity-coefficient'}>{coefficient}</div>
            <div className={'activity-buttons'}>
                <Button
                    type={'link'}
                    icon={<EditOutlined />}
                    onClick={() => {
                        navigate(activity_edit(activityId));
                    }}
                />
                <Button
                    type={'link'}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        RoleFunction.updateActivity() &&
                        deleteActivity(activityId);
                    }}
                />
            </div>
        </div>
    );
};

export default ActivityRow;
