import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined spin />;

const CrmSpin = (props) => {
    let result = null;

    if (props.spinning) {
        result = <Spin indicator={antIcon}>{props.children}</Spin>;
    } else {
        result = props.children;
    }

    return result;
};

export default CrmSpin;
