import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ExclamationCircleOutlined,} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Divider, Empty, Modal, Skeleton, Space, Tabs} from 'antd';
import map from 'lodash/map';
import moment from 'moment';

import CrmAvatar from '../../../components/CrmAvatar';
import CrmCard from '../../../components/CrmCard';
import CrmCenteredTitle from '../../../components/CrmCenteredTitle';
import CrmContact from '../../../components/CrmContact';
import CrmDisplayData from '../../../components/CrmDisplayData';
import CrmLayout from '../../../components/CrmLayout';
import RoleFunction from '../../../config/roles';
import {getValidatedContacts} from '../../../helpers/contactsValidate';
import {child_info, INDEX, NOT_FOUND, parent_edit} from '../../../routes';
import ClientMessage from '../ClientMessage';

import ParentAccount from './ParentAccount';
import ParentAddMessage from './ParentAddMessage';
import ParentChildrenSelectAndAdd from './ParentChildrenSelectAndAdd';

const QUERY = gql`
    query query (
        $id: ID,
        $mesfilter: MessageFiltersInput,
    ) {
        parent(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    birthDate
                    enabled
                    color
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    clients (pagination: {limit: 200}) {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                middleName
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                        }
                                    }
                                }
                            }
                        }
                    }
                    account {
                        data {
                            id
                            attributes {
                                username
                                blocked
                                contacts {
                                    type
                                    value
                                }
                            }
                        }
                    }
                    anket {
                        contraindications
                        experience
                        fullFamily
                        gadgetTime
                        mainGoal
                    }
                    enabled
                    indicators (pagination: {limit: 200}) {
                        id
                        name
                        startValue
                        goal
                        result (pagination: {limit: 200}) {
                            id
                            date
                            value
                        }
                    }
                    skills {
                        id
                        name
                        own
                    }
                    messages (filters: $mesfilter) {
                        data {
                            id
                            attributes {
                                author {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                message
                                fileUrl
                                date
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE = gql`
    mutation update($data: ParentInput!, $id: ID!) {
        updateParent(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const {TabPane} = Tabs;

export default function ParentInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const query = useQuery(QUERY, {
        variables: {
            id: id,
            mesfilter: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const [deleteMutation] = useMutation(DELETE);
    const onDelete = function () {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: `Действительно удалить клиента ${query.data.parent.data.attributes.firstName} ${query.data.parent.data.attributes.lastName}?`,
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }

        });
    };

    async function onConfirmedDelete() {
        await deleteMutation({
            variables: {
                id: id,
                data: {
                    enabled: false
                }
            }
        });
        navigate(INDEX);
    }

    let displayTitle = '–';
    let contacts = null;
    let messages = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let children = null;

    if (query.data) {

        // если такого клиента нет, то отправляем в 404
        if (!query.data.parent.data) {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }

        const parent = query.data.parent.data;

        const firstName = parent.attributes.firstName;
        const lastName = parent.attributes.lastName;
        const middleName = parent.attributes.middleName;

        displayTitle = middleName ? `${lastName} ${firstName} ${middleName}` : `${firstName} ${lastName}`;

        // Контакты
        if (query.data.parent.data.attributes.account.data.attributes.contacts.length > 0) {
            contacts = (
                <>
                    {/*<Divider>Контакты</Divider>*/}
                    <Space direction={'vertical'} size={10}>
                        {map(query.data.parent.data.attributes.account.data.attributes.contacts, (value, index) => {
                            return (
                                <CrmContact key={index} type={value.type} value={getValidatedContacts(value.value)}/>
                            )
                        })}
                    </Space>
                </>
            )
        }

        // Дети
        if (query.data.parent.data.attributes.clients) {
            children = (
                <Space direction={'vertical'} size={10} className={'block'}>
                    {query.data.parent.data.attributes.clients.data.map(function (value) {
                        return (
                            <CrmCard key={value.id}>
                                <Link to={child_info(value.id)}>
                                    <CrmAvatar
                                        size={'large'}
                                        firstName={value.attributes.firstName}
                                        lastName={value.attributes.lastName}
                                        color={value.attributes.color}
                                        avatarUrl={value.attributes.avatar.data && value.attributes.avatar.data.attributes.previewUrl}
                                    />
                                </Link>
                            </CrmCard>
                        )
                    })}
                </Space>
            )
        }

        // Комментарии
        messages = query.data.parent.data.attributes.messages.data.map(function (value, index) {
            return <ClientMessage
                key={index}
                message={value.attributes}
                id={value.id}
                onUpdate={query.refetch}
            />

        })

    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={displayTitle}
                editAction={RoleFunction.updateParent() && (() => {navigate(parent_edit(id))})}
                deleteAction={RoleFunction.deleteParent() && (() => {onDelete()})}
            />

            {!query.data && query.loading && <Skeleton active/>}

            {query.data &&
            <Space direction='vertical' size={20} className='block'>

                <CrmAvatar
                    size={'large'}
                    firstName={query.data.parent.data.attributes.firstName}
                    lastName={query.data.parent.data.attributes.lastName}
                    middleName={query.data.parent.data.attributes.middleName}
                    color={query.data.parent.data.attributes.color}
                    avatarUrl={query.data.parent.data.attributes.avatar.data && query.data.parent.data.attributes.avatar.data.attributes.previewUrl}
                />

                {contacts}

                {query.data.parent.data.attributes.birthDate &&
                <CrmDisplayData label={'Дата рождения'}
                    value={moment(query.data.parent.data.attributes.birthDate).format('DD MMMM YYYY') + ', ' + moment().diff(query.data.parent.data.attributes.birthDate, 'years') + ' лет'}
                />
                }

                <div>
                    <Divider>Дети</Divider>
                    {children}
                    <div className={'space'}/>
                    <ParentChildrenSelectAndAdd
                        parentId={id}
                        onUpdate={query.refetch}
                    >
                        {query.data.parent.data.attributes.clients.data}
                    </ParentChildrenSelectAndAdd>
                </div>

                <Tabs defaultActiveKey="1">

                    <TabPane key="comments" tab={'Комментарии'}>
                        <Space direction='vertical' size={20} className='block'>
                            {messages}
                            <ParentAddMessage id={id} onUpdate={query.refetch}
                                messages={query.data.parent.data.attributes.messages.data}/>
                        </Space>
                    </TabPane>

                    {RoleFunction.updateParentAccount() &&
                    <TabPane key="account" tab={'Аккаунт'}>
                        <ParentAccount data={query.data.parent.data.attributes.account.data} onUpdate={query.refetch}/>
                    </TabPane>
                    }

                </Tabs>
            </Space>
            }
        </CrmLayout>
    );
}
