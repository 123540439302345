import _ from 'lodash';

export const activities = [
    {
        name: 'Домашнее задание',
        emoji: '🏠',
    },
    {
        name: 'Устный ответ',
        emoji: '🙋️',
    },
    {
        name: 'Промежуточная контрольная',
        emoji: '📝️',
    },
    {
        name: 'Итоговая контрольная',
        emoji: '⭐',
    },
    {
        name: 'Самостоятельная работа',
        emoji: '✏️',
    },
    {
        name: 'Работа у доски',
        emoji: '🗣',
    },
];

export function getIconByActivityName(name) {
    const activityItem = _.find(activities, { name: name });
    return activityItem ? activityItem.emoji : null;
}
