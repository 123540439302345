import React from 'react';
import { Button, Form, Input } from 'antd';

import RoleFunction from '../../config/roles';
import { generateColor } from '../../helpers/colors';

export default function SubjectForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        RoleFunction.updateSubject() && onSubmit(values);
    };

    const newData = {
        ...data,
        color: (data && data.color) || generateColor(),
    };

    return (
        <Form
            name="subject"
            form={form}
            onFinish={onFinish}
            initialValues={newData}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'name'}
                label={'Название'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item name={'color'} label={'Цвет'}>
                <Input placeholder={'#ffffff'} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
