import React, {useState} from 'react';
import {MailOutlined, MinusCircleOutlined, PhoneOutlined, PlusOutlined, WhatsAppOutlined,} from '@ant-design/icons';
import {gql, useQuery} from '@apollo/client';
import {Button, DatePicker, Divider, Form, Input, Select,} from 'antd';
import MaskedInput from 'antd-mask-input';
import debounce from 'debounce-promise';
import moment from 'moment';

import SelectGroup from '../../../components/Form/SelectGroup';
import {getValidatedContacts} from '../../../helpers/contactsValidate';

const QUERY_PHONE = gql`
    query phone($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(filters: $filters) {
            data {
                id
                attributes {
                    username
                }
            }
        }
    }
`;

export default function ParentForm({data, onSubmit, loading, withAccount, actionName, actionIcon, withGroup}) {


    const [_formData, setFormData] = useState(data);

    const [form] = Form.useForm();

    const queryPhone = useQuery(QUERY_PHONE, {skip: true});

    const onFinish = (values) => {
        onSubmit(values);
        form.resetFields();
    };

    const onFormChange = () => {
        setFormData(form.getFieldValue('contacts'))
    };

    const request = debounce(async (value) => {
        const res = await queryPhone.refetch({filters: {username: {eq: value}}});
        if (res.data.usersPermissionsUsers.data.length > 0) {
            return Promise.resolve({exist: true});
        } else {
            return Promise.resolve({exist: false});
        }
    }, 1000);

    let newData = {...data};
    newData.birthDate = data && data.birthDate && moment(data.birthDate);
    newData.contacts = (data && data.contacts) ? getValidatedContacts(data.contacts) : [];

    return (

        <Form name="parent"
            form={form}
            onFinish={onFinish}
            initialValues={newData}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >

            <Form.Item name={'lastName'} label={'Фамилия'} required
                rules={[{required: true, message: 'Заполните поле',},]}>
                <Input placeholder='Захаров'/>
            </Form.Item>

            <Form.Item name={'firstName'} label={'Имя'} required
                rules={[{required: true, message: 'Заполните поле',},]}>
                <Input placeholder='Николай'/>
            </Form.Item>

            <Form.Item name={'middleName'} label={'Отчество'}>
                <Input placeholder='Михайлович'/>
            </Form.Item>

            {withAccount && (
                <>
                    <Divider>Аккаунт для входа</Divider>

                    <Form.Item name={'username'}
                        label={'Номер телефона для входа'}
                        required
                        hasFeedback
                        validateFirst={true}
                        rules={[
                            {required: true, message: 'Заполните поле',},
                            () => ({
                                validator(rule, value) {
                                    return new Promise((resolve, reject) => {
                                        request(value).then((value) => {
                                            if (value.exist) {
                                                reject('Пользователь с таким номером уже есть')
                                            } else {
                                                resolve();
                                            }
                                        });
                                    });
                                }
                            }),

                        ]}>
                        <MaskedInput mask={'+7 (111) 111-11-11'}/>
                    </Form.Item>
                </>
            )}

            <Divider>Дополнительные контакты</Divider>

            <Form.List name="contacts">
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map(({key, name, ...restField}) => (

                            <div key={key} className={'form-contact'}>

                                <Form.Item {...restField} name={[name, 'type']}
                                    rules={[{required: true, message: 'Выберите тип контакта',},]}
                                    className={'form-contact-select'}>
                                    <Select onSelect={onFormChange}>
                                        <Select.Option value='phoneWhatsapp'><PhoneOutlined/> <WhatsAppOutlined/> Телефон + WhatsApp</Select.Option>
                                        <Select.Option value='phone'><PhoneOutlined/> Телефон</Select.Option>
                                        <Select.Option value='whatsapp'><WhatsAppOutlined/> WhatsApp</Select.Option>
                                        <Select.Option value='email'><MailOutlined/> Почта</Select.Option>
                                    </Select>
                                </Form.Item>

                                {(form.getFieldValue('contacts')[key] && form.getFieldValue('contacts')[key].type !== 'email') ?
                                        <Form.Item {...restField} name={[name, 'value']}
                                            rules={[{required: true, message: 'Заполните поле',},]}
                                            className={'form-contact-input'} required>
                                            <MaskedInput onChange={onFormChange} mask={'+7 (111) 111-11-11'}/>
                                        </Form.Item>
                                    :
                                        <Form.Item {...restField} name={[name, 'value']}
                                            rules={[
                                                {required: true, message: 'Заполните поле',},
                                                {type: 'email', message: 'Введите корректный email',}
                                            ]}
                                            className={'form-contact-input'} required>
                                            <Input onChange={onFormChange}/>
                                        </Form.Item>
                                }

                                <Form.Item>
                                    <Button icon={<MinusCircleOutlined/>} onClick={() => remove(name)} type={'dashed'}/>
                                </Form.Item>

                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}/>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item name={'birthDate'} label={'Дата рождения'}>
                <DatePicker
                    format={'DD.MM.YYYY'}
                />
            </Form.Item>

            {withGroup &&
            <Form.Item name={'group'} label={'Класс'}>
                <SelectGroup/>
            </Form.Item>
            }

            <Form.Item>
                <Button type="primary" htmlType="submit" icon={actionIcon} loading={loading}>
                    {actionName}
                </Button>
            </Form.Item>


        </Form>
    );

}