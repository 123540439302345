// массив для обозначения уроков
export const lessons = [
    { name: '1й урок', start: '9:00', end: '9:40' },
    { name: '2й урок', start: '9:50', end: '10:30' },
    { name: '3й урок', start: '10:50', end: '11:30' },
    { name: '4й урок', start: '11:50', end: '12:30' },
    { name: '5й урок', start: '12:40', end: '13:20' },
    { name: '6й урок', start: '13:45', end: '14:25' },
    { name: '7й урок', start: '14:50', end: '15:30' },
    { name: '8й урок', start: '15:40', end: '16:20' },
    { name: '9й урок', start: '16:30', end: '17:10' },
];
