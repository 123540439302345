import React, {useState} from 'react';
import {DownloadOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Button, message, Modal} from 'antd';
import _ from 'lodash';

import RoleFunction from '../../config/roles';
import MarkForm from '../../pages/Main/Visit/MarkForm';
import CrmModal from '../CrmModal';

import Mark from './Mark';

import './mark.scss'

const QUERY = gql`
    query mark($filters: MarkFiltersInput) {
        marks(filters: $filters, pagination: {limit: 100}) {
            data {
                id
                attributes {
                    mark
                    activity {
                        data {
                            id
                            attributes {
                                abbr
                                name
                                coefficient
                            }
                        }
                    }
                    comment
                }
            }
        }
    }
`;

const CREATE = gql`
    mutation create($data: MarkInput!) {
        createMark(data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE = gql`
    mutation create($id: ID!, $data: MarkInput!) {
        updateMark(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const DELETE = gql`
    mutation delete($id: ID!) {
        deleteMark(id: $id) {
            data {
                id
            }
        }
    }
`;

export default function CrmMark({trainingId, clientId, onUpdate, onQuery, markData, refetch}) {

    const [data, setData] = useState(markData);
    const query = useQuery(QUERY, {
        variables: {
            filters: {
                client: {id: {eq: clientId}},
                training: {id: {eq: trainingId}},
            },
        },
        onCompleted: (data) => {
            setData(data.marks.data);
            onQuery && onQuery(data.marks.data);
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка загрузки оценок');
        },
        skip: markData !== undefined || markData === false
    });

    // Create
    const [showAddModal, setShowAddModal] = useState(false);
    const [create, createRes] = useMutation(CREATE);
    const onAdd = async (formData) => {
        const data = {
            client: clientId,
            training: trainingId,
            mark: formData.mark,
            activity: formData.activity,
            comment: formData.comment,
        };

        await create({variables: {data: data}});
        query.refetch();
        setShowAddModal(false);
        if (onUpdate) {onUpdate()};
        refetch && refetch()
    };

    // Update
    const [editMark, setEditMark] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [update, updateRes] = useMutation(UPDATE);
    const onEdit = async (formData) => {
        const data = {
            mark: formData.mark,
            activity: formData.activity,
            comment: formData.comment,
        };

        await update({
            variables: {id: editMark.id, data: data},
        });
        query.refetch();
        setShowEditModal(false);
        if (onUpdate) {onUpdate()};
        refetch && refetch()
    };

    // Delete
    const [deleteMark, deleteMarkRes] = useMutation(DELETE);
    const onDeleteMark = async () => {

        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить оценку?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk: async () => {
                await deleteMark({variables: {id: editMark.id}});
                query.refetch();
                setShowEditModal(false);
                if (onUpdate) {onUpdate()};
                refetch && refetch()

            }
        });
    };

    return (
        <div className={'marks'}>
            {data && data[0] && data[0] !== false && _.map(data, (mark) => {
                const activity = mark.attributes.activity.data;
                const comment = mark.attributes.comment || '';

                return (
                    <React.Fragment key={mark.id}>
                        <div
                            onClick={
                                RoleFunction.updateMark()
                                    ?
                                        (() => {
                                            setEditMark(mark);
                                            setShowEditModal(true);
                                        })
                                    :
                                    undefined
                            }
                            style={{cursor: 'pointer'}}
                        >
                            <Mark mark={mark.attributes.mark} activity={activity} comment={comment}/>
                            {!activity && <span className={'color-danger'}> <ExclamationCircleOutlined/></span>}
                        </div>

                    </React.Fragment>
                );
            })}

            {RoleFunction.updateMark() && editMark && (
                <CrmModal
                    isOpen={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    title={'Изменить оценку'}
                >
                    <div className={'modal-basic-form'}>
                        <MarkForm
                            onSubmit={onEdit}
                            loading={updateRes.loading}
                            data={{
                                mark: editMark.attributes.mark,
                                activity: editMark.attributes.activity.data
                                    ? editMark.attributes.activity.data.id
                                    : null,
                                comment: editMark.attributes.comment,
                            }}
                            actionName={'Сохранить'}
                            actionIcon={<DownloadOutlined/>}
                            withDelete={RoleFunction.deleteMark()}
                            onDelete={() => onDeleteMark()}
                            deleting={deleteMarkRes.loading}
                        />
                    </div>
                </CrmModal>
            )}

            {RoleFunction.createMark() && (
                <>
                    <Button
                        shape={'circle'}
                        icon={<PlusOutlined/>}
                        onClick={() => setShowAddModal(true)}
                        loading={query.loading}
                    />
                    <CrmModal
                        isOpen={showAddModal}
                        onClose={() => setShowAddModal(false)}
                        title={'Добавить оценку'}
                    >
                        <div className={'modal-basic-form'}>
                            <MarkForm
                                onSubmit={onAdd}
                                loading={createRes.loading}
                                actionName={'Добавить'}
                                actionIcon={<PlusOutlined/>}
                            />
                        </div>
                    </CrmModal>
                </>
            )}

        </div>
    );
}
