import { types as t } from 'mobx-state-tree';

import { AuthStore, initialAuthStore } from './AuthStore';
import { DeviceVarsStore } from './DeviceVarsStore';

export const RootStore = t
    .model({
        hydrated: t.optional(t.boolean, false), // метка что состояние хранилища восстановлено из сохраненного в localstorage
        deviceVars: t.optional(DeviceVarsStore, {}),
        auth: t.optional(AuthStore, initialAuthStore),
    })
    .actions((self) => ({
        setHydrated: (value) => {
            self.hydrated = value;
        },
    }));
