import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox, message } from 'antd';

import RoleFunction from '../../config/roles';

import './pass.scss';

const QUERY = gql`
    query pass($filters: PassFiltersInput) {
        passes(filters: $filters, pagination: { limit: 1 }) {
            data {
                id
                attributes {
                    pass
                }
            }
        }
    }
`;

const CREATE = gql`
    mutation create($data: PassInput!) {
        createPass(data: $data) {
            data {
                id
            }
        }
    }
`;

const DELETE = gql`
    mutation delete($id: ID!) {
        deletePass(id: $id) {
            data {
                id
            }
        }
    }
`;


export default function CrmPass({ trainingId, clientId, passData }) {
    // при рендере массива пропусков, если есть данные о пропуске урока - оправляем в passData данные о нем, если нет - false

    // Query
    const [data, setData] = useState([passData]);
    const query = useQuery(QUERY, {
        variables: {
            filters: {
                client: { id: { eq: clientId } },
                training: { id: { eq: trainingId } },
            },
        },
        onCompleted: (data) => {
            setData(data.passes.data);
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка загрузки пропуска');
        },
        skip: passData !== undefined || passData === false

    });

    // Create
    const [create, createRes] = useMutation(CREATE);
    const [deletePass, deletePassRes] = useMutation(DELETE);
    // const onAdd = async (formData) => {
    //     const data = {
    //         client: clientId,
    //         training: trainingId,
    //         mark: formData.mark,
    //         activity: formData.activity,
    //         comment: formData.comment,
    //     };
    //
    //     await create({ variables: { data: data } });
    //     onUpdate;
    //     setShowAddModal(false);
    // };

    const onCheck = async (value) => {
        if (value) {
            // add
            await create({
                variables: {
                    data: {
                        client: clientId,
                        training: trainingId,
                        pass: true,
                    },
                },
            });
            query.refetch();
        } else {
            // delete
            await deletePass({
                variables: {
                    id: data[0].id,
                },
            });
            query.refetch();
        }
    };

    const pass = data[0] === undefined || data[0] === false ? false : true;

    return (
        <>
            {pass && (
                <span className={'color-danger subject-danger'}>
                    <span
                        className={'pass__checked'}
                        onClick={
                            RoleFunction.createPass()
                                ? () => onCheck(false)
                                : undefined
                        }
                    >
                        Н
                    </span>
                    {/*<CloseOutlined*/}
                    {/*    onClick={*/}
                    {/*        RoleFunction.createPass()*/}
                    {/*            ? () => onCheck(false)*/}
                    {/*            : undefined*/}
                    {/*    }*/}
                    {/*/>*/}
                </span>
            )}

            {!pass && RoleFunction.createPass() && (
                <Checkbox checked={pass} disabled={createRes.loading || deletePassRes.loading} onChange={(e) => onCheck(e.target.checked)}/>

            )}
        </>
    );
}
