import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {gql, useMutation} from '@apollo/client';

import CrmCenteredTitle from '../../../components/CrmCenteredTitle';
import CrmLayout from '../../../components/CrmLayout';
import {generateColor} from '../../../helpers/colors';
import {setValidatedContacts} from '../../../helpers/contactsValidate';
import generatePassword from '../../../helpers/generatePassword';
import {trainer_info} from '../../../routes';
import TrainerForm from '../TrainerForm';

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_TRAINER = gql`
    mutation users ($data: TrainerInput!) {
        createTrainer (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function AdminNew() {

    const [createUser] = useMutation(CREATE_USER);
    const [createTrainer] = useMutation(CREATE_TRAINER);

    const [loading, setLoading] = useState(false);
    const [password] = useState(generatePassword());

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: setValidatedContacts(data.contacts),
            blocked: false,
            role: 5,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const trainerDate = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            birthDate: data.birthDate,
            color: generateColor(),
            account: userId,
            enabled: true,
        }

        const res2 = await createTrainer({variables: {data: trainerDate}});
        const id = res2.data.createTrainer.data.id;

        navigate(trainer_info(id), {replace: true});

    }

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={'Новый администратор'}
            />

            <TrainerForm onSubmit={onSubmit} loading={loading} withEmail actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>

        </CrmLayout>
    );
}
