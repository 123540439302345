import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { training_info } from '../../routes';

import TrainingForm from './TrainingForm';

const CREATE = gql`
    mutation create($data: TrainingInput!, $massEdit: Boolean! ) {
        createTrainings(data: $data, massEdit: $massEdit) {
            data {
                id
            }
        }
    }
`;

export default function TrainingNew() {
    const { date, time } = useParams();

    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            name: data.name,
            room: data.room,
            subject: data.subject,
            group: data.group,
            comment: data.comment,
            trainers: data.trainers,
            startDate: moment(data.startDate).format(),
        };
        const res = await create({
            variables: { data: newData, massEdit: data.massEdit },
            onCompleted: () => {
                navigate(training_info(res.data.createTraining.data.id));
            }
        });

    };

    const data = {
        startDate: moment(date).hour(time),
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новый урок'} />

            <TrainingForm
                onSubmit={onSubmit}
                loading={loading}
                data={data}
                actionName={'Добавить'}
                withGroup
                withSubject
                withTrainer
            />
        </CrmLayout>
    );
}
