// INDEX
export const INDEX = '/';
export const CALENDAR = '/calendar/:date/:trainer/:group/:subject';
export function calendar(date, trainer, group, subject) {
    return `/calendar/${date}/${trainer}/${group}/${subject}`;
}

// AUTH
export const LOGIN = '/login';
export const ABOUT = '/about';
export const LOGOUT = '/logout';
export const FORGET_PASSWORD = '/forget_password';

// TRAINING
export const TRAINING = '/class';
export const TRAINING_NEW = '/class/new/:date/:time';
export const TRAINING_INFO = '/class/:id';
export const TRAINING_EDIT = '/class/:id/edit';
export function training_info(id) {
    return TRAINING + '/' + id;
}
export function training_edit(id) {
    return TRAINING + '/' + id + '/edit';
}
export function training_new(date, time) {
    return TRAINING + '/new/' + date + '/' + time;
}

// MY PAGE - SETTINGS
export const SETTINGS = '/settings';

// CHILDREN
export const CHILDREN = '/children';
export const CHILD_INFO = '/child/:id';
export const CHILD_EDIT = '/child/:id/edit';
export function child_info(id) {
    return `/child/${id}`;
}
export function child_edit(id) {
    return `/child/${id}/edit`;
}

// PARENTS
export const PARENT = '/parent';
export const PARENT_INFO = '/parent/:id';
export const PARENT_EDIT = '/parent/:id/edit';
export function parent_info(id) {
    return `/parent/${id}`;
}
export function parent_edit(id) {
    return `/parent/${id}/edit`;
}

// TEACHER
export const TEACHER = '/teacher';
export const TEACHER_NEW = '/teacher/new';

// ADMIN
export const ADMIN = '/admin';
export const ADMIN_NEW = '/admin/new';

// CURATOR
export const CURATOR = '/curator';
export const CURATOR_NEW = '/curator/new';

// TRAINER
export const TRAINER_INFO = '/user/:id';
export const TRAINER_EDIT = '/user/:id/edit';
export function trainer_info(id) {
    return '/user/' + id;
}
export function trainer_edit(id) {
    return '/user/' + id + '/edit';
}

// HOMEWORK
export const HOMEWORK = '/homework/:id';
export const HOMEWORKS = '/homeworks/:group/:mode/:date';
export const HOMEWORK_NEW = '/homework/new/:trainingTo';
export const HOMEWORK_EDIT = '/homework/:id/edit';
export function homeworks(group, mode, date) {
    return `/homeworks/${group}/${mode}/${date}`
}
export function homework_page(id) {
    return '/homework/' + id
}
export function homework_edit(id) {
    return '/homework/' + id + '/edit';
}
export function homework_new(trainingTo) {
    return `/homework/new/${trainingTo}`;
}

// 404
export const NOT_FOUND = '/404';


// PAYMENT
export const PAYMENT_OFFER = '/payment/offer/:public_key';
export const PAYMENT_SUCCESS = '/payment/success/:id';
export const PAYMENT_ERROR = '/payment/error/:id';

// INFO
export const INFO_CATERING = '/info/catering';
export const INFO_PAYMENT = '/info/payment';
export const INFO_PERSONAL_DATA = '/info/personal_data';

// MARKS
export const MARKS = '/marks';

// CHAT
export const CHAT = '/chat';


// GROUP
export const GROUP = '/group';
export const GROUP_NEW = '/group/new';
export const GROUP_INFO = '/group/:id';
export const GROUP_EDIT = '/group/:id/edit';
export function group_info(id) {
    return GROUP + '/' + id;
}
export function group_edit(id) {
    return GROUP + '/' + id + '/edit';
}
export function group_new(date, client) {
    return `${GROUP}/new/${date}/${client}`;
}

// ROOM
export const ROOM = '/room';
export const ROOM_NEW = '/room/new';
export const ROOM_EDIT = '/room/:id/edit';
export function room_edit(id) {
    return ROOM + '/' + id + '/edit';
}
export function room_info(id) {
    return ROOM + '/' + id;
}

// SUBJECT
export const SUBJECT = '/subject';
export const SUBJECT_NEW = '/subject/new';
export const SUBJECT_EDIT = '/subject/:id/edit';
export function subject_edit(id) {
    return SUBJECT + '/' + id + '/edit';
}

// JOURNAL
export const JOURNAL = '/journal/:quarter/:trainer/:group/:subject';
export function journal(quarter, trainer, group, subject) {
    return `/journal/${quarter}/${trainer}/${group}/${subject}`;
}

// PROGRESS
export const PROGRESS = '/progress/:quarter/:group/:child';
export function progress(quarter, group, child) {
    return `/progress/${quarter}/${group}/${child}`;
}

// ACTIVITIES
export const ACTIVITIES = '/activities';
export const ACTIVITIES_NEW = '/activities/new';
export const ACTIVITIES_EDIT = '/activities/:id/edit';
export function activity_edit(id) {
    return ACTIVITIES + '/' + id + '/edit';
}

// ACADEMY YEARS / QUATERES
export const YEARS = '/years';
export const YEAR = '/years/:id';
export const YEAR_NEW = '/years/new';
export const YEAR_EDIT = '/years/:id/edit';
export function year_edit(id) {
    return YEARS + '/' + id + '/edit';
}

// PLAN
export const PLAN = '/plan/:trainer/:subject/:group/:quarter';
export const PLAN_NEW = '/plan/new';
export const PLAN_EDIT = '/plan/:id';
export function plan(trainer, subject, group, quarter) {
    return `/plan/${trainer}/${subject}/${group}/${quarter}`;
}

// ORDERS
export const ORDERS = '/orders'

