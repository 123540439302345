import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import _ from 'lodash';

import {getValidatedContacts} from '../../helpers/contactsValidate';

const QUERY = gql`
    query users (
        $filters: ParentFiltersInput,
    ) {
        parents (filters: $filters, pagination: {pageSize: 200}) {
            data {
                id
                attributes {
                    enabled
                    account {
                        data {
                            attributes {
                                username
                            }
                        }
                    }
                }
            }
        }
    }
`;

const {Option} = Select;

export default function FilterParentsPhones(field) {

    const clientId = field.clientId;

    // Query
    const query = useQuery(QUERY, {variables: {filters: {clients: {id: {eq: clientId}}}}});

    return (
        <span className={'filter'}>
            <Select
                {...field}
                size={'small'}
                bordered={false}
                placeholder="Выберите номер телефона"

                filterOption={false}
                notFoundContent={query.loading ? <Spin size="small"/> : null}

                loading={query.loading}

            >
                {query.data && _.map(query.data.parents.data, (value) => {

                    const account = value.attributes.account.data;
                    const phone  = account.attributes.username;

                    return value.attributes.enabled
                        ?
                            <Option key={value.id} value={phone}>
                                {getValidatedContacts(phone)}
                            </Option>
                        :
                        null
                })}
            </Select>
        </span>
    );
}