import React from 'react';
import { Button, Form, InputNumber } from 'antd';

export default function BalanceForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            name="balance"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'balance'}
                label={'Сумма пополнения'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <InputNumber
                    precision={2}
                    className={'block'}
                    formatter={(value) =>
                        `${value} ₽`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    }
                    parser={(value) => value.replace(/[₽\s]/g, '')}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}

// (2500).toLocaleString('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });
