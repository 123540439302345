import React, {useState} from 'react';
import {useInstance} from 'react-ioc';
import {CopyOutlined, EditOutlined, LockOutlined} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Alert, Button, Divider, Form, Input, Space, Switch} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import debounce from 'debounce-promise';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import CrmModal from '../../../components/CrmModal';
import { setValidatedContacts} from '../../../helpers/contactsValidate';
import copyToClipboard from '../../../helpers/copyToClipboard';
import generatePassword from '../../../helpers/generatePassword';
import {Store} from '../../../model/store/Store';

const UPDATE_USER = gql`
    mutation email($data: UsersPermissionsUserInput!, $id: ID!) {
        updateUsersPermissionsUser(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const QUERY_PHONE = gql`
    query phone($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(filters: $filters) {
            data {
                id
                attributes {
                    username
                }
            }
        }
    }
`;

function ParentAccount({data, onUpdate}) {

    const {id} = data;
    const {auth} = useInstance(Store);
    const disabled = auth.role === 'Trainer' || auth.role === 'Child' || auth.role === 'Parent';

    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalPwd, setShowModalPwd] = useState(false);

    const [password, setPassword] = useState();

    const [updateUser] = useMutation(UPDATE_USER);

    const queryPhone = useQuery(QUERY_PHONE, {skip: true});

    const [form] = Form.useForm();

    const onChangeBlocked = async (value) => {
        await updateUser({variables: {data: {blocked: !value}, id: id}});
        onUpdate();
    };

    const request = debounce(async (value) => {
        const res = await queryPhone.refetch({filters: {username: {eq: value}}});
        if (res.data.usersPermissionsUsers.data.length > 0) {
            return Promise.resolve({exist: true});
        } else {
            return Promise.resolve({exist: false});
        }
    }, 1000);

    const onChangeLogin = async (formData) => {

        let contacts = _.map(data.attributes.contacts, (item) => {return {type: item.type, value: item.value}});
        contacts[0].value = formData.username;
        await updateUser({
            variables: {
                data: {
                    username: setValidatedContacts(formData.username),
                    contacts: contacts,
                },
                id: id}
        });
        setShowModalLogin(false);
        onUpdate();
    };

    const onResetPassword = async () => {
        const newPwd = generatePassword();
        await updateUser({variables: {data: {password: newPwd}, id: id}});
        setPassword(newPwd);
        onUpdate();
        setShowModalPwd(true);
    };

    return (
        <div>

            <Space direction={'vertical'} size={20} className={'block'}>


                {disabled &&
                <Alert message={'Недостаточно прав'}
                    description={'Заблокировать или разрешить доступ, изменить логин или сбросить пароль может только Администратор'}
                    type="warning"
                    showIcon closable/>
                }

                <div>
                    <Switch disabled={disabled} defaultChecked={!data.attributes.blocked} onChange={onChangeBlocked}
                        loading={updateUser.loading}/> Доступен личный кабинет
                </div>

                <div>
                    <Divider>Логин</Divider>
                    <Input.Group compact>
                        <Input size={'large'} value={data.attributes.username} style={{width: 'calc(100% - 40px)'}}/>
                        <Button size={'large'}
                            icon={<CopyOutlined onClick={() => copyToClipboard(data.attributes.username)}/>}/>
                    </Input.Group>
                    <Button type={'link'} disabled={disabled} onClick={() => {
                        setShowModalLogin(true);
                        form.resetFields()
                    }} icon={<EditOutlined/>}>Редактировать</Button>
                </div>

                <div>
                    <Divider>Пароль</Divider>
                    <Button size={'large'} disabled={disabled} onClick={() => {
                        onResetPassword();
                    }} icon={<LockOutlined/>}>Сбросить пароль</Button>
                </div>


                <CrmModal isOpen={showModalLogin} title={'Редактировать логин'}
                    onClose={() => setShowModalLogin(false)}>
                    <div className={'modal-basic-form'}>
                        <Form name="email"
                            form={form}
                            onFinish={onChangeLogin}
                            initialValues={{username: data.attributes.username}}
                            layout={'vertical'}
                            size={'large'}
                            scrollToFirstError
                        >
                            <Form.Item name={'username'}
                                label={'Номер телефона для входа'}
                                required
                                hasFeedback
                                validateFirst={true}
                                rules={[
                                    {required: true, message: 'Заполните поле',},
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve, reject) => {
                                                request(value).then((value) => {
                                                    if (value.exist) {
                                                        reject('Пользователь с таким номером уже есть')
                                                    } else {
                                                        resolve();
                                                    }
                                                });
                                            });
                                        }
                                    }),

                                ]}>
                                <MaskedInput mask={'+7 (111) 111-11-11'}/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                </CrmModal>


                <CrmModal isOpen={showModalPwd} title={'Успешно'} actionName={'Хорошо'}
                    onAction={() => setShowModalPwd(false)} onClose={() => setShowModalPwd(false)}>
                    <div className={'modal-basic-form'}>
                        Клиенту назначен новый пароль:
                        <div className={'highlight'}>{password}</div>
                        <Button size={'large'} onClick={() => {
                            copyToClipboard(password)
                        }}>Копировавть</Button>
                    </div>
                </CrmModal>


            </Space>

        </div>
    )

}

export default observer(ParentAccount);