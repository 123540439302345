import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ExclamationCircleOutlined,} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {message, Modal, Skeleton, Space, Tabs,} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmAvatar from '../../components/CrmAvatar';
import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmContact from '../../components/CrmContact';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import {getValidatedContacts} from '../../helpers/contactsValidate';
import {INDEX, NOT_FOUND, trainer_edit,} from '../../routes';

import TrainerAccount from './TrainerAccount';

const QUERY = gql`
    query query ($id: ID) {
        trainer(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    color
                    enabled
                    birthDate
                    account {
                        data {
                            id
                            attributes {
                                username
                                email
                                contacts {
                                    type
                                    value
                                }
                                role {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    subjects {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE = gql`
    mutation delete($id: ID!, $data: TrainerInput!) {
        updateTrainer(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function TrainerInfo() {

    const {id} = useParams();
    const navigate = useNavigate();

    const query = useQuery(QUERY, {
        variables: {id: id},
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            message.error('Ошибка получения данных');
            console.error(error);
        },
    });

    const [deleteMutation] = useMutation(DELETE);

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить сотрудника?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }
        });
    }

    async function onConfirmedDelete() {
        await deleteMutation({variables: {id: id, data: {enabled: false}}});
        navigate(INDEX);
    }

    let displayTitle = '–';
    let displayContacts = null;
    let displaySubjects = null;
    let displayBirthDate = null;

    if (query.data) {

        const trainer = query.data.trainer.data;

        // если такого клиента нет, то отправляем в 404
        if (!trainer) {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }

        const account = trainer.attributes.account.data;
        const contacts = account.attributes.contacts;

        const firstName = trainer.attributes.firstName;
        const lastName = trainer.attributes.lastName;
        const middleName = trainer.attributes.middleName;

        const subjects = trainer.attributes.subjects.data;

        displayTitle = middleName ? `${lastName} ${firstName} ${middleName}` : `${firstName} ${lastName}`;

        // Контакты
        if (contacts.length > 0) {
            displayContacts = (
                <>
                    <Space direction={'vertical'} size={10}>
                        {_.map(contacts, (value, index) => {
                            return (
                                <CrmContact key={index} type={value.type} value={getValidatedContacts(value.value)}/>
                            )
                        })}
                    </Space>
                </>
            )
        }

        // Пердметы
        if (subjects.length > 0) {
            displaySubjects = (
                <CrmDisplayData label={'Предметы'} value={
                    <Space size={10}>
                        {_.map(subjects, (value, index) => {
                            return (
                                <span key={index}>{value.attributes.name}</span>
                            )
                        })}
                    </Space>
                }/>
            )
        }

        // день рождения
        if (trainer.attributes.birthDate) {
            displayBirthDate = <CrmDisplayData label={'День рождения'}
                value={moment(trainer.attributes.birthDate).format('DD MMMM YYYY')}/>
        }

    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={displayTitle}
                editAction={RoleFunction.updateTrainer() && (() => {navigate(trainer_edit(id))})}
                deleteAction={RoleFunction.deleteTrainer() && (() => {onDelete()})}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&

            <Space direction='vertical' size={20} className='block'>

                <CrmAvatar
                    size={'large'}
                    firstName={query.data.trainer.data.attributes.firstName}
                    lastName={query.data.trainer.data.attributes.lastName}
                    middleName={query.data.trainer.data.attributes.middleName}
                    color={query.data.trainer.data.attributes.color}
                />

                {displaySubjects}

                {displayContacts}

                {displayBirthDate}

                <Tabs defaultActiveKey="1">

                    {RoleFunction.updateTrainer() &&
                    <Tabs.TabPane key="account" tab={'Аккаунт'}>
                        <TrainerAccount data={query.data.trainer.data.attributes.account.data}
                            onUpdate={query.refetch}/>
                    </Tabs.TabPane>
                    }

                </Tabs>

            </Space>

            }

        </CrmLayout>
    );
}
