import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { YEARS } from '../../routes';

import { YearForm } from './YearForm';

const CREATE_YEAR = gql`
    mutation createYear($data: YearInput!) {
        createYear(data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_QUARTER = gql`
    mutation createQuarter($data: QuarterInput!) {
        createQuarter(data: $data) {
            data {
                id
            }
        }
    }
`;

export const YearNew = () => {
    const navigate = useNavigate();

    const [createYear] = useMutation(CREATE_YEAR);
    const [createQuarter] = useMutation(CREATE_QUARTER);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);

        const yearData = {
            startDate: moment()
                .set('year', data.name.split('/')[0]) // достаем начальную дату из названия
                .startOf('year')
                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
            endDate: moment()
                .set('year', data.name.split('/')[0]) // достаем конечную дату из названия
                .endOf('year')
                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
            name: data.name,
            quarters: [],
        };

        const response = await createYear({
            variables: {
                data: yearData,
            },
        });

        const yearId = response.data.createYear.data.id;

        _.map(data.quarters, async (quarter) => {
            await createQuarter({
                variables: {
                    data: {
                        endDate: moment(quarter.endDate)
                            .endOf('day')
                            .format('YYYY-MM-DD'),
                        startDate: moment(quarter.startDate)
                            .startOf('day')
                            .format('YYYY-MM-DD'),
                        name: quarter.name,
                        year: yearId,
                    },
                },
            });
        });

        navigate(YEARS);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новый учебный год'} />
            <YearForm
                onSubmit={onSubmit}
                loading={loading}
                actionName={'Добавить'}
                actionIcon={<PlusOutlined />}
            />
        </CrmLayout>
    );
};
