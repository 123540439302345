import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import moment from 'moment-timezone';

import 'moment/locale/ru';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/index.css';
// Styles and locales
import './styles/App.scss';

moment.tz.setDefault('Asia/Novosibirsk');
moment.updateLocale('ru', { week: { dow: 1 } });

ReactDOM.render(
    <ConfigProvider locale={ru_RU}>
        <App />
    </ConfigProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
