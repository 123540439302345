import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import {setValidatedContacts} from '../../helpers/contactsValidate';
import {child_info} from '../../routes';

import ChildForm from './ChildForm';

const QUERY = gql`
    query query (
        $id: ID,
    ) {
        client(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    birthDate
                    enabled
                    account {
                        data {
                            id
                            attributes {
                                email
                                blocked
                                contacts {
                                    type
                                    value
                                }
                                group {
                                    data {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDDATE_USER = gql`
    mutation users ($id: ID!, $data: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE_CHILD = gql`
    mutation users ($id: ID!, $data: ClientInput!) {
        updateClient (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ChildEdit() {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: (data) => {

            const client = data.client.data;
            const account = client.attributes.account.data;
            const contacts = account.attributes.contacts;
            const group = account.attributes.group.data;

            setData({
                firstName: client.attributes.firstName,
                lastName: client.attributes.lastName,
                middleName: client.attributes.middleName,
                birthDate: client.attributes.birthDate && moment(client.attributes.birthDate),
                contacts: _.map(contacts, (value) => {return {type: value.type, value: value.value}}),
                group: group && group.id,
            });

            setAccountId(account.id);

        }
    });

    const [updateUser] = useMutation(UPDDATE_USER);
    const [updateChild] = useMutation(UPDATE_CHILD);

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setLoading(false);
            setAccountId(null);
            setData(null)
        };
    }, []);


    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            contacts: setValidatedContacts(data.contacts),
            group: data.group,
        };
        await updateUser({variables: {id: accountId, data: userData}});

        const childData = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            birthDate: data.birthDate && moment(data.birthDate).format('YYYY-MM-DD'),
        };
        await updateChild({variables: {id: id, data: childData}});

        navigate(child_info(id));

    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={query.data ? `${query.data.client.data.attributes.firstName} ${query.data.client.data.attributes.lastName}` : '—'}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <ChildForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Сохранить'} withGroup/>

            </>
            }

        </CrmLayout>
    );
}
