import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {homework_page} from '../../routes';

export const HomeworkCard = (props) => {
    const {subject, title, comment, id, className} = props;

    return (
        <Link
            to={homework_page(id)}
            className={cn(['homeworks_card', className])}
        >
            <div className={'homeworks_subject'}>{subject}</div>
            <div className={'bold homeworks_title'}>{title}</div>
            <div className={'crm-message-text'}>{comment}</div>
        </Link>
    );
};
