import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber } from 'antd';

import SelectActivity from '../../../components/Form/SelectActivity';

export default function MarkForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
    withDelete, onDelete, deleting
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            name="mark"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={'large'}
            disabled={loading || deleting}
            scrollToFirstError
        >
            <Form.Item
                name={'mark'}
                label={'Оценка (0 — 100)'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <InputNumber min={0} max={100} className={'block'} />
            </Form.Item>

            <Form.Item
                name={'activity'}
                label={'Вид деятельности'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <SelectActivity />
            </Form.Item>

            <Form.Item name={'comment'} label={'Комментарий'}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>

            <Form.Item>
                <div className={'crm-line'}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={actionIcon}
                        loading={loading}
                    >
                        {actionName}
                    </Button>

                    {withDelete &&
                        <Button type="secondary" icon={<DeleteOutlined/>} loading={deleting} onClick={onDelete}>
                            Удалить
                        </Button>
                    }
                
                </div>
            </Form.Item>
        </Form>
    );
}
