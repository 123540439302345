import React from 'react';
import {DatePicker} from 'antd';

import './filters.scss';


export default function FilterDate(field) {
    return (
        <span className={'filter'}>
            <DatePicker
                size={'small'}
                bordered={false}
                format={'DD.MM.YYYY'}
                allowClear={false}
                {...field}
            />
        </span>
    );
}
