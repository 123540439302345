import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './filters.scss';

export default function FilterNext(field) {
    return (
        <span className={'filter'}>
            <Button
                type={'link'}
                size={'small'}
                shape={'circle'}
                icon={<ArrowRightOutlined />}
                {...field}
            />
        </span>
    );
}
