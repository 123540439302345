import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Button, Empty, message, Skeleton } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { room_edit, ROOM_NEW } from '../../routes';

import './room.scss';

const QUERY = gql`
    query query(
        $filters: RoomFiltersInput
        $pagination: PaginationArg
        $sort: [String] = []
    ) {
        rooms(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Rooms() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const query = useQuery(QUERY, {
        variables: {
            sort: ['name:ASC'],
            pagination: {
                limit: 200,
            },
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.rooms.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Кабинеты'}
                addAction={() => navigate(ROOM_NEW)}
            />

            {query.loading && <Skeleton active />}
            {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {_.map(data, (room) => {
                return (
                    <div key={room.id} className={'room-line'}>
                        <div>🚪 {room.attributes.name}</div>

                        <Button
                            type={'link'}
                            icon={<EditOutlined />}
                            onClick={() => navigate(room_edit(room.id))}
                        />
                    </div>
                );
            })}
        </CrmLayout>
    );
}
