import React from 'react';
import _ from 'lodash';
import moment from 'moment/moment';

import { HomeworkCard } from './HomeworkCard';
import { HomeworkHeader } from './HomeworkHeader';

export const HomeworkOnWeek = (props) => {
    const { data, range } = props;

    const groupedData = _.sortBy(
        _.values(_.groupBy(data, 'attributes.dueDate')),
        (el) => {
            return moment(el[0].attributes.dueDate).format('DDDD');
        },
    );

    function setTasksToGroups(tasks) {
        const formattedData = new Array(5);
        let i = 0;
        // дополнительный индекс для массива с заданиями,
        // что бы была возможность пропускать пустые дни
        return _.map(formattedData, (el, index) => {
            if (
                tasks[i] &&
                tasks[i].length &&
                // поскольку дата у всех ДЗ одинаковая, и мы точно знаем,
                // что в массиве есть хотя бы 1 элементы, можем по нему и ориентироваться
                moment(tasks[i][0].attributes.dueDate).date() ===
                    // сравниваем каждый раз именно через moment, что бы избежать ошибок типа 32, 33 дат месяца
                    moment(range).startOf('week').add(index, 'day').date()
            ) {
                ++i;
                return tasks[i - 1];
                // только после успешного нахождения списка заданий мы двигаемся вперед по массиву,
                // но, по скольку i после выхода из итерации увеличить нельзя, приходится костылить:
                // сначала увеличиваем её, а потом отнимаем, что бы указать на нужный элемент массива
            }
            return null;
        });
    }

    return (
        <div className={'homework__week'}>
            <HomeworkHeader daysOfWeek={range} />
            <div className={'homework__content'}>
                {_.map(setTasksToGroups(groupedData), (dayTasks) => {
                    return (
                        <div
                            className={'homework__day_column'}
                            key={Math.random()}
                        >
                            {_.map(dayTasks, (task) => {
                                return (
                                    <HomeworkCard
                                        subject={
                                            task.attributes.trainingTo.data
                                                .attributes.subject.data
                                                .attributes.name
                                        }
                                        title={task.attributes.name}
                                        comment={task.attributes.comment}
                                        key={task.id}
                                        id={task.id}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
