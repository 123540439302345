import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

const GET_CHILDS = gql`
    query getChilds($filters: ClientFiltersInput) {
        clients(
            filters: $filters
            pagination: { limit: 1000 }
            sort: ["lastName:ASC", "firstName:ASC"]
        ) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    account {
                        data {
                            attributes {
                                group {
                                    data {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const { Option } = Select;

export function FilterChild(props) {
    const { group, ...field } = props;
    const [data, setData] = useState([]);

    // Query
    const query = useQuery(GET_CHILDS, {
        variables: {
            filters: {
                ...(group ? { account: { group: { id: { eq: group } } } } : {}),
                enabled: { eq: true },
            }
        },
        onCompleted: (data) => {
            setData(data.clients.data);
        },
    });

    let fieldData = {
        ...field,
    };
    delete fieldData.withAll;
    delete fieldData.withNull;
    delete fieldData.filterSubject;

    return (
        <span className={cn([field.className, 'filter'])}>
            <Select
                size={'small'}
                bordered={false}
                placeholder="Выберите ребенка"
                filterOption={false}
                notFoundContent={query.loading ? <Spin size="small" /> : null}
                loading={query.loading}
                className={'block'}
                dropdownMatchSelectWidth={false}
                {...fieldData}
            >
                {field.withAll && (
                    <Option key={'0'} value={'0'}>
                        Все дети
                    </Option>
                )}

                {field.withNull && (
                    <Option key={'0'} value={'0'}>
                        Ребенок не выбран
                    </Option>
                )}

                {_.map(data, (item) => {
                    const child = item.attributes;

                    return (
                        <Option key={item.id} value={item.id}>
                            {child.lastName} {child.firstName}
                        </Option>
                    );
                })}
            </Select>
        </span>
    );
}
