import React from 'react';
import { Button, Form, Input } from 'antd';

import RoleFunction from '../../config/roles';

export default function ActivityForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        RoleFunction.updateActivity() &&
        onSubmit(values);
    };

    return (
        <Form
            name="subject"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'name'}
                label={'Название'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input placeholder={'Домашнее задание'}/>
            </Form.Item>

            <Form.Item
                name={'abbr'}
                label={'Буквенное обозначение'}
                required
            >
                <Input placeholder={'АБ'} />
            </Form.Item>

            <Form.Item name={'coefficient'} label={'Коэффициент'} required>
                <Input placeholder={'2'} type={'number'} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
