import React, { useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, message } from 'antd';

import ModalChildAdd from '../../components/ModalChildAdd';
import ModalClientsSelect from '../../components/ModalClientsSelect';
import RoleFunction from '../../config/roles';

const UPDATE = gql`
    mutation upddate($data: GroupInput!, $id: ID!) {
        updateGroup(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function GroupClientsSelectAndAdd({ id, users, onUpdate }) {
    const [update] = useMutation(UPDATE);

    // Update Users
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        const newUsers = value.map(function (item) {
            return item.id;
        });

        await update({
            variables: { data: { users: newUsers }, id: id },
            onCompleted: () => {
                onUpdate();
                setModalSelectVisible(false);
            },
            onError: () => {
                message.error('Ошибка обработки данных');
            },
        });
    };

    // Add Visit with New Client
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddClient = async function () {
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <div className={'crm-line'}>
                {RoleFunction.updateGroup() && (
                    <Button
                        type={'link'}
                        icon={<EditOutlined />}
                        onClick={() => setModalSelectVisible(true)}
                    >
                        Выбрать из списка
                    </Button>
                )}
                {RoleFunction.createClient() && (
                    <Button
                        type={'link'}
                        icon={<PlusOutlined />}
                        onClick={() => setModalAddVisible(true)}
                    >
                        Создать ученика
                    </Button>
                )}
            </div>

            <ModalClientsSelect
                isOpen={modalSelectVisible}
                onClose={() => setModalSelectVisible(false)}
                selected={users}
                onFinish={(value) => onSaveList(value)}
            />
            <ModalChildAdd
                withGroup={id}
                isOpen={modalAddVisible}
                onClose={() => setModalAddVisible(false)}
                onFinish={(userId, clientId) => onAddClient(userId, clientId)}
            />
        </>
    );
}
