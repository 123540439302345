import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Empty, Modal, Skeleton, Space } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { YEAR_NEW } from '../../routes';

import { YearRow } from './YearRow';

const GET_YEARS = gql`
    query years($filters: YearFiltersInput) {
        years(filters: $filters, pagination: { limit: 200 }) {
            data {
                id
                attributes {
                    name
                    startDate
                    endDate
                }
            }
        }
    }
`;

const DELETE_YEAR = gql`
    mutation deleteYear($id: ID!) {
        deleteYear(id: $id) {
            data {
                id
            }
        }
    }
`;

const DELETE_QUATRE = gql`
    mutation deleteQuarter($id: ID!) {
        deleteQuarter(id: $id) {
            data {
                id
            }
        }
    }
`;

export const Years = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const query = useQuery(GET_YEARS, {
        variables: {
            filters: {},
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.years.data);
        },
    });

    const [deleteYear] = useMutation(DELETE_YEAR);
    const [deleteQuarter] = useMutation(DELETE_QUATRE);


    async function onConfirmedDelete(id) {
        await deleteYear({
            variables: {
                id: id,
            },
        });

        _.map(data, async (quarter) => {
            await deleteQuarter({
                variables: {
                    id: quarter.id,
                },
            });
        });

        await query.refetch();
    }

    function onDelete(id) {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить учебный год?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete(id);
            },
        });
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Учебные года'}
                addAction={
                    RoleFunction.updateYears() &&
                    (() => {
                        navigate(YEAR_NEW);
                    })
                }
            ></CrmCenteredTitle>

            <Space direction={'vertical'} size={20} className={'block '}>
                <div className={'visit-list-row'}>
                    <div className={'activity-col-name'}>
                        <span className={'caption color-gray-3'}>Название</span>
                    </div>
                </div>
            </Space>

            {query.loading && <Skeleton active />}
            {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {data.length &&
                _.map(data, (year) => {
                    const { name } = year.attributes;

                    return (
                        <YearRow
                            name={name}
                            key={year.id}
                            yearId={year.id}
                            deleteYear={onDelete}
                        />
                    );
                })}
        </CrmLayout>
    );
};

