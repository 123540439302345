import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {gql, useMutation} from '@apollo/client';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';

import HomeworkForm from './HomeworkForm';

const CREATE_TASK = gql`
    mutation createTask($data: TaskInput!) {
        createTask(data: $data) {
            data {
                id
            }
        }
    }
`;

export const HomeworkNew = () => {
    const [createTask, createTaskRes] = useMutation(CREATE_TASK);

    const { trainingTo } = useParams();

    const navigate = useNavigate();

    const onSubmit = async (data) => {

        console.log('data', data);

        await createTask({
            variables: { data: data },
        });
        navigate(-1);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новое задание'} />
            <HomeworkForm
                onSubmit={onSubmit}
                loading={createTaskRes.loading}
                actionName={'Выдать задание'}
                actionIcon={<PlusOutlined />}
                data={{
                    trainingTo: trainingTo
                }}
            />
        </CrmLayout>
    )
};
