import React from 'react';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, DatePicker, Divider, Form, Input} from 'antd';
import {MaskedInput} from 'antd-mask-input';
import _ from 'lodash';
import moment from 'moment/moment';

import RoleFunction from '../../config/roles';

export const YearForm = (props) => {
    const {initialData, onSubmit, loading, actionName, actionIcon} = props;

    const [form] = Form.useForm();

    const onFinish = (values) => {
        RoleFunction.updateYears() && onSubmit(values);
    };

    const quarterOrder = {
        0: 'I',
        1: 'II',
        2: 'III',
        3: 'IV',
    };
    const startYear = moment().format('YYYY');
    const endYear = moment().add(1, 'year').format('YYYY');

    const initialValues = initialData
        ? {
            name: initialData.attributes.name,
            quarters: _.map(
                initialData.attributes.quarters.data,
                (quarter) => ({
                    name: quarter.attributes.name,
                    endDate: moment(quarter.attributes.endDate),
                    startDate: moment(quarter.attributes.startDate),
                }),
            ),
        }
        : {};

    return (
        <Form
            name="subject"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout={'vertical'}
            size={'large'}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'name'}
                label={'Название'}
                required
                rules={[{required: true, message: 'Пропущено название'}]}
            >
                <MaskedInput
                    mask={'2011/2011'}
                    placeholder={startYear + '/' + endYear}
                />
            </Form.Item>

            <Divider>Четверти</Divider>

            <Form.List name="quarters">
                {(fields, {add, remove}) => (
                    <>
                        {fields.map(({key, name, ...restField}) => (
                            <div key={key} className={'quarter-form-list'}>
                                <Form.Item
                                    {...restField}
                                    className={'year-form-column'}
                                    label={'Название'}
                                    name={[name, 'name']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пропущено название',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={`${quarterOrder[name]} четверть`}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    className={'year-form-column'}
                                    label={'Начало'}
                                    name={[name, 'startDate']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пропущена начальная дата',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={'quarter-form-datePiker'}
                                        placeholder={`01.01.${startYear}`}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    className={'year-form-column'}
                                    label={'Конец'}
                                    name={[name, 'endDate']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пропущена конечная дата',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={'quarter-form-datePiker'}
                                        placeholder={`01.01.${startYear}`}
                                    />
                                </Form.Item>

                                <Button
                                    type="dashed"
                                    onClick={() => remove(name)}
                                    icon={<MinusOutlined/>}
                                    loading={loading}
                                    className="quarter-add"
                                />
                            </div>
                        ))}

                        {fields.length < 4 && (
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined/>}
                                    loading={loading}
                                />
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
};
