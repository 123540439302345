import React from 'react';
import { DatePicker, Space, TimePicker } from 'antd';

export default function DateAndTimePicker(field) {
    // Render
    return (
        <Space size={10}>
            <DatePicker
                {...field}
                format={'DD.MM.YYYY'}
                size={field.size}
                allowClear={false}
            />
            <TimePicker
                {...field}
                format={'HH:mm'}
                size={field.size}
                allowClear={false}
            />
        </Space>
    );
}
