import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';

import {setValidatedContacts} from '../helpers/contactsValidate';
import generatePassword from '../helpers/generatePassword';
import ChildForm from '../pages/Children/ChildForm';

import CrmModal from './CrmModal';

const CREATE_USER = gql`
    mutation user($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser(data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_CHILD = gql`
    mutation client($data: ClientInput!) {
        createClient(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ModalChildAdd({
    isOpen,
    onClose,
    onFinish,
    withGroup,
}) {
    const [createUser] = useMutation(CREATE_USER);
    const [createChild] = useMutation(CREATE_CHILD);

    const [loading, setLoading] = useState(false);
    const password = generatePassword();

    const onSubmit = async (data) => {
        setLoading(true);

        // 6 - ребенок
        // 7 - взрослый

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: setValidatedContacts(data.contacts),
            blocked: false,
            role: 6,
            group: withGroup,
        };

        const res = await createUser({ variables: { data: userData } });
        const userId = res.data.createUsersPermissionsUser.data.id;

        const clientData = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            birthDate:
                data.birthDate && moment(data.birthDate).format('YYYY-MM-DD'),
            account: userId,
            enabled: true,
        };

        const res2 = await createChild({ variables: { data: clientData } });
        const clientId = res2.data.createClient.data.id;

        onFinish(userId, clientId);
        setLoading(false);
    };

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Создать ученика'}>
            <div className={'modal-basic-form'}>
                <ChildForm
                    onSubmit={onSubmit}
                    loading={loading}
                    withEmail
                    actionName={'Добавить'}
                    actionIcon={<PlusOutlined />}
                />
            </div>
        </CrmModal>
    );
}
