import React, {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {gql, useMutation} from '@apollo/client';
import moment from 'moment';

import CrmModal from '../../components/CrmModal';
import {setValidatedContacts} from '../../helpers/contactsValidate';
import generatePassword from '../../helpers/generatePassword';
import ParentForm from '../Client/Parent/ParentForm';

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_PARENT = gql`
    mutation users ($data: ParentInput!) {
        createParent (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ModalParentAdd({isOpen, onClose, onFinish, childId}) {

    const [createUser] = useMutation(CREATE_USER);
    const [createParent] = useMutation(CREATE_PARENT);

    const [loading, setLoading] = useState(false);
    const [password] = useState(generatePassword());

    const onSubmit = async (data) => {
        setLoading(true);
        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: setValidatedContacts(data.username),
            email: `${generatePassword()}@proschoolnsk.ru`,
            password: password,
            contacts: setValidatedContacts([{type: 'phoneWhatsapp', value: data.username}, ...data.contacts]),
            blocked: false,
            role: 7,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const parentData = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            birthDate: data.birthDate && moment(data.birthDate).format('YYYY-MM-DD'),
            account: userId,
            clients: [childId],
            enabled: true,
        }
        const res2 = await createParent({variables: {data: parentData}});
        const parentId = res2.data.createParent.data.id;

        onFinish(parentId);

    }

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Добавить родителя'}>
            <div className={'modal-basic-form'}>

                <ParentForm onSubmit={onSubmit} loading={loading} withAccount actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>

            </div>
        </CrmModal>
    )

}