import { React, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined, IdcardOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import axios from 'axios';
import moment from 'moment';

import CrmWindowSpin from '../../components/CrmWindowSpin';
import { getRestApiBase } from '../../env';
import logo_bs from '../../images/logo_bs.svg';

function usePost(publicKey) {
    return useQuery('getSubscriptionData', async () => {
        const { data } = await axios.get(
            `${getRestApiBase()}/payment-order?publicKey=${encodeURIComponent(
                publicKey,
            )}`,
        );
        return data;
    });
}

export default function PaymentOffer() {
    const { public_key } = useParams();

    const { data, error, isFetching } = usePost(public_key);

    // console.log('data', data);

    let DisplayNumber = '';
    let DisplayClient = '';
    let DisplayGym = '';
    let DisplayRange = '';
    let DisplayLimit = '';
    let DisplayPrice = '';

    if (data !== undefined) {
        DisplayNumber = data.id;
        DisplayClient = `${data.client.firstName} ${data.client.lastName}`;
        DisplayGym = `${data.gym.name} ${data.gym.address}`;
        DisplayRange = `${moment(data.startDate).format(
            'DD MMM YYYY',
        )} — ${moment(data.endDate).format('DD MMM YYYY')}`;
        DisplayPrice = data.price;
    }

    const [loadingLink, setLoadingLink] = useState(false);

    const onSubmit = async () => {
        setLoadingLink(true);

        const link = await axios.post(
            `${getRestApiBase()}/payment-link?subscriptionId=${encodeURIComponent(
                data.id,
            )}`,
        );

        const url = String(link.data);
        // console.log('url', url);

        window.location.assign(url);

        setLoadingLink(false);
    };

    if (error) {
        message.error(error.message);
    }

    return (
        <div className={'crm-payment-body'}>
            <div className={'crm-payment-layout'}>
                <div className={'crm-payment-logo'}>
                    <img src={logo_bs} alt="logo" />
                </div>

                <div className={'crm-payment-icon'}>
                    <IdcardOutlined />
                </div>

                {isFetching && <CrmWindowSpin spinning />}

                {!isFetching && !error && (
                    <>
                        <div className={'title'}>
                            Абонемент №{DisplayNumber}
                        </div>

                        <div className={'crm-payment-text'}>
                            <div className={'crm-payment-text'}>
                                {DisplayClient}
                            </div>
                            <div className={'crm-payment-text'}>
                                {DisplayGym}
                            </div>
                            <div className={'crm-payment-text'}>
                                {DisplayRange}
                            </div>
                            <div className={'crm-payment-text'}>
                                {DisplayLimit}
                            </div>
                            <p className={'bold'}>{DisplayPrice} ₽</p>

                            {data.paid ? (
                                <span className={'color-success'}>
                                    <CheckCircleOutlined /> Оплачено
                                </span>
                            ) : (
                                <Button
                                    type={'primary'}
                                    size={'large'}
                                    style={{
                                        backgroundColor: '#FDB913',
                                        color: '#000',
                                        borderColor: '#FDB913',
                                    }}
                                    loading={loadingLink}
                                    onClick={onSubmit}
                                >
                                    Оплатить
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
