import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment/moment';

import CrmModal from '../../components/CrmModal';
import RoleFunctions from '../../config/roles';
import { training_info } from '../../routes';
import HomeworkForm from '../Homework/HomeworkForm';

import { PlanTask } from './PlanTask';

import classes from './plan.module.scss';

const UPDATE_TRAINING = gql`
    mutation updateTraining($data: TrainingInput!, $id: ID!) {
        updateTraining(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const CREATE_TASK = gql`
    mutation createTask($data: TaskInput!) {
        createTask(data: $data) {
            data {
                id
            }
        }
    }
`;

export const PlanRow = (props) => {
    const { training, tasks, refetch } = props;

    const [state, setState] = useState({
        mode: 0,
        name: training.attributes.name,
        editTask: false,
        createTask: false,
    });

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    const [updateTraining, updateTrainingData] = useMutation(UPDATE_TRAINING);
    const [createTask, createTaskRes] = useMutation(CREATE_TASK);

    function escFunction(event) {
        if (event.key === 'Escape') {
            setState({ ...state, mode: 0 });
        }
    }

    async function saveNewName() {
        RoleFunctions.updatePlan &&
            (await updateTraining({
                variables: {
                    id: training.id,
                    data: {
                        name: state.name,
                    },
                },
            }));
        refetch();
    }

    const onSubmit = async (data) => {
        await createTask({
            variables: { data: data },
        });
        setState({ ...state, editTask: false });
        refetch();
    };

    return (
        <div className={classes.content_row}>
            <Link
                to={training_info(training.id)}
                className={classes.content_date}
            >
                {moment(training.attributes.startDate).format('dd, DD MMMM')}
            </Link>

            {state.mode === 0 ? (
                training.attributes.name ? (
                    <div
                        onClick={() => setState({ ...state, mode: 1 })}
                        className={cn([
                            classes.task__name,
                            classes.content_name,
                        ])}
                    >
                        {training.attributes.name}
                    </div>
                ) : (
                    <div
                        onClick={() => setState({ ...state, mode: 1 })}
                        className={cn([
                            classes.name__empty,
                            classes.content_name,
                        ])}
                    >
                        Нет темы
                    </div>
                )
            ) : (
                <Input
                    className={classes.content_name}
                    size={'small'}
                    placeholder={'Нет темы'}
                    defaultValue={state.name}
                    onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                    }
                    onPressEnter={() => {
                        setState({ ...state, mode: 0 });
                        return saveNewName();
                    }}
                    suffix={
                        <Button
                            type={'link'}
                            icon={<SaveOutlined />}
                            onClick={() => {
                                setState({ ...state, mode: 0 });
                                return saveNewName();
                            }}
                            loading={updateTrainingData.loading}
                        />
                    }
                />
            )}

            {tasks.length ? (
                <div className={classes.tasks_column}>
                    {_.map(tasks, (task) => {
                        return (
                            <PlanTask
                                task={task}
                                training={training}
                                key={task.id + Math.random()}
                                refetch={refetch}
                            />
                        );
                    })}
                </div>
            ) : (
                <>
                    <div
                        className={cn([
                            classes.task__empty,
                            classes.content_task,
                        ])}
                    >
                        {'Нет задания'}
                    </div>

                    {RoleFunctions.createTask() &&
                    <>
                        <Button
                            className={classes.content_button}
                            type={'link'}
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setState({ ...state, editTask: true });
                            }}
                        />
                        <CrmModal
                            isOpen={state.editTask}
                            onClose={() => setState({ ...state, editTask: false })}
                            title={'Выдать задание'}
                        >
                            <div className={'modal-basic-form'}>
                                <HomeworkForm
                                    onSubmit={onSubmit}
                                    loading={createTaskRes.loading}
                                    actionName={'Создать'}
                                    actionIcon={<PlusOutlined />}
                                    data={{
                                        trainingTo: training.id
                                    }}
                                />
                            </div>
                        </CrmModal>
                    </>}

                </>
            )}
        </div>
    );
};
