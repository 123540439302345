import React, {useEffect, useRef, useState} from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Empty, message, Skeleton, Space } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import FilterGroups from '../../components/Filters/FilterGroups';
import FilterQuarters from '../../components/Filters/FilterQuarters';
import Filters from '../../components/Filters/Filters';
import FilterSubjects from '../../components/Filters/FilterSubjects';
import FilterTrainers from '../../components/Filters/FilterTrainers';
import { Store } from '../../model/store/Store';
import { plan } from '../../routes';

import { PlanRow } from './PlanRow';

import classes from './plan.module.scss';

const GET_PLAN = gql`
    query getPlan(
        $groupId: ID!
        $quarterId: ID!
        $subjectId: ID!
        $trainerId: ID!
    ) {
        getPlan(
            groupId: $groupId
            quarterId: $quarterId
            subjectId: $subjectId
            trainerId: $trainerId
        ) {
            data {
                training {
                    id
                    attributes {
                        startDate
                        name
                        subject {
                            data {
                                id
                                attributes {
                                    name
                                }
                            }
                        }
                        group {
                            data {
                                id
                            }
                        }
                    }
                }
                tasks {
                    id
                    attributes {
                        name
                        comment
                        trainingTo {
                            data {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Plan = () => {
    const { trainer, subject, group, quarter } = useParams();

    const navigate = useNavigate();
    const { auth } = useInstance(Store);

    const [data, setData] = useState([]);
    const firstLoading = useRef(true)

    const query = useQuery(GET_PLAN, {
        variables: {
            groupId: group,
            quarterId: quarter,
            subjectId: subject,
            trainerId: trainer,
        },
        onCompleted: (data) => {
            setData(data.getPlan.data);
            firstLoading.current = false
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения данных');
        },
        skip:
            quarter === '0' ||
            trainer === '0' ||
            subject === '0' ||
            group === '0',
    });

    useEffect(() => {
        if (auth.role === 'Teacher') {
            navigate(plan(auth.id, subject, group, quarter));
        }
    }, []);

    // изменение учителя
    function onChangeTrainer(value) {
        navigate(plan(value, subject, group, quarter));
    }

    // изменение предмета
    function onChangeSubject(value) {
        navigate(plan(trainer, value, group, quarter));
    }

    // изменение класса
    function onChangeGroup(value) {
        navigate(plan(trainer, subject, value, quarter));
    }

    // изменение четверти
    function onChangeQuarter(value) {
        navigate(plan(trainer, subject, group, value));
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Учебный план'} />
            <div className={'crm-layout calendar-filter'}>
                <Filters>
                    <FilterQuarters
                        withNull
                        value={quarter}
                        onChange={(value) => onChangeQuarter(value)}
                    />
                    <FilterGroups
                        withNull
                        value={group}
                        onChange={(value) => onChangeGroup(value)}
                    />
                    <FilterSubjects
                        withNull
                        value={subject}
                        onChange={(value) => onChangeSubject(value)}
                    />
                    <FilterTrainers
                        withNull
                        value={trainer}
                        onChange={(value) => onChangeTrainer(value)}
                        filterSubject={subject}
                    />
                </Filters>
            </div>

            <div className={classes.content_overflow}>
                <Space direction={'vertical'} size={20} className={'block'}>
                    <div className={classes.plan_list_row}>
                        <div className={classes.header_date}>
                            <span className={'caption color-gray-3'}>Дата</span>
                        </div>

                        <div className={classes.header_name}>
                            <span className={'caption color-gray-3'}>
                                Тема урока
                            </span>
                        </div>

                        <div className={classes.header_task}>
                            <span className={'caption color-gray-3'}>
                                Задание к этому уроку
                            </span>
                        </div>
                    </div>
                </Space>

                {query.loading && firstLoading.current && <Skeleton active />}
                {!query.loading && data && data.length === 0 && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {data.length > 0 &&
                    _.map(data, (training) => {
                        return (
                            <PlanRow
                                training={training.training}
                                tasks={training.tasks}
                                refetch={() => query.refetch()}
                                key={training.training.id}
                            />
                        );
                    })
                }
            </div>
        </CrmLayout>
    );
};
