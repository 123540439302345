import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { message } from 'antd';
import moment from 'moment';

import CrmModal from '../../components/CrmModal';

import TrainingForm from './TrainingForm';

const CREATE = gql`
    mutation create($data: TrainingInput!, $massEdit: Boolean! ) {
        createTrainings(data: $data, massEdit: $massEdit) {
            data {
                id
            }
        }
    }
`;

export default function ModalTrainingNew({
    isOpen,
    onClose,
    onFinish,
    filters,
}) {
    const [create] = useMutation(CREATE);

    let formData = {
        startDate: filters.startDate,
        trainers: [],
        group: filters.group === '0' ? null : filters.group,
        subject: filters.subject === '0' ? null : filters.subject,
    };

    if (filters.trainers !== '0') {
        filters.trainers = [filters.trainers];
    }

    const onSubmit = async (data) => {
        const newData = {
            name: data.name,
            room: data.room,
            subject: data.subject || filters.subject,
            group: data.group || filters.group,
            comment: data.comment,
            trainers: data.trainers || [filters.trainers],
            startDate: moment(data.startDate).format(),
        };

        await create({
            variables: { data: newData, massEdit: data.massEdit },
            onCompleted: () => {
                onFinish();
            },
            onError: (error) => {
                console.error(error);
                message.error('Ошибка добавления урока')
            },
        });

    };

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Добавить урок'}>
            <div className={'modal-basic-form'}>
                {isOpen && (
                    <TrainingForm
                        data={formData}
                        onSubmit={onSubmit}
                        size={'normal'}
                        withGroup={!formData.group}
                        withSubject={!formData.subject}
                        withTrainer={formData.trainers.length === 0}
                        actionName={'Добавить'}
                        actionIcon={<PlusOutlined />}
                    />
                )}
            </div>
        </CrmModal>
    );
}
