import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
// import { PlusOutlined } from '@ant-design/icons';
import {Tooltip} from 'antd';
// import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import CrmAvatar from '../../components/CrmAvatar';
import CrmMark from '../../components/Mark/CrmMark';
import CrmPass from '../../components/Pass/CrmPass';
import {child_info, training_info} from '../../routes';
import AverageMark from '../Journal/AverageMark';
import AverageWeightMark from '../Journal/AverageWeightMark';

import './journal.scss';

export default function JournalGrid({
    _loading,
    trainings,
    users,
    _passes,
    marks,
}) {
    const sortedTrainings = useRef(
        _.values(
            _.sortBy(
                _.groupBy(trainings, (el) => {
                    return moment(el.attributes.startDate).format('MMMM');
                }),
                (arr) => {
                    moment(arr[0].attributes.startDate).format('M');
                },
            ),
        ),
    );

    // MARKS

    const userMarks = _.map(users, (user) => {
        return {
            user: user.id,
            trainings: _.map(trainings, (training) => {
                const trainingId = training.id;
                const clientId = user.attributes.client.data.id;

                const markData = _.filter(marks, {
                    attributes: {
                        training: {data: {id: trainingId}},
                        client: {data: {id: clientId}},
                    },
                });

                return {
                    training: training.id,
                    marks: markData,
                };
            }),
        };
    });

    // идем по каждой тренировке
    const displayTraining = (trainings) =>
        _.map(trainings, (training) => {
            const displayDate = moment(training.attributes.startDate).format(
                'DD (ddd)',
            );

            return (
                <div key={training.id} className={'training'}>
                    <div className={'block_wrap'}>
                        <Tooltip title={training.attributes.name}>
                            <Link to={training_info(training.id)}>
                                {displayDate}
                            </Link>
                        </Tooltip>
                    </div>

                    <div className={'cols'}>
                        {/* пропуски */}
                        <div className={'col col_pass'}>
                            <div className={'col_header'}>
                                <Tooltip title={'Пропуски'}>ПР</Tooltip>
                            </div>

                            {_.map(users, (user) => {
                                return (
                                    <div key={user.id} className={'item'}>
                                        <CrmPass
                                            trainingId={training.id}
                                            clientId={
                                                user.attributes.client.data.id
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        {/* оценки */}
                        <div className={'col col-marks'}>
                            <div className={'col_header'}>
                                <Tooltip title={'Оценки'}>Оценки</Tooltip>
                            </div>

                            {_.map(users, (user) => {
                                const trainingId = training.id;
                                const clientId = user.attributes.client.data.id;

                                const markData = _.filter(marks, {
                                    attributes: {
                                        training: {data: {id: trainingId}},
                                        client: {data: {id: clientId}},
                                    },
                                });

                                return (
                                    <div
                                        key={user.id}
                                        className={'item item-marks'}
                                    >
                                        <CrmMark
                                            trainingId={trainingId}
                                            clientId={clientId}
                                            markData={markData}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        });

    return (
        <div className="journal-wrapper">
            {/*Уроки*/}
            <div className={'months'}>
                {_.map(sortedTrainings.current, (el) => {
                    return (
                        <div key={Math.random()} className={'journal_col'}>
                            <div className={'block_wrap'}>
                                <div className={'month'}>
                                    {moment(el[0].attributes.startDate).format(
                                        'MMMM',
                                    )}
                                </div>
                            </div>
                            <div className={'journal__pass_and_mark'}>
                                {displayTraining(el)}
                            </div>
                        </div>
                    );
                })}

                {/*Средние баллы*/}
                <div className={'average-marks'}>
                    <div className={'average-mark'}>
                        <div className={'average_wrap'}>СР</div>

                        {_.map(userMarks, (userMark, index) => {
                            return (
                                <div key={index} className={'item'}>
                                    <AverageMark
                                        trainings={userMark.trainings}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className={'average-mark mark_result'}>
                        <div className={'average_wrap'}>СРВЗ/ИТОГ</div>

                        {_.map(userMarks, (userMark, index) => {
                            return (
                                <div key={index} className={'item item_result'}>
                                    <AverageWeightMark
                                        trainings={userMark.trainings}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/*Пользователи*/}
            <div className={'users'}>
                {_.map(users, (user) => {
                    const client = user.attributes.client.data;
                    return (
                        <div key={user.id} className={'user'}>
                            <Link to={child_info(client.id)}>
                                <CrmAvatar
                                    firstName={client.attributes.firstName}
                                    lastName={client.attributes.lastName}
                                    color={client.attributes.color}
                                />
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
