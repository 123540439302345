import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

const QUERY = gql`
    query trainers($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(
            filters: $filters
            pagination: { pageSize: 200 }
            sort: ["trainer.lastName:ASC"]
        ) {
            data {
                id
                attributes {
                    trainer {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                middleName
                                color
                                subjects {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const { Option } = Select;

export default function FilterTrainers(field) {
    const { filterSubject } = field;
    const [data, setData] = useState([]);

    // настраиваем фильтр
    let filters = {
        role: { id: { eq: 3 } },
        trainer: {
            enabled: { eq: true },
        },
    };

    // добавляем в фильтр предмет, если он указан
    if (filterSubject !== '0') {
        filters.trainer.subjects = {id: {eq:  filterSubject}};
    };

    // Query
    const query = useQuery(QUERY, {
        variables: {
            filters: filters,
        },
        onCompleted: (data) => {
            setData(data.usersPermissionsUsers.data);
        },
    });

    let fieldData = {
        ...field,
    };
    delete fieldData.withAll;
    delete fieldData.withNull;
    delete fieldData.filterSubject;

    return (
        <span className={cn([field.className, 'filter'])}>
            <Select
                {...fieldData}
                size={'small'}
                bordered={false}
                placeholder="Выберите преподавателя"
                filterOption={false}
                notFoundContent={query.loading ? <Spin size="small" /> : null}
                loading={query.loading}
                className={'block'}
                dropdownMatchSelectWidth={false}
            >
                {field.withAll && (
                    <Option key={'0'} value={'0'}>
                        Все преподаватели
                    </Option>
                )}

                {field.withNull && (
                    <Option key={'0'} value={'0'}>
                        Преподаватель не выбран
                    </Option>
                )}

                {+filterSubject
                    ? _.map(data, (item) => {
                        const trainer = item.attributes.trainer.data;

                        return (
                            <Option key={item.id} value={item.id}>
                                {trainer.attributes.lastName}{' '}
                                {trainer.attributes.firstName}{' '}
                                {trainer.attributes.middleName}
                            </Option>
                        );
                    })
                    : _.map(data, (item) => {
                        const trainer = item.attributes.trainer.data;

                        return (
                            <Option key={item.id} value={item.id}>
                                {trainer.attributes.lastName}{' '}
                                {trainer.attributes.firstName}{' '}
                                {trainer.attributes.middleName}
                            </Option>
                        );
                    })}
            </Select>
        </span>
    );
}
