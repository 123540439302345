import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'antd';

const MIGRATION1 = gql`
    mutation mutation {
        updateChildrenPasswords {
            done
        }
    }
`;

// const MIGRATION2 = gql`
//     mutation mutation {
//         migrateVisit {
//             done
//         }
//     }
// `;
//
// const MIGRATION3 = gql`
//     mutation mutation {
//         createChildAccounts {
//             done
//         }
//     }
// `;
//
// const MIGRATION4 = gql`
//     mutation mutation {
//         createTrainersFromUsers {
//             done
//         }
//     }
// `;
//
// const MIGRATION5 = gql`
//     mutation mutation {
//         migrateTasks {
//             done
//         }
//     }
// `;

export default function Test() {
    const [inLoadingMutation, setInLoadingMutation] = useState(false);
    const [inMutationDone, setInMutationDone] = useState(false);

    const [migrationOne] = useMutation(MIGRATION1);
    // const [migrationTwo] = useMutation(MIGRATION2);
    // const [migrationThree] = useMutation(MIGRATION3);
    // const [migrationFour] = useMutation(MIGRATION4);
    // const [migrationFive] = useMutation(MIGRATION5);

    async function migration1() {
        // setInMutation(true);
        setInLoadingMutation(true);
        await migrationOne({
            onCompleted: () => {
                setInLoadingMutation(false);
                setInMutationDone(true);
            },
            onError: (error) => {
                console.error(error);
                setInLoadingMutation(false);
            }
        });
    }

    // async function migration2() {
    //     setInLoadingMutation(true);
    //     await migrationTwo({
    //         onCompleted: () => {
    //             setInLoadingMutation(false);
    //             setInMutationDone(true);
    //         },
    //         onError: (error) => {
    //             console.error(error);
    //             setInLoadingMutation(false);
    //         }
    //     });
    // }
    //
    // async function migration3() {
    //     // setInMutation(true);
    //     setInLoadingMutation(true);
    //     const res = await migrationThree();
    //     const done = res.data.createChildAccounts.done;
    //     if (done) {
    //         setInLoadingMutation(false);
    //         setInMutationDone(true);
    //     }
    // }
    //
    // async function migration4() {
    //     // setInMutation(true);
    //     setInLoadingMutation(true);
    //     const res = await migrationFour();
    //     const done = res.data.createTrainersFromUsers.done;
    //     if (done) {
    //         setInLoadingMutation(false);
    //         setInMutationDone(true);
    //     }
    // }
    //
    // async function migration5() {
    //     // setInMutation(true);
    //     setInLoadingMutation(true);
    //     const res = await migrationFive();
    //     const done = res.data.migrateTasks.done;
    //     if (done) {
    //         setInLoadingMutation(false);
    //         setInMutationDone(true);
    //     }
    // }

    return (
        <div className={'crm-not-found-layout'}>
            <h1>Migrations</h1>
            <Button loading={inLoadingMutation} onClick={() => migration1()}>
                Migration 1
            </Button>
            {/*<Button loading={inLoadingMutation} onClick={() => migration3()}>Migration 3</Button>*/}
            {/*<Button loading={inLoadingMutation} onClick={() => migration4()}>Migration 4</Button>*/}
            {/*<Button loading={inLoadingMutation} onClick={() => migration5()}>Migration 5</Button>*/}
            {inLoadingMutation && 'Loading...'}
            {inMutationDone && <span>Успешно завершено</span>}
        </div>
    );
}
