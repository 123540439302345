import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import CrmHeader from '../../components/CrmHeader';
import FilterBack from '../../components/Filters/FilterBack';
import FilterButton from '../../components/Filters/FilterButton';
import FilterGroups from '../../components/Filters/FilterGroups';
import FilterNext from '../../components/Filters/FilterNext';
import Filters from '../../components/Filters/Filters';
import FilterSubjects from '../../components/Filters/FilterSubjects';
import FilterTrainers from '../../components/Filters/FilterTrainers';
import RoleFunction from '../../config/roles';
import { calendar } from '../../routes';

import CopyTraining from './CopyTraining/CopyTraining';
import CalendarGrid from './CalendarGrid';
import ModalTrainingNew from './ModalTrainingNew';

import './calendar.scss';

const GET_TRAINIG = gql`
    query trainings(
        $trainingsFilter: TrainingFiltersInput
        $trainingsPagination: PaginationArg
    ) {
        trainings(filters: $trainingsFilter, pagination: $trainingsPagination) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    name
                    comment
                    trainers {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    room {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    subject {
                        data {
                            id
                            attributes {
                                name
                                color
                            }
                        }
                    }
                    group {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

function title(str) {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase();
    });
}

function getTrainingFilter(date, trainer, group, subject) {
    const startDate = moment(date);
    const endDate = moment(date).add(7, 'days');

    let filters = {
        enabled: { eq: true },
        startDate: { between: [startDate.format(), endDate.format()] },
    };

    if (trainer !== '0') {
        filters.trainers = { id: { in: [trainer] } };
    }

    if (group !== '0') {
        filters.group = { id: { eq: group } };
    }

    if (subject !== '0') {
        filters.subject = { id: { eq: subject } };
    }

    return filters;
}

function Calendar() {
    const { date, trainer, group, subject } = useParams();

    const navigate = useNavigate();

    // изминение даты
    // листание вперед
    function goToday() {
        navigate(
            calendar(
                moment().startOf('isoWeek').format('YYYY-MM-DD'),
                trainer,
                group,
                subject,
            ),
        );
    }
    // листание вперед
    function addWeek() {
        navigate(
            calendar(
                moment(date).add(7, 'days').format('YYYY-MM-DD'),
                trainer,
                group,
                subject,
            ),
        );
    }
    // листание назад
    function substractWeek() {
        navigate(
            calendar(
                moment(date).subtract(7, 'days').format('YYYY-MM-DD'),
                trainer,
                group,
                subject,
            ),
        );
    }

    // изминение класса
    function onChangeGroup(value) {
        navigate(calendar(date, trainer, value, subject));
    }

    // изминение класса
    function onChangeSubject(value) {
        navigate(calendar(date, trainer, group, value));
    }

    // изминение учителя
    function onChangeTrainer(value) {
        navigate(calendar(date, value, group, subject));
    }

    // создание урока
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [createDate, setCreateDate] = useState(moment());
    function onCreateTraining(startDate) {
        setCreateDate(startDate);
        setShowCreateModal(true);
    }

    // копирование тренировки
    const [modalOpened, setModalOpened] = useState(false);

    // Главный запрос
    // todo добавить фильтр в зависимости от роли
    // для Parent показывать только те классы, в которых есть дети у Parent
    // для Teacher показывать только те  уроки, которые ведет Учитель
    // для Admin добавить фильтр по учителю / по классу
    const { data, loading, refetch } = useQuery(GET_TRAINIG, {
        variables: {
            trainingsFilter: getTrainingFilter(date, trainer, group, subject),
            trainingsPagination: { limit: 10000 },

            usersFilter: {
                role: {
                    or: [{ name: { eq: 'Trainer' } }, { name: { eq: 'Boss' } }],
                },
            },
            usersPagination: { limit: 10000 },
            usersSort: 'lastName:ASC',
        },
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения данных уроков');
        },
    });

    // готовим переменные
    let trainings = [];
    if (data !== undefined && data.trainings !== null) {
        trainings = data.trainings.data;
    }

    // можно создавать новые тренировки или нельзя
    // console.log('auth', auth.role);

    const filtersCreate = {
        startDate: createDate,
        trainers: trainer,
        group: group,
        subject: subject,
    };

    // рендер
    return (
        <>
            <CrmHeader />

            <div className={'calendar-wrapper'}>
                {/*<div className={'crm-calendar-header crm-layout'}>*/}

                {/*<div className={'crm-calendar-header-left'}>*/}
                {/*<Link to={INDEX}>*/}
                {/*<h3>*/}
                {/*<b>{title(moment(date).add(4, 'days').format('MMMM'))}</b> {moment(date).add(4, 'days').format('YYYY')}*/}
                {/*</h3>*/}
                {/*</Link>*/}
                {/*</div>*/}

                {/*<div className={'crm-calendar-header-right'}>*/}
                {/*<Button shape={'circle'} icon={<LeftOutlined/>} onClick={substractWeek}/>*/}
                {/*<Button shape={'circle'} icon={<RightOutlined/>} onClick={addWeek}/>*/}
                {/*</div>*/}

                {/*</div>*/}

                <div className={'crm-layout calendar-filter'}>
                    <Filters>
                        <FilterBack onClick={substractWeek} />
                        <FilterButton onClick={goToday}>
                            <span className={'bold'}>
                                {title(
                                    moment(date).add(4, 'days').format('MMMM'),
                                )}
                            </span>
                            , {moment(date).add(4, 'days').format('YYYY')}
                        </FilterButton>
                        <FilterNext onClick={addWeek} />

                        {RoleFunction.copyTraining() && (
                            <>
                                <FilterButton
                                    shape={'circle'}
                                    onClick={() => setModalOpened(true)}
                                >
                                    <CopyOutlined />
                                </FilterButton>
                                {modalOpened &&
                                <CopyTraining isOpen={modalOpened} onClose={() => setModalOpened(false)} date={date}/>
                                }
                            </>
                        )}

                        <FilterGroups
                            withAll
                            value={group}
                            onChange={(value) => onChangeGroup(value)}
                        />

                        <FilterSubjects
                            withAll
                            value={subject}
                            onChange={(value) => onChangeSubject(value)}
                        />
                        <FilterTrainers
                            withAll
                            value={trainer}
                            onChange={(value) => onChangeTrainer(value)}
                            filterSubject={subject}
                        />

                    </Filters>
                </div>

                <CalendarGrid
                    loading={loading}
                    trainings={trainings}
                    startWeek={date}
                    allowCreate={RoleFunction.createTraining()}
                    onCreate={(startDate) => onCreateTraining(startDate)}
                />

                <ModalTrainingNew
                    isOpen={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                    filters={filtersCreate}
                    onFinish={() => {
                        setShowCreateModal(false);
                        refetch();
                    }}
                />
            </div>
        </>
    );
}

export default observer(Calendar);
