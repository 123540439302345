import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { SUBJECT } from '../../routes';

import SubjectForm from './SubjectForm';

const CREATE = gql`
    mutation create($data: SubjectInput!) {
        createSubject(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function SubjectNew() {
    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        await create({ variables: { data: data } });
        navigate(SUBJECT);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новый предмет'} />

            <SubjectForm
                onSubmit={onSubmit}
                loading={loading}
                actionName={'Добавить'}
                actionIcon={<PlusOutlined />}
            />
        </CrmLayout>
    );
}
