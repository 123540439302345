import React from 'react';
import { Space } from 'antd';
import _ from 'lodash';

import { HomeworkCard } from './HomeworkCard';

export const HomeworkOnDay = (props) => {
    const { data: homeworks } = props;

    return (
        <Space size={10} direction={'vertical'} className={'homeworks_list'}>
            {_.map(homeworks, (card) => {
                const { name, comment } = card.attributes;
                const subject =
                    card.attributes.trainingTo.data.attributes.subject.data
                        .attributes.name;

                return (
                    <HomeworkCard
                        subject={subject}
                        title={name}
                        comment={comment}
                        key={card.id}
                        id={card.id}
                    />
                );
            })}
        </Space>
    );
};

