import React, {useState} from 'react';
import {CheckCircleOutlined} from '@ant-design/icons';
import {gql, useMutation} from '@apollo/client';
import {Checkbox, message} from 'antd';
import moment from 'moment';

import CrmModal from '../../../components/CrmModal';

import './copy-training.scss';

const COPY_TRAINIG = gql`
    mutation copy($backward: Boolean, $endDate: String!, $startDate: String!) {
        copyTrainings(
            backward: $backward
            endDate: $endDate
            startDate: $startDate
        ) {
            done
        }
    }
`;


export default function CopyTraining({isOpen, onClose, date}) {

    const [inCopyDone, setInCopyDone] = useState(false);
    const [copyTrainingBackward, setCopyTrainingBackward] = useState(false);
    const [copyTrainingMutation, copyTrainingMutationData] = useMutation(
        COPY_TRAINIG,
        {
            variables: {
                startDate: moment(date).format('YYYY-MM-DD'),
                endDate: moment(date).add(7, 'days').format('YYYY-MM-DD'),
                backward: copyTrainingBackward,
            },
            onCompleted: (data) => {
                setInCopyDone(data.copyTrainings.done);
            },
            onError: (error) => {
                console.error(error);
                message.error('Ошибка копирования уроков');
            },
        },
    );
    function closeModal() {
        setInCopyDone(false);
        setCopyTrainingBackward(false);
        onClose();
    }
    async function copyTraining() {
        await copyTrainingMutation();
    }

    return (
        <CrmModal
            isOpen={isOpen}
            title={'Скопировать расписание'}
            onClose={() => closeModal()}
            onAction={() => copyTraining()}
            loading={copyTrainingMutationData.loading}
            disabled={copyTrainingMutationData.loading}
            actionName={'Скопировать'}
        >
            <div className={'modal-basic-form'}>
                <p className={'bold'}>
                    Будет скопировано расписание на
                    неделю
                </p>

                <p>
                    Мы сохраним информацию по времени,
                    предмету, кабинету и списку
                    учеников. Темы уроков, комментарии и
                    отсутствующие не будут заполнены.
                </p>
                <p>
                    <Checkbox
                        checked={copyTrainingBackward}
                        onChange={() => {
                            setCopyTrainingBackward(
                                !copyTrainingBackward,
                            );
                        }}
                    >
                        На прошлую неделю
                    </Checkbox>
                </p>
                {inCopyDone && (
                    <p className={'bold color-success'}>
                        <CheckCircleOutlined />{' '}
                        Копирование завершено успешно!
                    </p>
                )}
            </div>
        </CrmModal>
    );
}
