import React from 'react';
import { Avatar } from 'antd';
import cn from 'classnames';

import './crm-avatar.scss';

export default function CrmAvatar({
    firstName,
    lastName,
    middleName,
    color,
    avatarUrl,
    caption,
    size,
    className
}) {

    return (
        <div className={cn(['crm-avatar', className])}>
            <Avatar
                size={size}
                style={{
                    backgroundColor: color || '#F0F0F0',
                    color: '#333333',
                }}
                src={avatarUrl}
            >
                {lastName.charAt(0)}
                {firstName.charAt(0)}
            </Avatar>
            <div className={'text-wrapper childInfo_card'}>
                <div className={'text'}>
                    {middleName
                        ? `${lastName} ${firstName} ${middleName}`
                        : `${lastName} ${firstName}`}{' '}
                </div>
                {caption && <div className={'caption color-gray-3'}>{caption}</div>}
            </div>
        </div>
    );
}
