import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

const QUERY = gql`
    query users($filters: QuarterFiltersInput) {
        quarters(
            filters: $filters
            pagination: { pageSize: 200 }
            sort: ["year.id:ASC", "startDate:ASC"]
        ) {
            data {
                id
                attributes {
                    name
                    startDate
                    endDate
                    year {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const { Option } = Select;

export default function FilterQuarters(field) {
    const [data, setData] = useState([]);

    // Query
    const query = useQuery(QUERY, {
        onCompleted: (data) => {
            setData(data.quarters.data);
        },
    });

    let fieldData = {
        ...field,
    };
    delete fieldData.withAll;
    delete fieldData.withNull;

    return (
        <span className={cn([field.className, 'filter'])}>
            <Select
                {...fieldData}
                size={'small'}
                bordered={false}
                placeholder="Выберите четверть"
                filterOption={false}
                notFoundContent={query.loading ? <Spin size="small" /> : null}
                loading={query.loading}
                className={'block'}
                dropdownMatchSelectWidth={false}
            >
                {field.withAll && (
                    <Option key={'0'} value={'0'}>
                        Все четверти
                    </Option>
                )}

                {field.withNull && (
                    <Option key={'0'} value={'0'}>
                        Четверть не выбрана
                    </Option>
                )}

                {_.map(data, (item) => {

                    const year = item.attributes.year.data;

                    return (
                        <Option key={item.id} value={item.id}>
                            {item.attributes.name} {year && `(${year.attributes.name})`}
                        </Option>
                    );
                })}
            </Select>
        </span>
    );
}
