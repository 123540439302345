import React from 'react';

import CrmHeader from './CrmHeader';

const CrmDocument = (props) => {
    return (
        <div>
            <CrmHeader />
            <div className={'crm-policyPage'}>{props.children}</div>
        </div>
    );
};

export default CrmDocument;
