import React from 'react';
import _ from 'lodash';

import Mark from '../../components/Mark/Mark';

export default function AverageMark(props) {
    const { marks } = props;

    const sum =
        marks === 0
            ? 0
            : _.reduce(
                marks,
                (result, mark) => {
                    return result + +mark.attributes.mark;
                },
                0,
            );

    const averageMark = sum > 0 ? Math.round(sum / marks.length) : 0;

    return (
        <div>
            <Mark
                mark={averageMark}
                activity={{ attributes: { name: 'Средний балл' } }}
                comment={''}
            />
        </div>
    );
}
