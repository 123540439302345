import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {message, Select, Spin} from 'antd';
import _ from 'lodash';
import moment from 'moment';

const QUERY = gql`
    query users(
        $id: ID!
    ) {
        getSameTrainings(id: $id) {
            trainings {
                id
                attributes {
                    name
                    startDate
                }
            }
        }
    }
`;

const { Option } = Select;

export default function SelectSameTrainings(field) {

    console.log('field', field);

    // Query
    const query = useQuery(QUERY, {
        variables: {
            id: field.value
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения списка уроков');
        },
    });

    // Render
    return (
        <Select
            filterOption={false}
            notFoundContent={query.loading ? <Spin size="small" /> : null}
            placeholder="Выберите урок"
            loading={query.loading}
            {...field}
        >
            {query.data &&
                _.map(query.data.getSameTrainings.trainings, (training) => {
                    return (
                        <Option
                            key={training.id + Math.random()}
                            value={training.id}
                        >
                            {moment(training.attributes.startDate).format(
                                'DD MMM YYYY',
                            )}
                            {training.attributes.name && `, ${training.attributes.name}`}
                        </Option>
                    );
                })}
        </Select>
    );
}
