import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import {ACTIVITIES} from '../../routes';

import ActivityForm from './ActivityForm';

const CREATE_ACTIVITY = gql`
    mutation createActivity($data: ActivityInput!) {
        createActivity(data: $data) {
            data {
                id
            }
        }
    }
`;

const ActivityNew = () => {
    const navigate = useNavigate();

    const [createActivity] = useMutation(CREATE_ACTIVITY);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);
        await createActivity({
            variables: { data: { ...data, coefficient: +data.coefficient } }, // gql принимает coefficient только типа number
        });
        navigate(ACTIVITIES);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новый вид деятельности'} />
            <ActivityForm
                onSubmit={onSubmit}
                loading={loading}
                actionName={'Добавить'}
                actionIcon={<PlusOutlined />}
            />
        </CrmLayout>
    );
};

export default ActivityNew;
