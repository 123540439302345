import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseOutlined } from '@ant-design/icons';
import { gql, useLazyQuery } from '@apollo/client';
import { Avatar, Button, Empty, Input, Skeleton } from 'antd';
import _ from 'lodash';

import ClientSelectRow from '../pages/Main/ClientSelectRow';

import CrmModal from './CrmModal';

const QUERY = gql`
    query clients(
        $filters: ClientFiltersInput!
        $pagination: PaginationArg = {}
        $sort: [String] = []
    ) {
        clients(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function ModalChildrenSelect({
    isOpen,
    onClose,
    onFinish,
    selected,
}) {
    const [selectedData, setSelectedData] = useState([...selected]);

    // main data
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(20);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [fetch, fetchedData] = useLazyQuery(QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: (newData) => {
            setData([...data, ...newData.clients.data]);
            setTotal(newData.clients.meta.pagination.total);
        },
    });
    const loadMoreData = () => {
        setPage(page + 1);
        fetch({
            variables: {
                filters: {
                    or: [
                        { firstName: { contains: searchText } },
                        { lastName: { contains: searchText } },
                    ],
                    enabled: { in: [true, null] },
                },
                sort: ['lastName:ASC', 'firstName:ASC'],
                pagination: {
                    pageSize: 20,
                    page: page + 1,
                },
            },
        });
    };
    useEffect(() => {
        loadMoreData();
    }, [searchText]);

    // Cahnge List
    const onRemoveItem = (data) => {
        let newData = selectedData;
        _.remove(newData, { id: data.id });
        setSelectedData([...newData]);
    };
    const onAddItem = (data) => {
        setSelectedData([...selectedData, data]);
    };

    // Items
    const items = data.map(function (value) {
        const selected = _.findIndex(selectedData, { id: value.id }) >= 0;
        const onClick = selected
            ? () => onRemoveItem(value)
            : () => onAddItem(value);

        return (
            <ClientSelectRow
                key={value.id}
                user={value}
                type={'child'}
                selected={selected}
                onClick={onClick}
            />
        );
    });

    const onSave = function () {
        onFinish(selectedData);
    };

    return (
        <CrmModal
            isOpen={isOpen}
            onClose={onClose}
            title={'Выбрать из списка'}
            actionName={'Сохранить'}
            onAction={onSave}
        >
            <div className={'modal-selected-users-container'}>
                <div className={'modal-selected-users'}>
                    {_.map(selectedData, (value) => {
                        const person = value;

                        return (
                            <React.Fragment key={value.id}>
                                <div className={'modal-selected-user'}>
                                    <div
                                        className={'modal-selected-user-avatar'}
                                    >
                                        <Avatar
                                            src={person.attributes.avatarUrl}
                                            style={{
                                                backgroundColor: person
                                                    .attributes.color
                                                    ? person.attributes.color
                                                    : '#f2f2f2',
                                                color: '#333333',
                                            }}
                                        >
                                            {person.attributes.firstName &&
                                                person.attributes.firstName.charAt(
                                                    0,
                                                )}
                                            {person.attributes.lastName &&
                                                person.attributes.lastName.charAt(
                                                    0,
                                                )}
                                        </Avatar>
                                        <Button
                                            icon={<CloseOutlined />}
                                            shape="circle"
                                            size={'small'}
                                            className={
                                                'modal-selected-user-close'
                                            }
                                            onClick={() => onRemoveItem(value)}
                                        />
                                    </div>
                                    <div className={'modal-selected-user-text'}>
                                        👦 {person.attributes.firstName}
                                    </div>
                                    <div className={'modal-selected-user-text'}>
                                        {person.attributes.lastName}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>

            <div className={'crm-modal-sheet-title'}>
                <Input.Search
                    className={'crm-filters-1-item'}
                    onSearch={(value) => {
                        setSearchText(value);
                        setData([]);
                        setPage(0);
                    }}
                />
            </div>

            <div className={'modal-selected-users-rows'} id="scrollableDiv">
                <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={
                        <div className="modal-selected-user-item">
                            <Skeleton
                                avatar={{ size: 'normal' }}
                                active
                                paragraph={{ rows: 0 }}
                            />
                        </div>
                    }
                    scrollableTarget="scrollableDiv"
                >
                    {items}
                </InfiniteScroll>

                {data.length === 0 && !fetchedData.loading && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </CrmModal>
    );
}
