function getRole() {
    // todo забирать из Store а не напрямую из локалстораджа
    const { auth } = JSON.parse(window.localStorage.getItem('proschool-store'));
    return auth.role;
}

const RoleFunctions = {
    onTest: () => {
        console.log('role', getRole());
        return true;
    },

    //TRAINING

    createTraining: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateTraining: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateTrainingName: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    deleteTraining: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    copyTraining: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return false;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    //TRAINER

    createTrainer: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateTrainer: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    deleteTrainer: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // CLIENT

    createClient: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateClient: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    deleteClient: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateClientAccount: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    readClientNutrition: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return true;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    nutritionRefillBalance: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return true;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // PARENT

    createParent: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateParent: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    deleteParent: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateParentAccount: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // JOURNAL
    readJournal: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // MARK

    createMark: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },
    updateMark: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },
    deleteMark: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateVisit: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // PASS

    createPass: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },
    deletePass: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // GROUP

    createGroup: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateGroup: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    deleteGroup: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateActivity: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateYears: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updateSubject: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // TASK

    createTask: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },
    updateTask: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    updatePlan: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    // Progress
    progressShowFilter: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Teacher':
                return true;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },
};

export default RoleFunctions;
