import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export const HomeworkHeader = (props) => {
    const { daysOfWeek } = props;

    const row = [];

    for (let i = 0; i < 5; i++) {
        row.push(moment(daysOfWeek).startOf('week').add(i, 'day').date());
    }

    return (
        <div className={'homework__header'}>
            {_.map(row, (el) => (
                <div key={Math.random()} className={'homework__header_day'}>{el}</div>
            ))}
        </div>
    );
};
