import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Empty, Modal, Skeleton, Space } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { ACTIVITIES_NEW } from '../../routes';

import ActivityRow from './ActivityRow';

const GET_ACTIVITIES = gql`
    query activities($filters: ActivityFiltersInput) {
        activities(filters: $filters, pagination: { limit: 200 }) {
            data {
                id
                attributes {
                    name
                    coefficient
                    abbr
                }
            }
        }
    }
`;

const DELETE_ACTIVITY = gql`
    mutation deleteActivity($id: ID!) {
        deleteActivity(id: $id) {
            data {
                id
            }
        }
    }
`;

const Activities = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const query = useQuery(GET_ACTIVITIES, {
        variables: {
            filters: {},
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.activities.data);
        },
    });

    const [deleteActivity] = useMutation(DELETE_ACTIVITY);

    async function onConfirmedDelete(id) {
        await deleteActivity({
            variables: {
                id: id,
                data: {
                    enabled: false,
                },
            },
        });
        await query.refetch();
    }

    function onDelete(id) {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить сообщение?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete(id);
            },
        });
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Виды деятельности'}
                addAction={
                    RoleFunction.updateActivity() &&
                    (() => {
                        navigate(ACTIVITIES_NEW);
                    })
                }
            ></CrmCenteredTitle>

            <Space direction={'vertical'} size={20} className={'block '}>
                <div className={'visit-list-row'}>
                    <div className={'activity-col-abbr'}>
                        <span className={'caption color-gray-3'}>АБ</span>
                    </div>

                    <div className={'activity-col-name'}>
                        <span className={'caption color-gray-3'}>Название</span>
                    </div>

                    <div className={'activity-col-coefficient'}>
                        <span className={'caption color-gray-3'}>Коэф.</span>
                    </div>
                </div>
            </Space>

            {query.loading && <Skeleton active />}
            {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {data.length &&
                _.map(data, (activity) => {
                    const { abbr, name, coefficient } = activity.attributes;

                    return (
                        <ActivityRow
                            abbr={abbr}
                            name={name}
                            coefficient={coefficient}
                            key={activity.id}
                            activityId={activity.id}
                            deleteActivity={onDelete}
                        />
                    );
                })}
        </CrmLayout>
    );
};

export default Activities;
