import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmMark from '../../components/Mark/CrmMark';
import CrmPass from '../../components/Pass/CrmPass';
import { training_info } from '../../routes';

import AverageMark from './AverageMark';
import AverageWeightMark from './AverageWeightMark';
import {
    groupMarks,
    groupMarksBySubject,
    groupTrainings,
    sortPasses,
    sortTrainings,
} from './ProgressFunctions';

import './progress.scss';

export default function ProgressGrid(props) {
    const { trainings, marks, childId, passes, refetch } = props;

    // console.time()
    const sortedTrainings = sortTrainings(trainings);
    const groupedTrainings = groupTrainings(trainings);
    const groupedMarks = groupMarks(marks);
    const marksBySubject = groupMarksBySubject(marks);
    const sortedPasses = sortPasses(passes);
    // console.timeEnd()

    // console.log(sortedTrainings);
    // console.log(groupedTrainings);
    // console.log(groupedMarks);
    // console.log(marksBySubject);
    // console.log(sortedPasses);
    // console.log(subjects);

    const navigate = useNavigate();

    // адаптируем высоту каждого предмета, в зависимости от количества подгрупп
    let adaptivSubjects = {};

    _.forEach(sortedTrainings, (training) => {
        const subjectId = +training[0].attributes.subject.data.id;
        if (adaptivSubjects[subjectId]) {
            if (training.length > adaptivSubjects[subjectId].subgroupLength) {
                adaptivSubjects[subjectId].subgroupLength = training.length;
            }
        } else {
            // console.log(training);
            adaptivSubjects[subjectId] = {
                subgroupLength: training.length,
                name: training[0].attributes.subject.data.attributes.name,
                id: training[0].attributes.subject.data.id,
            };
        }
    });
    adaptivSubjects = _(adaptivSubjects).values().value();

    const onContentClick = (event) => {
        event.stopPropagation();
    };

    // переменные для итерации в дополнительных циклах
    let trainingI = 0;
    let markI = 0;
    let passI = 0;
    let passTrainingI = 0;

    const displayTraining = (month) =>
        // идем по каждому дню
        _.map(month, (day) => {
            const displayDate = moment(day[0].attributes.startDate).format('DD MMM (ddd)');
            const thisDayNumber = +moment(day[0].attributes.startDate).format('DDDD');

            return (
                <div key={Math.random()} className={'training'}>
                    <div className={'block_wrap'}>
                        <span>{displayDate}</span>
                    </div>

                    <div className={'cols'}>
                        {/* пропуски */}
                        <div className={'col col_pass'}>
                            <div className={'col_header'}>
                                <Tooltip title={'Пропуски'}>ПР</Tooltip>
                            </div>

                            {_.map(adaptivSubjects, (subject) => {
                                const trainingSubjectId =
                                    passTrainingI < sortedTrainings.length - 1 &&
                                    sortedTrainings[passTrainingI][0].attributes.subject.data.id;

                                const dayNumber =passTrainingI < sortedTrainings.length - 1 && +moment(
                                    sortedTrainings[passTrainingI][0].attributes.startDate,
                                ).format('DDDD');

                                const subjectId = subject.id;

                                const passTrainingId =
                                    passI < sortedPasses.length &&
                                    sortedPasses[passI].attributes.training.data.id;
                                const passData = sortedPasses[passI];

                                const currentI = passTrainingI;

                                return (
                                    <div key={subject.id + Math.random()} className={'item_wrap'}>
                                        {passTrainingI < sortedTrainings.length - 1 &&
                                        trainingSubjectId === subjectId &&
                                        // проверка на последний урок на дню
                                        thisDayNumber === dayNumber &&
                                        ++passTrainingI
                                            ? _(sortedTrainings[currentI])
                                                .map((_training, i) => {
                                                    const trainingId =
                                                          passTrainingI < sortedTrainings.length &&
                                                          sortedTrainings[currentI][i].id;

                                                    return (
                                                        trainingId && (
                                                            <div
                                                                key={subject.id + Math.random()}
                                                                className={'item'}
                                                            >
                                                                {
                                                                    <CrmPass
                                                                        trainingId={trainingId}
                                                                        clientId={childId}
                                                                        {...(trainingId ===
                                                                              passTrainingId &&
                                                                          ++passI
                                                                              ? {
                                                                                  passData:
                                                                                        passData,
                                                                              }
                                                                              : {
                                                                                  passData: false,
                                                                              })}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    );
                                                })
                                                .concat(
                                                    _(
                                                        Array(
                                                            subject.subgroupLength -
                                                                  sortedTrainings[currentI].length,
                                                        ),
                                                    )
                                                        .map(() => (
                                                            <div
                                                                key={subject.id + Math.random()}
                                                                className={'item'}
                                                            />
                                                        ))
                                                        .value(),
                                                )
                                                .value()
                                            : _(Array(subject.subgroupLength))
                                                .map(() => (
                                                    <div
                                                        key={subject.id + Math.random()}
                                                        className={'item'}
                                                    />
                                                ))
                                                .value()}
                                    </div>
                                );
                            })}
                        </div>

                        {/* оценки */}
                        <div className={'col col-marks'}>
                            <div className={'col_header'}>
                                <Tooltip title={'Оценки'}>Оценки</Tooltip>
                            </div>

                            {_.map(adaptivSubjects, (subject) => {
                                const subjectId = subject.id;
                                const trainingSubjectId =
                                    sortedTrainings[trainingI][0].attributes.subject.data.id;

                                const dayNumber = sortedTrainings.length - 1 && +moment(
                                    sortedTrainings[trainingI][0].attributes.startDate,
                                ).format('DDDD');

                                const currentTrainingI = trainingI;

                                // каждая тренировка - массив, хотя бы из 1 элемента
                                return trainingI < sortedTrainings.length - 1 &&
                                    trainingSubjectId === subjectId &&
                                    // проверка на последний урок на дню
                                    thisDayNumber === dayNumber &&
                                    ++trainingI ? (
                                    <div className={'item_wrap'} key={Math.random()}>
                                        {_.map(
                                            sortedTrainings[currentTrainingI],
                                            (_training, i) => {
                                                const trainingId =
                                                    sortedTrainings[currentTrainingI][i].id;

                                                const markData = groupedMarks[markI];

                                                const markSubjectId =
                                                    markData &&
                                                    markData[0].attributes.training.data.attributes
                                                        .subject.data.id;
                                                const markTrainingId =
                                                    markData &&
                                                    markData[0].attributes.training.data.id;

                                                const toTraining = () => {
                                                    navigate(training_info(trainingId));
                                                };

                                                return (
                                                    <div
                                                        key={subject.id + Math.random()}
                                                        onClick={toTraining}
                                                        className={'item item-marks item-pointer'}
                                                    >
                                                        {
                                                            <div onClick={onContentClick}>
                                                                <CrmMark
                                                                    trainingId={trainingId}
                                                                    clientId={childId}
                                                                    refetch={refetch}
                                                                    {...(markSubjectId ===
                                                                        subjectId &&
                                                                    trainingId === markTrainingId &&
                                                                    ++markI
                                                                        ? {
                                                                            markData: markData,
                                                                        }
                                                                        : {
                                                                            markData: [false],
                                                                        })}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            },
                                        )}
                                        {subject.subgroupLength >
                                            sortedTrainings[currentTrainingI].length &&
                                            _(
                                                Array(
                                                    subject.subgroupLength -
                                                        sortedTrainings[currentTrainingI].length,
                                                ),
                                            )
                                                .map(() => (
                                                    <div
                                                        key={subject.id + Math.random()}
                                                        className={'item item-marks'}
                                                    />
                                                ))
                                                .value()}
                                    </div>
                                ) : (
                                    _(Array(subject.subgroupLength))
                                        .map(() => (
                                            <div
                                                key={subject.id + Math.random()}
                                                className={'item item-marks'}
                                            />
                                        ))
                                        .value()
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        });

    let marksBySubjectI = 0;
    let marksBySubjectResultI = 0;

    return (
        <div className="progress-wrapper">
            {/*Уроки*/}
            <div className={'progresses'}>
                {_.map(groupedTrainings, (month) => {
                    // месяц
                    return (
                        <div key={Math.random()} className={'progress_col'}>
                            <div className={'block_wrap'}>
                                <div className={'month'}>
                                    {moment(month[0][0].attributes.startDate).format('MMMM')}
                                </div>
                            </div>
                            <div className={'progress__pass_and_mark'}>
                                {displayTraining(month)}
                            </div>
                        </div>
                    );
                })}

                {/*Средние баллы*/}
                <div className={'average-marks'}>
                    <div className={'average-mark'}>
                        <div className={'average_wrap'}>СР</div>

                        {_.map(adaptivSubjects, (subject) => {
                            const currentI = marksBySubjectI;
                            return marksBySubject[currentI] &&
                                subject.id ===
                                    marksBySubject[currentI][0].attributes.training.data.attributes
                                        .subject.data.id ? (
                                ++marksBySubjectI && (
                                    <div className={'item_wrap'} key={subject.id + Math.random()}>
                                        <div key={Math.random()} className={'item'}>
                                            <AverageMark marks={marksBySubject[currentI]} />
                                        </div>
                                        {_(
                                            Array(
                                                // оценка всегда одна, ее и отнимаем
                                                subject.subgroupLength - 1,
                                            ),
                                        )
                                            .map(() => (
                                                <div
                                                    key={subject.id + Math.random()}
                                                    className={'item'}
                                                />
                                            ))
                                            .value()}
                                    </div>
                                )
                            ) : (
                                <div className={'item_wrap'} key={subject.id + Math.random()}>
                                    <div key={Math.random()} className={'item'}>
                                        <AverageMark marks={0} />
                                    </div>
                                    {_(
                                        Array(
                                            // оценка всегда одна, ее и отнимаем
                                            subject.subgroupLength - 1,
                                        ),
                                    )
                                        .map(() => (
                                            <div
                                                key={subject.id + Math.random()}
                                                className={'item'}
                                            />
                                        ))
                                        .value()}
                                </div>
                            );
                        })}
                    </div>

                    <div className={'average-mark mark_result'}>
                        <div className={'average_wrap'}>СРВЗ/ИТОГ</div>

                        {_.map(adaptivSubjects, (subject) => {
                            const currentI = marksBySubjectResultI;
                            return marksBySubject[currentI] &&
                                subject.id ===
                                    marksBySubject[currentI][0].attributes.training.data.attributes
                                        .subject.data.id ? (
                                ++marksBySubjectResultI && (
                                    <div className={'item_wrap'} key={subject.id + Math.random()}>
                                        <div key={Math.random()} className={'item'}>
                                            <AverageWeightMark marks={marksBySubject[currentI]} />
                                        </div>
                                        {_(
                                            Array(
                                                // оценка всегда одна, ее и отнимаем
                                                subject.subgroupLength - 1,
                                            ),
                                        )
                                            .map(() => (
                                                <div
                                                    key={subject.id + Math.random()}
                                                    className={'item'}
                                                />
                                            ))
                                            .value()}
                                    </div>
                                )
                            ) : (
                                <div className={'item_wrap'} key={subject.id + Math.random()}>
                                    <div key={Math.random()} className={'item'}>
                                        <AverageWeightMark marks={0} />
                                    </div>
                                    {_(
                                        Array(
                                            // оценка всегда одна, ее и отнимаем
                                            subject.subgroupLength - 1,
                                        ),
                                    )
                                        .map(() => (
                                            <div
                                                key={subject.id + Math.random()}
                                                className={'item'}
                                            />
                                        ))
                                        .value()}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/*Уроки*/}
            <div className={'subjects'}>
                {_.map(adaptivSubjects, (subject) => {
                    return (
                        <div key={subject.id} className={'subject'}>
                            <div className={'subject_name'}>{subject.name}</div>
                            {subject.subgroupLength > 1 &&
                                _(
                                    Array(
                                        // как минимум 1 предмет у нас всегда рендерится
                                        subject.subgroupLength - 1,
                                    ),
                                )
                                    .map(() => (
                                        <div
                                            className={'subject_subgroup'}
                                            key={Math.random()}
                                        ></div>
                                    ))
                                    .value()}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
