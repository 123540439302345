import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

import CrmSpin from '../../components/CrmSpin';
import logo from '../../images/Logo.svg';

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);

    function toggleLoading() {
        setLoading(!loading);
    }

    return (
        <div className="crm-login-layout dissolved">
            <div className="crm-login-logo">
                <img src={logo} className="App-logo" alt="logo" />
            </div>
            <CrmSpin spinning={loading}>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                    Введите email, который вы&nbsp;указали при регистрации.
                    Мы&nbsp;создаим новый пароль и&nbsp;пришлем его
                    на&nbsp;указанную почту.
                    <Space
                        direction="vertical"
                        size={10}
                        style={{ width: '100%' }}
                    >
                        Email
                        <Input placeholder="example@site.com" size="large" />
                    </Space>
                    <Button
                        type="primary"
                        size="large"
                        block={true}
                        onClick={toggleLoading}
                    >
                        Выслать новый пароль
                    </Button>
                    <Link to="/login">
                        <Button
                            type="link"
                            block={true}
                            icon={<LeftOutlined />}
                        >
                            Назад
                        </Button>
                    </Link>
                </Space>
            </CrmSpin>
        </div>
    );
};

export default ForgetPassword;
