import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import { Pagination, Space} from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import FilterSearch from '../../components/Filters/FilterSearch';

import {OrdersRow} from './OrdersRow';

import classes from './orders.module.scss';

const GET_ORDERS = gql`
    query ($filters: OrderFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        orders(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    phone
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                            }
                        }
                    }
                    amount
                    status
                    operationStatus
                    iikoStatus
                    iikoError
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

function getFilters(search) {
    let filters = {};
    if (search !== '') {
        filters.client = {
            or: [
                {firstName: {contains: search}},
                {lastName: {contains: search}},
            ]
        };
    }
    return filters;
}

export const Orders = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const query = useQuery(GET_ORDERS, {
        variables: {
            filters: getFilters(search),
            pagination: {
                pageSize: pageSize,
                page: page,
            },
            sort: ['createdAt:DESC'],
        },
    });

    const onPageSizeChange = (current, size) => {
        setPageSize(size)
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Оплата питания'}/>

            <FilterSearch className={'filter-search-orders'} onSearch={(value) => setSearch(value)} placeholder={'Поиск по имени или фамилии'}/>

            <Space direction={'vertical'} size={20} className={'block'}>
                <div className={classes.header__list_row}>
                    <div className={classes.header_date}>
                        <span className={'caption color-gray-3'}>Дата</span>
                    </div>

                    <div className={classes.header_phone}>
                        <span className={'caption color-gray-3'}>Номер телефона</span>
                    </div>

                    <div className={classes.header_client}>
                        <span className={'caption color-gray-3'}>Клиент</span>
                    </div>

                    <div className={classes.header_sum}>
                        <span className={'caption color-gray-3'}>Сумма</span>
                    </div>

                    <div className={classes.header_paid}>
                        <span className={'caption color-gray-3'}>Оплата</span>
                    </div>

                    <div className={classes.header_iiko}>
                        <span className={'caption color-gray-3'}>IIKO</span>
                    </div>
                </div>
            </Space>

            {query.data && (
                <>
                    {_(query.data.orders.data)
                        .map((order) => {
                            return <OrdersRow key={order.id} order={order}/>;
                        })
                        .value()}
                    {query.data.orders.data.length > 0 && (
                        <Pagination
                            size={'small'}
                            className={classes.pagination}
                            defaultCurrent={query.data.orders.meta.pagination.page}
                            current={query.data.orders.meta.pagination.page}
                            total={query.data.orders.meta.pagination.total}
                            pageSize={query.data.orders.meta.pagination.pageSize}
                            onChange={(page) => {
                                setPage(page);
                            }}
                            onShowSizeChange={onPageSizeChange}
                        />
                    )}
                </>
            )}
        </CrmLayout>
    );
};
