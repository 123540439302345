import React, { useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import { Store } from '../../model/store/Store';

const Logout = () => {
    const store = useInstance(Store);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    useEffect(() => {
        store.auth.clearState();
    }, []);

    return <Spin indicator={antIcon} />;
};

export default observer(Logout);
