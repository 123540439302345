import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Empty, message, Modal, Skeleton } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { subject_edit, SUBJECT_NEW } from '../../routes';

import './subject.scss';

const QUERY = gql`
    query query(
        $filters: SubjectFiltersInput
        $pagination: PaginationArg
        $sort: [String] = []
    ) {
        subjects(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    color
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const DELETE_SUBJECT = gql`
    mutation deleteSubject($id: ID!) {
        deleteSubject(id: $id) {
            data {
                id
            }
        }
    }
`;

export default function Subjects() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const query = useQuery(QUERY, {
        variables: {
            sort: ['name:ASC'],
            pagination: {
                limit: 200,
            },
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.subjects.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [deleteSubject] = useMutation(DELETE_SUBJECT);

    async function onConfirmedDelete(id) {
        await deleteSubject({
            variables: {
                id: id,
            },
        });
        await query.refetch();
    }

    function onDelete(id) {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить предмет',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete(id);
            },
        });
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Предметы'}
                addAction={() => navigate(SUBJECT_NEW)}
            />

            {query.loading && <Skeleton active />}
            {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {_.map(data, (subject) => {
                const color = subject.attributes.color || '#f0f0f0';

                return (
                    <div key={subject.id} className={'subject-line'}>
                        <div>
                            <div
                                className={'subject-color'}
                                style={{ backgroundColor: color }}
                            />{' '}
                            {subject.attributes.name}
                        </div>
                        <div>
                            <Button
                                type={'link'}
                                icon={<EditOutlined />}
                                onClick={() =>
                                    navigate(subject_edit(subject.id))
                                }
                            />
                            <Button
                                type={'link'}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    RoleFunction.updateSubject() &&
                                        onDelete(subject.id);
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </CrmLayout>
    );
}
