import React from 'react';
import { Link } from 'react-router-dom';

import CrmMark from '../../../components/Mark/CrmMark';
import CrmPass from '../../../components/Pass/CrmPass';

const SubjectListRow = ({ training, clientId }) => {

    const subject = training.attributes.subject.data;

    return (
        <>
            <div className={'visit-list-row body'}>
                <Link
                    to={`/class/${training.id}`}
                    className={'line-2col'}
                >
                    {subject.attributes.name}
                </Link>

                <div className={'line-1col'}>
                    <CrmPass clientId={clientId} trainingId={training.id}/>
                </div>

                <div className={'line-1col'}>
                    <CrmMark clientId={clientId} trainingId={training.id}/>
                </div>
            </div>
        </>
    );
};

export default SubjectListRow;
