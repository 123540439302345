import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Empty, message, Skeleton, Space} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmDisplayData from '../../../components/CrmDisplayData';
import CrmModal from '../../../components/CrmModal';
import FilterParentsPhones from '../../../components/Filters/FilterParentsPhones';
import FilterRange from '../../../components/Filters/FilterRange';
import Filters from '../../../components/Filters/Filters';
import RoleFunction from '../../../config/roles';
import { INFO_CATERING, INFO_PERSONAL_DATA } from '../../../routes';

import BalanceForm from './balanceForm';

import './iiko.scss';

const QUERY = gql`
    query query($clientId: ID!, $phone: String!) {
        getIikoCard(clientId: $clientId, phone: $phone) {
            cards {
                number
            }
            phone
            balance
            isDeleted
            isBlocked
        }
    }
`;

const TRANSACTIONS = gql`
    query query($clientId: ID!, $phone: String!, $dateFrom: String!, $dateTo: String!) {
        getIikoTransactions(
            clientId: $clientId
            phone: $phone
            dateFrom: $dateFrom
            dateTo: $dateTo
        ) {
            data {
                type
                transactionDate
                transactionSum
            }
        }
    }
`;

const QUERY_ORDER = gql`
    query query($filters: OrderFiltersInput) {
        orders(filters: $filters) {
            data {
                id
                attributes {
                    number
                    phone
                    amount
                    status
                    paid
                    paymentLink
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;

const CREATE_ORDER = gql`
    mutation create($data: OrderInput!) {
        createOrder(data: $data) {
            data {
                id
                attributes {
                    paymentLink
                }
            }
        }
    }
`;

// const CREATE = gql`
//     mutation create ($data: IikoCardInput!) {
//         createIikoCard(data: $data) {
//             data {
//                 id
//             }
//         }
//     }
// `;
//
// const UPDATE = gql`
//     mutation update ($id: ID!, $data: IikoCardInput!) {
//         updateIikoCard(id: $id, data: $data,) {
//             data {
//                 id
//             }
//         }
//     }
// `;

// const REFILL = gql`
//     mutation refill ($amount: Float!, $comment: String, $id: ID!) {
//         iikoRefillBalance(id: $id, amount: $amount, comment: $comment) {
//             done
//         }
//     }
// `;

export default function IikoInfo({ clientId }) {
    const [phone, setPhone] = useState(null);
    const [orders, setOrders] = useState(null);

    const [transactionsRange, setTransactionsRange] = useState([
        moment().subtract(1, 'month'),
        moment(),
    ]);

    const [data, setData] = useState(null);
    const [transactions, setTransactions] = useState([]);

    // const [modalAddOpen, setModalAddOpen] = useState(false);
    // const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalFillOpen, setModalFillOpen] = useState(false);

    const [createOrder, createOrderRes] = useMutation(CREATE_ORDER, {
        onCompleted(data) {
            const paymentLink = data.createOrder.data.attributes.paymentLink
            window.location.assign(paymentLink);
        },
        onError(data) {
            console.log(data);
            message.error('Ошибка при оплате. Пожалуйста, обратитесь к администратору');
        }
    });
    // const [update, updateRes] = useMutation(UPDATE);
    // const [refill, refillRes] = useMutation(REFILL);

    const query = useQuery(QUERY, {
        variables: {
            clientId: clientId,
            phone: phone,
        },
        onCompleted: (data) => {
            setData(data.getIikoCard);
        },
        onError: () => {
            message.error('Ошибка загрузки данных о питании');
        },
        skip: phone ? false : true,
    });

    useQuery(QUERY_ORDER, {
        variables: {
            filters: {
                phone: { eq: phone },
                status: { in: [0, 1, 6, 3, 4] },
                updatedAt: {
                    between: [moment().subtract(3, 'hour').format(), moment().format()],
                },
            },
        },
        onCompleted: (data) => {
            setOrders(data.orders.data);
        },
        onError: (error) => {
            message.error('Ошибка загрузки данных о пополнении баланса');
            console.error(error);
        },
        skip: phone ? false : true,
    });

    const transactionsQuery = useQuery(TRANSACTIONS, {
        variables: {
            clientId: clientId,
            phone: phone,
            dateFrom: moment(transactionsRange[0]).format('YYYY-MM-DD'),
            dateTo: moment(transactionsRange[1]).format('YYYY-MM-DD'),
        },
        onCompleted: (data) => {
            setTransactions(data.getIikoTransactions.data);
        },
        onError: () => {
            message.error('Ошибка загрузки данных о транзакциях');
        },
        fetchPolicy: 'cache-and-network',
        skip: data && phone ? false : true,
    });

    // todo: разделить элементы транзации на стили, добавить элипсис на тип

    const onFill = async (formData) => {
        const newData = {
            client: clientId,
            phone: phone,
            amount: formData.balance,
            comment: formData.commnet,
        };

        await createOrder({ variables: { data: newData } });
    };

    return (
        <div>
            <FilterParentsPhones clientId={clientId} onSelect={(value) => setPhone(value)} />

            <div className={'space'} />

            {query.loading && <Skeleton active />}
            {!query.loading && !data && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

            {data !== null && (
                <Space direction={'vertical'} size={20} className={'block'}>
                    <CrmDisplayData
                        label="Браслеты"
                        value={
                            data.cards.length === 0 ? (
                                <span className={'color-danger'}>Нет браслетов</span>
                            ) : (
                                <>
                                    {_.map(data.cards, (item, index) => {
                                        return <span key={index}>💫</span>;
                                    })}{' '}
                                    {data.cards.length} шт.
                                </>
                            )
                        }
                    />

                    <div className={'balance-line'}>
                        <CrmDisplayData
                            label="Баланс"
                            value={
                                <span className={'bold'}>
                                    {`${data.balance} ₽`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                </span>
                            }
                        >
                            {_.map(orders, (order, index) => {
                                if (order.attributes.paid) {
                                    return (
                                        <span key={index}>
                                            <CheckCircleOutlined className={'color-success'} />{' '}
                                            Зачислено{' '}
                                            {`${order.attributes.amount} ₽`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ' ',
                                            )}
                                        </span>
                                    );
                                } else {
                                    return (
                                        <span key={index}>
                                            Ожидает зачисления{' '}
                                            {`${order.attributes.amount} ₽`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ' ',
                                            )}
                                        </span>
                                    );
                                }
                            })}
                        </CrmDisplayData>

                        <div>
                            <Button
                                type="primary"
                                icon={<CreditCardOutlined/>}
                                onClick={() => setModalFillOpen(true)}
                                disabled={
                                    data.isDeleted ||
                                    data.isBlocked ||
                                    data.cards.length === 0 ||
                                    !RoleFunction.nutritionRefillBalance()
                                }
                            >
                                Пополнить баланс
                            </Button>
                            {(data.isDeleted || data.isBlocked) && (
                                <div className={'caption color-gray-3'} style={{width: 200}}>
                                    Пользователь не&nbsp;доступен для пополнения баланса, обратитесь
                                    к&nbsp;Администратору.
                                </div>
                            )}
                        </div>
                        <CrmModal
                            isOpen={modalFillOpen}
                            onClose={() => setModalFillOpen(false)}
                            title={'Пополнить баланс'}
                        >
                            <div className={'modal-basic-form'}>
                                <BalanceForm
                                    onSubmit={onFill}
                                    loading={createOrderRes.loading}
                                    data={{ balance: 1000 }}
                                    actionName={'Перейти к оплате'}
                                    actionIcon={<CreditCardOutlined />}
                                />
                            </div>
                        </CrmModal>
                    </div>
                    <div>
                        <Divider>Транзакции</Divider>

                        <Filters>
                            <FilterRange
                                value={transactionsRange}
                                onChange={(range) => setTransactionsRange(range)}
                            />
                        </Filters>

                        {transactionsQuery.loading && <Skeleton active />}

                        {_.map(transactions, (transaction, index) => {
                            const date = moment(transaction.transactionDate).format(
                                'DD MMM YYYY HH:mm',
                            );
                            const type = transaction.type;
                            const sum = `${transaction.transactionSum} ₽`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ' ',
                            );

                            if (type) {
                                return (
                                    <div className={'transaction'} key={index}>
                                        <span className={'color-gray-3 caption'}>{date}</span>
                                        <div key={index} className={'transaction-line body'}>
                                            <div> {type}</div>
                                            <div>{sum}</div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </Space>
            )}

            <Space direction={'vertical'} size={10} className={'block'}>
                <Divider>Юридическая информация</Divider>

                <Link to={INFO_CATERING}>Организация питания</Link>
                <Link to={INFO_PERSONAL_DATA}>Политика обработки персональных данных</Link>
            </Space>
        </div>
    );
}
