import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import CrmAvatar from '../../../components/CrmAvatar';
import CrmMark from '../../../components/Mark/CrmMark';
import CrmPass from '../../../components/Pass/CrmPass';
import { child_info } from '../../../routes';

export default function VisitListRow({
    client,
    trainingId,
    onUpdate,
}) {


    const enabled = client.attributes.enabled !== false ? true : false;

    return (
        <div className={cn(['visit-list-row', !enabled && 'deleted'])}>
            <div className={'line-2col ellipsis'}>
                <Link to={child_info(client.id)}>
                    <CrmAvatar
                        firstName={client.attributes.firstName}
                        lastName={client.attributes.lastName}
                        color={client.attributes.color}
                    />
                </Link>
            </div>

            <div className={'line-1col'}>
                <CrmPass clientId={client.id} trainingId={trainingId} onUpdate={onUpdate}/>
            </div>

            <div className={'line-1col'}>
                <CrmMark clientId={client.id} trainingId={trainingId} onUpdate={onUpdate}/>
            </div>
        </div>
    );
}
