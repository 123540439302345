import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

import logo from '../../images/Logo.svg';
import { child_info } from '../../routes';

export default function PaymentSuccess() {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <div className="crm-login-layout dissolved">
            <div className="crm-login-logo">
                <img src={logo} className="App-logo" alt="logo" />
            </div>

            <Space direction="vertical" size={20}>
                <div className={'crm-payment-icon'}>
                    <ClockCircleOutlined />
                </div>

                <div className={'title'}>
                    Платёж в обработке
                </div>

                <p>Большое спасибо!</p>
                <p>При успешной оплате деньги поступят на&nbsp;кошелек через несколько минут.</p>

                <Button
                    type={'primary'}
                    size={'large'}
                    onClick={() => navigate(child_info(id))}
                >
                    Хорошо
                </Button>
            </Space>
        </div>
    );
}
