import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

import Mark from '../../components/Mark/Mark';

export default function AverageWeightMark(props) {

    // (M1 * K1) + (M2 + K2) / (K1 + K2)

    const { marks } = props;

    let activityError = false;

    let averageWeightsSum = 0;

    const averageMarksSum =
        marks === 0
            ? 0
            : _.reduce(
                marks,
                (result, mark) => {

                    // смотрим на активность
                    // если ее нет - то ставим ошибке true
                    const activity = mark.attributes.activity.data;
                    let coefficient = 0;
                    if (activity) {
                        coefficient = activity.attributes.coefficient;
                    } else {
                        activityError = true;
                    }
                    averageWeightsSum += coefficient;

                    return result + +mark.attributes.mark * coefficient;
                },
                0,
            );

    const averageWeightMark = averageWeightsSum > 0 ? Math.round(averageMarksSum / averageWeightsSum * 10) / 10 : 0;

    return (
        <div className={'srvz'}>
            <Mark
                mark={averageWeightMark}
                activity={{ attributes: { name: 'Средний взвешенный балл/\nИтоговая оценка' } }}
                comment={''}
                className={'____________'}
            />
            {activityError && (
                <span className={'color-danger'}>
                    {' '}
                    <ExclamationCircleOutlined />
                </span>
            )}
        </div>
    );
}
