import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { group_info } from '../../routes';

import GroupForm from './GroupForm';

const QUERY = gql`
    query query($id: ID) {
        group(id: $id) {
            data {
                id
                attributes {
                    name
                    curator {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    users {
                        data {
                            id
                            attributes {
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: GroupInput!, $id: ID!) {
        updateGroup(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function GroupEdit() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: { id: id },
        onCompleted: (data) => {
            setData({
                name: data.group.data.attributes.name,
                curator:
                    data.group.data.attributes.curator.data &&
                    data.group.data.attributes.curator.data.id,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        await update({ variables: { id: id, data: data } });
        navigate(group_info(id));
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={`Изменить класс ${data && data.name}`} />

            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <GroupForm
                        onSubmit={onSubmit}
                        loading={loading}
                        data={data}
                        actionName={'Сохранить'}
                    />
                </>
            )}
        </CrmLayout>
    );
}
