import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import cn from 'classnames';

import './filters.scss';

export default function FilterSearch(field) {
    return (
        <span className={cn([field.className, 'filter'])}>
            <Input.Search
                {...field}
                className={'filter-search'}
                enterButton={<Button type={'text'} icon={<SearchOutlined />} />}
                size={'small'}
                bordered={false}
            />
        </span>
    );
}
