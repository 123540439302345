import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Modal, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';
import { lessons } from '../../config/lessons';
import RoleFunction from '../../config/roles';
import { homework_edit, NOT_FOUND } from '../../routes';

const GET_TASK = gql`
    query task($id: ID) {
        task(id: $id) {
            data {
                id
                attributes {
                    name
                    comment
                    group {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    trainingTo {
                        data {
                            attributes {
                                subject {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                startDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_TASK = gql`
    mutation deleteTask($id: ID!) {
        deleteTask(id: $id) {
            data {
                id
            }
        }
    }
`;

function setDate(queryDate) {
    let datOfWeek = moment(queryDate).format('dddd');
    datOfWeek = datOfWeek.charAt(0).toUpperCase() + datOfWeek.slice(1);

    let lessonNumber = 0;
    _.find(lessons, ({ start }, i) => {
        if (moment(queryDate).format('H:mm') === start) {
            lessonNumber = i;
            return true;
        }
    });

    const lessonDate = moment(queryDate).startOf('week');
    const todayWeekDate = moment().startOf('week');
    if (lessonDate.diff(todayWeekDate, 'week') === 0) {
        return `${datOfWeek}, ${lessons[lessonNumber].name}`;
    }

    return `${datOfWeek} (${moment(queryDate).format('DD MMM YYYY')}), ${
        lessons[lessonNumber].name
    }`;
}

export const Homework = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [data, setData] = useState(null);

    useQuery(GET_TASK, {
        variables: { id: id },
        onCompleted: (data) => {
            !data.task.data && navigate(NOT_FOUND);
            setData({
                group: data.task.data.attributes.group.data.attributes.name,
                trainingTo:
                    data.task.data.attributes.trainingTo.data.attributes
                        .startDate,
                subject:
                    data.task.data.attributes.trainingTo.data.attributes.subject
                        .data.attributes.name,
                comment: data.task.data.attributes.comment,
                name: data.task.data.attributes.name,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [deleteTask] = useMutation(DELETE_TASK);

    async function onConfirmedDelete(id) {
        await deleteTask({
            variables: {
                id: id,
            },
        });
        navigate(-1);
    }

    function onDelete(id) {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить задание?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete(id);
            },
        });
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Задание'}
                editAction={
                    RoleFunction.updateTask() &&
                    (() => {
                        navigate(homework_edit(id));
                    })
                }
                deleteAction={
                    RoleFunction.updateTask() && (() => onDelete(id))
                }
            ></CrmCenteredTitle>

            {data && (
                <Space direction="vertical" size={20} className="block">
                    <CrmDisplayData
                        label="Класс"
                        value={<span>{data.group}</span>}
                    />
                    <CrmDisplayData
                        label="К уроку"
                        value={<span>{setDate(data.trainingTo)}</span>}
                    />
                    <CrmDisplayData
                        label="Предмет"
                        value={<span>{data.subject}</span>}
                    />
                    <CrmDisplayData
                        label="Задание"
                        value={<span className={'bold'}>{data.name}</span>}
                    />
                    {data.comment &&
                        <CrmDisplayData
                            label="Комментарий"
                            block={true}
                            value={
                                <div className={'crm-message-text'}>{data.comment}</div>
                            }
                        />
                    }
                </Space>
            )}
        </CrmLayout>
    );
};
