import React from 'react';
import cn from 'classnames';

import CrmHeader from './CrmHeader';

export default function CrmLayout(props) {
    const { without_padding } = props;

    return (
        <div>
            <CrmHeader />
            <div
                className={cn([
                    { without_padding: without_padding },
                    'crm-layout crm-layout-content dissolved',
                ])}
            >
                {props.children}
            </div>
        </div>
    );
}
