import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';
import _ from 'lodash';

import CrmAvatar from '../../../components/CrmAvatar';
import CrmCenteredTitle from '../../../components/CrmCenteredTitle';
import CrmLayout from '../../../components/CrmLayout';
import RoleFunction from '../../../config/roles';
import { TEACHER_NEW, trainer_info } from '../../../routes';

import '../user.scss';

const QUERY = gql`
    query query($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(
            filters: $filters
            pagination: { limit: 200 }
            sort: ["trainer.lastName:ASC"]
        ) {
            data {
                id
                attributes {
                    role {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    trainer {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                middleName
                                color
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function Teachers() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const query = useQuery(QUERY, {
        variables: { filters: { role: { id: { in: [3] } } } },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            console.log('data', data);
            setData(data.usersPermissionsUsers.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'🧑‍🏫 Преподаватели'}
                addAction={
                    RoleFunction.createTrainer() &&
                    (() => {
                        navigate(TEACHER_NEW);
                    })
                }
            />

            {query.loading && <Skeleton active />}

            {_.map(data, (user) => {
                const trainer = user.attributes.trainer.data;

                return (
                    <div className="user_item" key={user.id}>
                        <Link to={trainer_info(trainer.id)}>
                            <CrmAvatar
                                size={'large'}
                                firstName={trainer.attributes.firstName}
                                lastName={trainer.attributes.lastName}
                                middleName={trainer.attributes.middleName}
                                color={trainer.attributes.color}
                            />
                        </Link>
                    </div>
                );
            })}
        </CrmLayout>
    );
}
