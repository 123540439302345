import React from 'react';
import { Space } from 'antd';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';

export default function Catering() {
    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Организация питания'} />

            <Space direction={'vertical'} size={20} className={'block'}>
                <CrmDisplayData
                    label="Юридическое лицо"
                    value="ООО «Санфуд»"
                />
                <CrmDisplayData label="ИНН" value="5404007353" />
                <CrmDisplayData label="ОГРН" value="1155476029862" />
                <CrmDisplayData
                    label="Адрес регистрации"
                    value="630048,  г. Новосибирск, пл. Карла Маркса, 7"
                />
                <CrmDisplayData
                    label="Фактический адрес"
                    value="630009, Новосибирская обл, г. Новосибирск, ул. Обская, дом № 48/1"
                />
                <CrmDisplayData
                    label="Электронная почта"
                    value={
                        <a href={'mailto:e.styadya@yandex.ru'}>
                            e.styadya@yandex.ru
                        </a>
                    }
                />
                <CrmDisplayData
                    label="Номер телефона"
                    value="+7 (953) 885-00-75"
                />
            </Space>
        </CrmLayout>
    );
}
