import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message,Skeleton } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { training_info } from '../../routes';

import TrainingForm from './TrainingForm';

const QUERY = gql`
    query query($id: ID) {
        training(id: $id) {
            data {
                id
                attributes {
                    startDate
                    name
                    comment
                    trainers {
                        data {
                            id
                        }
                    }
                    room {
                        data {
                            id
                        }
                    }
                    subject {
                        data {
                            id
                        }
                    }
                    group {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: TrainingInput!, $id: ID!, $massEdit: Boolean!) {
        updateTrainings(data: $data, id: $id, massEdit: $massEdit) {
            data {
                id
            }
        }
    }
`;

export default function TrainingEdit() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: { id: id },
        onCompleted: (data) => {
            setData({
                name: data.training.data.attributes.name,
                comment: data.training.data.attributes.comment,
                room: data.training.data.attributes.room.data
                    ? data.training.data.attributes.room.data.id
                    : null,
                group: data.training.data.attributes.group.data
                    ? data.training.data.attributes.group.data.id
                    : null,
                subject: data.training.data.attributes.subject.data
                    ? data.training.data.attributes.subject.data.id
                    : null,
                trainers: _.map(
                    data.training.data.attributes.trainers.data,
                    (item) => {
                        return item.id;
                    },
                ),
                startDate: moment(data.training.data.attributes.startDate),
            });
        },
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        let newData = {...data};
        delete newData.massEdit;

        console.log('data', data);

        await update({
            variables: { id: id, data: newData, massEdit: data.massEdit},
            onCompleted: (data) => {
                console.log(data);
                navigate(training_info(id));
            },
            onError: (error) => {
                message.error('Ошибка обновления уроков');
                console.error(error);
            }
        });
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={`Изменить урок №${id}`} />

            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <TrainingForm
                        onSubmit={onSubmit}
                        loading={loading}
                        data={data}
                        actionName={'Сохранить'}
                        withGroup
                        withSubject
                        withTrainer
                    />
                </>
            )}
        </CrmLayout>
    );
}
