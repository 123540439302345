import { useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { Store } from '../../model/store/Store';
import { calendar } from '../../routes';

function Index() {
    const { auth } = useInstance(Store);
    const navigate = useNavigate();

    useEffect(() => {
        const date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        const trainer = auth.role === 'Teacher' ? auth.id : 0;
        const group = 0;
        const subject = 0;
        navigate(calendar(date, trainer, group, subject), { replace: true });
    }, []);

    return null;
}

export default observer(Index);
