import React from 'react';
import { Link } from 'react-router-dom';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tooltip} from 'antd';
import currency from 'currency.js';
import moment from 'moment';

import { setValidatedContacts } from '../../helpers/contactsValidate';
import { child_info } from '../../routes';

import classes from './orders.module.scss';

const currencyFormat = (value) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const OrdersRow = ({ order }) => {
    const { createdAt, amount, client, phone, status, iikoStatus, iikoError } = order.attributes;

    const name = client.data && client.data.attributes.firstName;
    const surName = client.data && client.data.attributes.lastName;

    let sberStatusElement
    switch (status) {
        case 0: sberStatusElement = <Tooltip title={'Заказ зарегистрирован, но не оплачен'}><ClockCircleOutlined className={'color-warning'}/></Tooltip>; break;
        case 1: sberStatusElement = <Tooltip title={'Предавторизованная сумма удержана'}><ExclamationCircleOutlined className={'color-danger'}/></Tooltip>; break;
        case 2: sberStatusElement = <Tooltip title={'Проведена полная авторизация суммы заказа'}><CheckCircleOutlined className={'color-success'}/></Tooltip>; break;
        case 3: sberStatusElement = <Tooltip title={'Авторизация отменена'}><ExclamationCircleOutlined className={'color-danger'}/></Tooltip>; break;
        case 4: sberStatusElement = <Tooltip title={'По транзакции была проведена операция возврата'}><ExclamationCircleOutlined className={'color-danger'}/></Tooltip>; break;
        case 5: sberStatusElement = <Tooltip title={'Инициирована авторизация через сервер контроля доступа банка-эмитента'}><ClockCircleOutlined className={'color-warning'}/></Tooltip>; break;
        case 6: sberStatusElement = <Tooltip title={'Авторизация отклонена'}><ExclamationCircleOutlined className={'color-danger'}/></Tooltip>; break;
    }

    let iikoStatusElement
    switch (iikoStatus) {
        case 'new': iikoStatusElement = <Tooltip title={'В очереди на пополнение'}><ClockCircleOutlined className={'color-warning'}/></Tooltip>; break;
        case 'process': iikoStatusElement = <Tooltip title={'В очереди на пополнение'}><ClockCircleOutlined className={'color-warning'}/></Tooltip>; break;
        case 'done': iikoStatusElement = <Tooltip title={'Кошелек пополнен'}><CheckCircleOutlined className={'color-success'}/></Tooltip>; break;
        case 'error': iikoStatusElement = <Tooltip title={iikoError}><ExclamationCircleOutlined className={'color-danger'}/></Tooltip>; break;
    }

    return (
        <div className={classes.row}>
            <div className={classes.row_date}>
                {moment(createdAt).format('DD.MM.YYYY HH:mm')}
            </div>
            <div className={classes.row_phone}>{setValidatedContacts(phone)}</div>
            {client.data ? (
                <Link to={child_info(client.data.id)} className={classes.row_client}>
                    {name} {surName}
                </Link>
            ) : (
                <div className={classes.row_client}>Данных нет</div>
            )}
            <div className={classes.row_sum}>{currencyFormat(amount)}</div>
            <div className={classes.row_paid}>
                {sberStatusElement}
            </div>
            <div className={classes.row_iiko}>
                {iikoStatusElement}
            </div>
        </div>
    );
};
