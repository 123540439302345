import React from 'react';
import {Tooltip} from 'antd';
import _ from 'lodash';

import './mark.scss';

export default function Mark({mark, activity, comment}) {
    const colors = [
        {mark: [91, 100], color: '#7CD039', font: '#333333'},
        {mark: [81, 90], color: '#A0CE29', font: '#333333'},
        {mark: [71, 80], color: '#C0CC1B', font: '#333333'},
        {mark: [61, 70], color: '#E0CA0D', font: '#333333'},
        {mark: [51, 60], color: '#FEC502', font: '#333333'},
        {mark: [41, 50], color: '#F9A220', font: '#333333'},
        {mark: [31, 40], color: '#F47E40', font: '#FFFFFF'},
        {mark: [21, 30], color: '#EE5B5E', font: '#FFFFFF'},
        {mark: [11, 20], color: '#E93464', font: '#FFFFFF'},
        {mark: [0, 10], color: '#E40C6A', font: '#FFFFFF'},
    ];

    const color = _.find(colors, (item) => {
        return Math.round(mark) >= item.mark[0] && Math.round(mark) <= item.mark[1];
    });

    const promtText = activity ? activity.attributes.name : 'Вид деятельности не указан';

    return (
        <Tooltip
            overlayStyle={{whiteSpace: 'pre-line'}}
            overlayInnerStyle={{textAlign: 'left'}}
            title={promtText + '\n' + comment}
        >
            <span
                className={'mark'}
                style={{backgroundColor: color.color, color: color.font}}
            >
                {mark}
            </span>
        </Tooltip>
    );
}
