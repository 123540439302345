import moment from 'moment/moment';

export function getNextSchoolDay(date) {
    if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        if (moment().weekday() + 1 >= 5) {
            // пятница считается 4ым днем
            return moment().add(1, 'week').startOf('week').format('YYYY-MM-DD');
        } else {
            return moment().add(1, 'day').format('YYYY-MM-DD');
        }
    } else {
        return moment(date).format('YYYY-MM-DD');
    }
}
