import React from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal, Space } from 'antd';
import moment from 'moment';

import CrmAvatar from '../../components/CrmAvatar';

const DELETE_MESSAGE = gql`
    mutation updateMessage($data: MessageInput!, $id: ID!) {
        updateMessage(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function ClientMessage(props) {
    const { message, id, onUpdate } = props;

    const [deleteMutation] = useMutation(DELETE_MESSAGE);

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить сообщение?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete();
            },
        });
    }

    async function onConfirmedDelete() {
        await deleteMutation({
            variables: {
                id: id,
                data: {
                    enabled: false,
                },
            },
        });
        onUpdate();
    }

    return (
        <div className="crm-message">
            <Space direction="vertical" size={5} className="block">
                <div className="children-message-title caption">
                    <CrmAvatar
                        size="small"
                        color={message.author.data.attributes.color}
                        firstName={message.author.data.attributes.firstName}
                        lastName={message.author.data.attributes.lastName}
                    />
                    <span>
                        {moment(message.date).fromNow()}{' '}
                        <Button
                            icon={<DeleteOutlined />}
                            type={'link'}
                            onClick={onDelete}
                        />
                    </span>
                </div>
                <div className={'crm-message-text'}>
                    {message.message}

                    {message.fileUrl && (
                        <div className={'crm-message-file'}>
                            <div className={'crm-message-file-icon'}>
                                <FileIcon
                                    extension={message.fileUrl.split('.').pop()}
                                    {...defaultStyles[
                                        message.fileUrl.split('.').pop()
                                    ]}
                                />
                            </div>
                            <span>
                                <a href={message.fileUrl}>
                                    {message.fileUrl.substring(
                                        message.fileUrl.lastIndexOf('/') + 1,
                                    )}
                                </a>
                            </span>
                        </div>
                    )}
                </div>
            </Space>
        </div>
    );
}
