import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {SaveOutlined} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import {ACTIVITIES, NOT_FOUND} from '../../routes';

import ActivityForm from './ActivityForm';

const GET_ACTIVITY = gql`
    query activity($id: ID) {
        activity(id: $id) {
            data {
                id
                attributes {
                    coefficient
                    name
                    abbr
                }
            }
        }
    }
`;

const EDIT_ACTIVITY = gql`
    mutation updateActivity($data: ActivityInput!, $id: ID!) {
        updateActivity(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const ActivityEdit = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(GET_ACTIVITY, {
        variables: { id: id },
        onCompleted: (data) => {
            !data.activity.data && navigate(NOT_FOUND)
            setData({
                coefficient: data.activity.data.attributes.coefficient,
                name: data.activity.data.attributes.name,
                abbr: data.activity.data.attributes.abbr,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [editActivity] = useMutation(EDIT_ACTIVITY);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        await editActivity({
            variables: {
                id: id,
                data: { ...data, coefficient: +data.coefficient }, // gql принимает coefficient только типа number
            },
        });
        navigate(ACTIVITIES);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={`Изменить вид активности "${data && data.name}"`}
            />

            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <ActivityForm
                        onSubmit={onSubmit}
                        loading={loading}
                        data={data}
                        actionName={'Сохранить'}
                        actionIcon={<SaveOutlined />}
                    />
                </>
            )}
        </CrmLayout>
    );
};

export default ActivityEdit;
