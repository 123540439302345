import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import {setValidatedContacts} from '../../helpers/contactsValidate';
import {trainer_info} from '../../routes';

import TrainerForm from './TrainerForm';

const QUERY = gql`
    query query ($id: ID) {
        trainer(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    middleName
                    color
                    enabled
                    birthDate
                    account {
                        data {
                            id
                            attributes {
                                username
                                email
                                contacts {
                                    type
                                    value
                                }
                            }
                        }
                    }
                    subjects {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDDATE_USER = gql`
    mutation users ($id: ID!, $data: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE_TRAINER = gql`
    mutation users ($id: ID!, $data: TrainerInput!) {
        updateTrainer (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function TrainerEdit() {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: ((data) => {

            const trainer = data.trainer.data;
            const account = trainer.attributes.account.data;
            const contacts = account.attributes.contacts;
            const subjects = trainer.attributes.subjects.data;

            setData({
                firstName: trainer.attributes.firstName,
                lastName: trainer.attributes.lastName,
                middleName: trainer.attributes.middleName,
                birthDate: trainer.attributes.birthDate && moment(trainer.attributes.birthDate),
                contacts: _.map(contacts, (value) => {return {type: value.type, value: value.value}}),
                subjects: _.map(subjects, (value) => {return (value.id)}),
            });

            setAccountId(account.id);

        })
    });

    const [updateUser] = useMutation(UPDDATE_USER);
    const [updateTrainer] = useMutation(UPDATE_TRAINER);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            contacts: setValidatedContacts(data.contacts),
            group: data.group,
        }
        await updateUser({variables: {id: accountId, data: userData}});

        const trainerData = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            subjects: data.subjects,
            birthDate: data.birthDate ? moment(data.birthDate).format('YYYY-MM-DD') : null,
        }
        await updateTrainer({variables: {id: id, data: trainerData}});

        navigate(trainer_info(id));

    }

    let displayTitle = '–';
    if (data) {
        displayTitle = data.middleName ? `${data.lastName} ${data.firstName} ${data.middleName}` : `${data.firstName} ${data.lastName}`;
    }

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={displayTitle}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <TrainerForm onSubmit={onSubmit} loading={loading} data={data} withSubject actionName={'Сохранить'} withGroup/>
            </>
            }

        </CrmLayout>
    );
}
