import React, { useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'antd';
import cn from 'classnames';

import RoleFunction from '../../config/roles';

import ModalParentAdd from './ModalParentAdd';
import ModalParentSelect from './ModalParentSelect';

const UPDATE_CLIENT_PARENTS = gql`
    mutation parent($id: ID!, $parents: [ID]) {
        updateClientParents(id: $id, parents: $parents) {
            done
        }
    }
`;

export default function ChildParentsSelectAndAdd({
    childId,
    onUpdate,
    parents,
    className
}) {
    const [updateClientParents] = useMutation(UPDATE_CLIENT_PARENTS);

    // Select Parents
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        const parents = value.map(function (item) {
            return item.id;
        });
        await updateClientParents({
            variables: { id: childId, parents: parents },
        });
        onUpdate();
        setModalSelectVisible(false);
    };

    // Add Parent
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddParent = async function () {
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <div className={cn(['crm-line', className]) }>
                {RoleFunction.updateParent() && (
                    <Button
                        type={'link'}
                        icon={<EditOutlined />}
                        onClick={() => setModalSelectVisible(true)}
                    >
                        Выбрать из списка
                    </Button>
                )}
                {RoleFunction.createParent() && (
                    <Button
                        type={'link'}
                        icon={<PlusOutlined />}
                        onClick={() => setModalAddVisible(true)}
                    >
                        Добавить родителя
                    </Button>
                )}
            </div>

            <ModalParentSelect
                isOpen={modalSelectVisible}
                onClose={() => setModalSelectVisible(false)}
                selected={parents}
                onFinish={(value) => onSaveList(value)}
            />
            <ModalParentAdd
                isOpen={modalAddVisible}
                onClose={() => setModalAddVisible(false)}
                childId={childId}
                onFinish={(value) => onAddParent(value)}
            />
        </>
    );
}
