export function setValidatedContacts(contacts) {

    if (contacts) {

        if (typeof contacts === 'object') {
            return contacts.map(el => {
                return el.type.includes('phone') || el.type.includes('whatsapp')
                    ? {
                        ...el,
                        value: el.value.replace(/\D/g, ''),
                    }
                    : el
            })
        }

        return contacts.includes('@')
            ? contacts
            : contacts.replace(/\D/g, '')

    }
}

export function getValidatedContacts(contacts) {
    if (typeof contacts === 'object') {
        return contacts.map(el => {
            return el.type.includes('phone') || el.type.includes('whatsapp')
                ? {
                    ...el,
                    value: `+${el.value[0]} (${el.value.slice(1, 4)}) ${el.value.slice(4, 7)}-${el.value.slice(7, 9)}-${el.value.slice(9)}`,
                }
                : el
        })
    }

    return contacts.includes('@') || contacts.includes('+')
        ? contacts
        : `+${contacts[0]} (${contacts.slice(1, 4)}) ${contacts.slice(4, 7)}-${contacts.slice(7, 9)}-${contacts.slice(9)}`
}
