import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ArrowLeftOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

export default function CrmCenteredTitle({
    title,
    addAction,
    editAction,
    deleteAction,
}) {
    const navigate = useNavigate();

    return (
        <div className="crm-title">
            <div className={'crm-title-back'}>
                <Button
                    type={'link'}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            <div className={'crm-title-headline'}>{title || 'Страница'}</div>
            <div className={'crm-title-action'}>
                {addAction && (
                    <Button
                        type={'link'}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            addAction();
                        }}
                    />
                )}
                {editAction && (
                    <Button
                        type={'link'}
                        icon={<EditOutlined />}
                        onClick={() => {
                            editAction();
                        }}
                    />
                )}
                {deleteAction && (
                    <Button
                        type={'link'}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            deleteAction();
                        }}
                    />
                )}
            </div>
        </div>
    );
}
