import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Empty, Modal, Skeleton, Space } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { year_edit } from '../../routes';

import { QuarterRow } from './QuarterRow';

const GET_YEAR = gql`
    query year($id: ID) {
        year(id: $id) {
            data {
                id
                attributes {
                    name
                    quarters {
                        data {
                            id
                            attributes {
                                name
                                startDate
                                endDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_YEAR = gql`
    mutation deleteYear($id: ID!) {
        deleteYear(id: $id) {
            data {
                id
            }
        }
    }
`;

const DELETE_QUATRE = gql`
    mutation deleteQuarter($id: ID!) {
        deleteQuarter(id: $id) {
            data {
                id
            }
        }
    }
`;

export const Year = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const query = useQuery(GET_YEAR, {
        variables: {
            id: id,
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.year.data);
        },
    });

    const [deleteYear] = useMutation(DELETE_YEAR);
    const [deleteQuarter] = useMutation(DELETE_QUATRE);

    async function onConfirmedDelete(id) {
        await deleteYear({
            variables: {
                id: id,
            },
        });

        _.map(data, async (quarter) => {
            await deleteQuarter({
                variables: {
                    id: quarter.id,
                },
            });
        });

        await query.refetch();
    }

    function onDelete(id) {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить учебный год?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete(id);
            },
        });
    }

    let displayTitle = '';

    if (query.data) {
        displayTitle = query.data.year.data.attributes.name;
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={displayTitle}
                editAction={
                    RoleFunction.updateYears() &&
                    (() => {
                        navigate(year_edit(id));
                    })
                }
                deleteAction={
                    RoleFunction.updateYears() && (() => onDelete(id))
                }
            ></CrmCenteredTitle>

            <Space direction={'vertical'} size={20} className={'block '}>
                <div className={'visit-list-row'}>
                    <div className={'year-col-name-first'}>
                        <span className={'caption color-gray-3'}>Четверти</span>
                    </div>

                    <div>
                        <span className={'caption color-gray-3'}>Даты</span>
                    </div>
                </div>
            </Space>

            {query.loading && <Skeleton active />}

            {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {data.attributes &&
                _.map(data.attributes.quarters.data, (year) => {
                    const { name, startDate, endDate } = year.attributes;

                    return (
                        <QuarterRow
                            name={name}
                            startDate={startDate}
                            endDate={endDate}
                            key={year.id}
                        />
                    );
                })}
        </CrmLayout>
    );
};
