import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Divider, message, Modal, Skeleton, Space } from 'antd';
import _ from 'lodash';

import CrmAvatar from '../../components/CrmAvatar';
import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmDisplayData from '../../components/CrmDisplayData';
import CrmLayout from '../../components/CrmLayout';
import RoleFunction from '../../config/roles';
import { child_info, GROUP, group_edit } from '../../routes';

import GroupClientsSelectAndAdd from './GroupClientsSelectAndAdd';

const QUERY = gql`
    query query($id: ID) {
        group(id: $id) {
            data {
                id
                attributes {
                    name
                    curator {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    users(pagination: { pageSize: 200 }) {
                        data {
                            id
                            attributes {
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE = gql`
    mutation delete($id: ID!) {
        deleteGroup(id: $id) {
            data {
                id
            }
        }
    }
`;

export default function GroupInfo() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const query = useQuery(QUERY, {
        variables: { id: id },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.group.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [deleteMutation] = useMutation(DELETE);

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить класс?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete();
            },
        });
    }

    async function onConfirmedDelete() {
        await deleteMutation({ variables: { id: id } });
        navigate(GROUP);
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={data && data.attributes.name}
                editAction={
                    RoleFunction.updateGroup() &&
                    (() => {
                        navigate(group_edit(id));
                    })
                }
                deleteAction={
                    RoleFunction.deleteGroup() &&
                    (() => {
                        onDelete();
                    })
                }
            />

            {query.loading && <Skeleton active />}

            {data && (
                <Space direction="vertical" size={20} className="block">
                    <CrmDisplayData
                        label="Назвавние"
                        value={data.attributes.name}
                    />

                    {data.attributes.curator.data && (
                        <CrmDisplayData
                            label="Куратор"
                            value={
                                <CrmAvatar
                                    firstName={
                                        data.attributes.curator.data.attributes
                                            .trainer.data.attributes.firstName
                                    }
                                    lastName={
                                        data.attributes.curator.data.attributes
                                            .trainer.data.attributes.lastName
                                    }
                                    color={
                                        data.attributes.curator.data.attributes
                                            .trainer.data.attributes.color
                                    }
                                />
                            }
                        />
                    )}

                    <div>
                        <Divider>
                            Список учеников ({data.attributes.users.data.length}
                            )
                        </Divider>

                        {_.map(data.attributes.users.data, (user) => {
                            const client = user.attributes.client.data;

                            return (
                                <div
                                    className="group_user_item"
                                    key={client.id}
                                >
                                    <Link to={child_info(client.id)}>
                                        <CrmAvatar
                                            size={'large'}
                                            firstName={
                                                client.attributes.firstName
                                            }
                                            lastName={
                                                client.attributes.lastName
                                            }
                                            color={client.attributes.color}
                                        />
                                    </Link>
                                </div>
                            );
                        })}

                        <GroupClientsSelectAndAdd
                            id={id}
                            users={data.attributes.users.data}
                            onUpdate={query.refetch}
                        />
                    </div>
                </Space>
            )}
        </CrmLayout>
    );
}
