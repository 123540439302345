import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'antd';
import moment from 'moment/moment';

import CrmModal from '../../components/CrmModal';
import RoleFunction from '../../config/roles';
import { homework_page } from '../../routes';
import HomeworkForm from '../Homework/HomeworkForm';

import classes from './plan.module.scss';

const EDIT_TASK = gql`
    mutation updateTask($data: TaskInput!, $id: ID!) {
        updateTask(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export const PlanTask = (props) => {
    const { task, training, refetch } = props;

    const [state, setState] = useState({ editTask: false });
    const oldTaskData = useRef({
        name: '',
        date: moment()
    });

    const [editTask, resEditTask] = useMutation(EDIT_TASK);

    useEffect(() => {
        oldTaskData.current = {
            name: training.attributes.subject.data.attributes.name,
            date: moment(training.attributes.startDate).format('DD.MM.YYYY'),
        };
    }, []);

    const onEdit = async (data) => {
        await editTask({
            variables: {
                id: task.id,
                data: {
                    comment: data.comment,
                    name: data.name,
                    ...(data.trainingTo.includes(oldTaskData.current.name)
                        ? {}
                        : {
                            trainingTo: data.trainingTo,
                            dueDate: data.dueDate,
                        }),
                },
            },
        });
        setState({ ...state, editTask: false });
        refetch();
    };

    return (
        <div className={classes.tasks_row}>

            <Link to={homework_page(task.id)} className={classes.content_task}>
                {task.attributes.name}
            </Link>

            {RoleFunction.updateTask() &&
            <>
                <Button
                    className={classes.content_button}
                    type={'link'}
                    icon={<EditOutlined />}
                    onClick={() => {
                        setState({ ...state, editTask: true });
                    }}
                />
                <CrmModal
                    isOpen={state.editTask}
                    onClose={() => setState({ ...state, editTask: false })}
                    title={'Изменить задание'}
                >
                    <div className={'modal-basic-form'}>
                        <HomeworkForm
                            onSubmit={onEdit}
                            loading={resEditTask.loading}
                            actionName={'Сохранить'}
                            actionIcon={<SaveOutlined/>}
                            data={{
                                name: task.attributes.name,
                                comment: task.attributes.comment,
                                trainingTo: task.attributes.trainingTo.data.id,
                            }}
                        />
                    </div>
                </CrmModal>
            </>}
        </div>
    );
};

