import React from 'react';
import _ from 'lodash';

import Mark from '../../components/Mark/Mark';

export default function AverageMark({trainings}) {

    // let sum = 0;

    let allMarks = [];

    _.forEach(trainings, (training) => {
        const marks = training.marks;
        _.forEach(marks, (mark) => {
            if (mark) {
                allMarks.push(mark);
            }
        })
    });

    let averageSum = 0;
    _.forEach(allMarks, (mark) => {
        averageSum += mark.attributes.mark;
    });
    const averageMark = averageSum > 0 ? Math.round(averageSum / allMarks.length) : 0;

    return (
        <div>

            <Mark mark={averageMark} activity={{attributes: {name: 'Средний балл'}}} comment={''}/>

        </div>
    );
}
