import React, { useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'antd';

import ModalChildAdd from '../../../components/ModalChildAdd';
import ModalChildrenSelect from '../../../components/ModalChildrenSelect';
import RoleFunction from '../../../config/roles';

const UPDATE_PARENT = gql`
    mutation parent($data: ParentInput!, $id: ID!) {
        updateParent(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentChildrenSelectAndAdd({
    parentId,
    children,
    onUpdate,
}) {
    const [updateParent] = useMutation(UPDATE_PARENT);

    // Select Clients
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        let clients = value.map(function (item) {
            return item.id;
        });
        await updateParent({
            variables: { data: { clients: clients }, id: parentId },
        });
        onUpdate();
        setModalSelectVisible(false);
    };

    // Add Client
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddClient = async function (userId, clientId) {
        let clients = children.map(function (value) {
            return value.id;
        });
        clients.push(clientId);
        await updateParent({
            variables: { data: { clients: clients }, id: parentId },
        });
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <div className={'crm-line'}>
                {RoleFunction.updateParent() && (
                    <Button
                        type={'link'}
                        icon={<EditOutlined />}
                        onClick={() => setModalSelectVisible(true)}
                    >
                        Выбрать из списка
                    </Button>
                )}
                {RoleFunction.createClient() && (
                    <Button
                        type={'link'}
                        icon={<PlusOutlined />}
                        onClick={() => setModalAddVisible(true)}
                    >
                        Добавить ребенка
                    </Button>
                )}
            </div>

            <ModalChildrenSelect
                isOpen={modalSelectVisible}
                onClose={() => setModalSelectVisible(false)}
                selected={children}
                onFinish={(value) => onSaveList(value)}
            />
            <ModalChildAdd
                isOpen={modalAddVisible}
                onClose={() => setModalAddVisible(false)}
                onFinish={(userId, clientId) => onAddClient(userId, clientId)}
            />
        </>
    );
}
