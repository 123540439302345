import React from 'react';
import cn from 'classnames';

import './filters.scss';

export default function Filters({ children, withoutOverflow }) {
    return (
        <div
            className={cn([
                'filter-line',
                withoutOverflow ? 'without-overflow' : null,
            ])}
        >
            {children}
        </div>
    );
}
