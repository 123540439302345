import React from 'react';
import _ from 'lodash';

import VisitListRow from './VisitListRow';

import './visit.scss';

export default function TrainingVisits({ trainingId, users, onUpdate }) {

    // рендерим пересечения ученика и пропуска с оценками

    return (
        <>
            <div className={'visit-list-row'}>
                <div className={'line-2col ellipsis'}>
                    <span className={'caption color-gray-3'}>Ученики</span>
                </div>

                <div className={'line-1col'}>
                    <span className={'caption color-gray-3'}>Пропуски</span>
                </div>

                <div className={'line-1col'}>
                    <span className={'caption color-gray-3'}>Оценки</span>
                </div>
            </div>

            {_.map(users, (user) => {

                const client = user.attributes.client.data;

                return (
                    <VisitListRow
                        key={user.id}
                        client={client}
                        trainingId={trainingId}
                        onUpdate={onUpdate}
                    />
                );
            })}

        </>
    );
}
