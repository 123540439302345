import React from 'react';

export const QuarterRow = (props) => {
    const { name, startDate, endDate } = props;
    const reformatDate = (date) => {
        return date.split('-').reverse().join('.');
    };

    return (
        <div className={'quarter-list-row'}>
            <div className={'quarter-name'}>{name}</div>
            <div>
                {reformatDate(startDate)} - {reformatDate(endDate)}
            </div>
        </div>
    );
};
