import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { SUBJECT } from '../../routes';

import SubjectForm from './SubjectForm';

const QUERY = gql`
    query query($id: ID) {
        subject(id: $id) {
            data {
                id
                attributes {
                    name
                    color
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: SubjectInput!, $id: ID!) {
        updateSubject(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function SubjectEdit() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: { id: id },
        onCompleted: (data) => {
            setData({
                name: data.subject.data.attributes.name,
                color: data.subject.data.attributes.color,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        await update({ variables: { id: id, data: data } });
        navigate(SUBJECT);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={`Изменить предмет ${data && data.name}`} />

            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <SubjectForm
                        onSubmit={onSubmit}
                        loading={loading}
                        data={data}
                        actionName={'Сохранить'}
                    />
                </>
            )}
        </CrmLayout>
    );
}
