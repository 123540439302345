import { Link, useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

import RoleFunction from '../../config/roles';
import { year_edit } from '../../routes';

import './years.scss';

export const YearRow = (props) => {
    const { name, deleteYear, yearId } = props;

    const navigate = useNavigate();

    return (
        <Space className={'years-list-row'}>
            <Link to={yearId} className={'years-name'}>
                {name}
            </Link>
            <div className={'years-buttons'}>
                <Button
                    type={'link'}
                    icon={<EditOutlined />}
                    onClick={() => {
                        navigate(year_edit(yearId));
                    }}
                />
                <Button
                    type={'link'}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        RoleFunction.updateYears() && deleteYear(yearId);
                    }}
                />
            </div>
        </Space>
    );
};
