import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { group_info } from '../../routes';

import GroupForm from './GroupForm';

const CREATE = gql`
    mutation create($data: GroupInput!) {
        createGroup(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function GroupNew() {
    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await create({ variables: { data: data } });
        navigate(group_info(res.data.createGroup.data.id));
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Новый класс'} />

            <GroupForm
                onSubmit={onSubmit}
                loading={loading}
                actionName={'Добавить'}
                actionIcon={<PlusOutlined />}
            />
        </CrmLayout>
    );
}
