import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { lessons } from '../../config/lessons';

import CalendarTraining from './CalendarTraining';

import './calendar.scss';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function timeToPixels(time) {
    const value = `${time}`;
    const h = value.split(':')[0];
    const m = value.split(':')[1];
    // так как первые 8 уроков отсутствуют, нужно их вычесть, что бы 8ой был 1ым в списке
    return (parseInt(h) - 8) * 60 + parseInt(m);
}

export default function CalendarGrid({
    loading,
    trainings,
    startWeek,
    allowCreate,
    onCreate,
}) {
    // ширина календаря заввисит от ширины экрана и подставляем кратное количество блоков
    // высота календаря 1 минута = 1 px, 1 час = 60 px
    // отрисовывем дополнительную сетку по урокам школы с переменами

    // замерили ширину окна и отловили ее изменение
    // данные записали в calWidth
    const [calWidth, setCalWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setCalWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //  расчитали размер ячеек исходя из ширины экрана
    // ||  ||      ||      ||      ||
    function calculateGrid(width) {
        let columns = 3;
        if (width >= 660) {
            columns = 7;
        } else if (width >= 570) {
            columns = 6;
        } else if (width >= 480) {
            columns = 5;
        } else if (width >= 390) {
            columns = 4;
        } else {
            columns = 3;
        }
        const gap = 10;
        const hourHeight = 60;
        const recomendedFirstColumnWidth = 20;
        const columnWidth = Math.floor(
            (width - (columns + 2) * gap - recomendedFirstColumnWidth) /
                columns,
        );
        const firstColumn = width - columns * columnWidth - (columns + 2) * gap;
        const contentWidth = firstColumn + columnWidth * 7 + gap * 9;
        const daysWidth = columnWidth * 7 + gap * 6;
        return {
            width: width,
            columns: columns,
            columnWidth: columnWidth,
            firstColumn: firstColumn,
            gap: gap,
            hourHeight: hourHeight,
            contentWidth: contentWidth,
            daysWidth: daysWidth,
        };
    }

    const calGrid = calculateGrid(calWidth);

    // заполнили данные для легеды: названий дней недели, числа и "если сегодня"
    let legend = [];
    for (let i = 0; i <= 6; i++) {
        const day = moment(startWeek).add(i, 'days');

        legend.push({
            name: day.format('dd'),
            number: day.format('DD'),
            isToday: day.isSame(moment(), 'days'),
            date: day,
        });
    }

    // массив для обозначения времени
    let hours = [
        // { name: '0:00' },
        // { name: '1:00' },
        // { name: '2:00' },
        // { name: '3:00' },
        // { name: '4:00' },
        // { name: '5:00' },
        // { name: '6:00' },
        // { name: '7:00' },
        { name: '8:00' },
        { name: '9:00' },
        { name: '9:40' },
        { name: '9:50' },
        { name: '10:30' },
        { name: '10:50' },
        { name: '11:30' },
        { name: '11:50' },
        { name: '12:30' },
        { name: '12:40' },
        { name: '13:20' },
        { name: '13:45' },
        { name: '14:25' },
        { name: '14:50' },
        { name: '15:30' },
        { name: '15:40' },
        { name: '16:20' },
        { name: '17:00' },
        { name: '18:00' },
        // { name: '19:00' },
        // { name: '20:00' },
        // { name: '21:00' },
        // { name: '22:00' },
        // { name: '23:00' },
    ];

    let lines = [];
    for (let i = 0; i <= 23; i++) {
        lines.push(i);
    }

    // создаем refObject для контента календаря
    const calRef = useRef();
    // скролим внутри до первого урока
    useEffect(() => {
        calRef.current?.scrollTo({
            top: timeToPixels(lessons[0].start),
            behavior: 'smooth',
        });
    }, []);

    // расчет позиции текущего времени
    // const [now, setNow] = useState();
    const [time, setTime] = useState(moment());
    useEffect(() => {
        const interval = setInterval(() => setTime(moment()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const nowDay = time.isoWeekday() - 1;
    const now = {
        top: timeToPixels(time.format('HH:mm')),
        left:
            calGrid.firstColumn +
            nowDay * calGrid.columnWidth +
            (nowDay + 2) * calGrid.gap,
    };

    // перегруппировывваем массив с уроками чтобы отображать колизии
    // todo проверять коллизии если даты не совпадают
    const trainingsData = _.groupBy(trainings, 'attributes.startDate');

    return (
        <div className="calendar">
            <div
                className={'calendar-header'}
                style={{ width: calGrid.contentWidth }}
            >
                {_.map(legend, (day, index) => {
                    const posX =
                        calGrid.firstColumn +
                        index * calGrid.columnWidth +
                        (index + 2) * calGrid.gap;
                    return (
                        <div
                            key={`day${index}`}
                            className={cn(['day', day.isToday && 'today'])}
                            style={{ width: calGrid.columnWidth, left: posX }}
                        >
                            {/*{day.isToday ? 'Сегодня' : <span>{day.name}, {day.number}</span>}*/}
                            <span>
                                {day.name}, {day.number}
                            </span>
                        </div>
                    );
                })}
            </div>

            <div
                ref={calRef}
                className={'calendar-content'}
                style={{ width: calGrid.contentWidth }}
            >
                {_.map(lessons, (lesson, index) => {
                    const posX = calGrid.firstColumn + calGrid.gap * 2;
                    const posY = timeToPixels(lesson.start);
                    const height = timeToPixels(lesson.end) - posY;
                    return (
                        <div
                            key={index}
                            className={'lesson'}
                            style={{
                                width: calGrid.daysWidth,
                                left: posX,
                                top: posY + 7,
                                height: height,
                            }}
                        />
                    );
                })}

                {_.map(legend, (day, indexD) => {
                    return (
                        <React.Fragment key={indexD}>
                            {_.map(lessons, (lesson, indexL) => {
                                const posX =
                                    calGrid.firstColumn +
                                    indexD * calGrid.columnWidth +
                                    (indexD + 2) * calGrid.gap;
                                const posY = timeToPixels(lesson.start);
                                const height = timeToPixels(lesson.end) - posY;
                                const h = lesson.start.split(':')[0];
                                const m = lesson.start.split(':')[1];
                                const startDate = moment(day.date)
                                    .hour(h)
                                    .minutes(m);
                                return (
                                    <React.Fragment key={indexL}>
                                        {loading && (
                                            <div
                                                className={'loading'}
                                                style={{
                                                    width: calGrid.columnWidth,
                                                    height: height,
                                                    top: posY + 7,
                                                    left: posX,
                                                }}
                                            >
                                                <Skeleton.Button block active />
                                            </div>
                                        )}
                                        {allowCreate && (
                                            <div
                                                className={'add-button'}
                                                onClick={() =>
                                                    onCreate(startDate)
                                                }
                                                style={{
                                                    width: calGrid.columnWidth,
                                                    height: height,
                                                    top: posY + 7,
                                                    left: posX,
                                                }}
                                            >
                                                <PlusOutlined />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}

                {_.map(hours, (hour, index) => {
                    const posY = timeToPixels(hour.name) + 7;
                    const lineWidth = calGrid.daysWidth + 5;
                    return (
                        <div
                            key={index}
                            className={'hour'}
                            style={{ left: calGrid.gap, top: posY }}
                        >
                            <div
                                className={'hour-name'}
                                style={{ width: calGrid.firstColumn }}
                            >
                                {hour.name}
                            </div>
                            <div
                                className={'hour-line'}
                                style={{ width: lineWidth }}
                            />
                        </div>
                    );
                })}

                {/*{_.map(lines, (line, index) => {*/}
                {/*const posY = calGrid.hourHeight * index + 7;*/}
                {/*const lineWidth = calGrid.daysWidth + 5;*/}
                {/*return (*/}
                {/*<div key={index} className={'hour'} style={{left: calGrid.gap, top: posY}}>*/}
                {/*<div className={'hour-line'} style={{width: lineWidth}}/>*/}
                {/*</div>*/}
                {/*)*/}
                {/*})}*/}

                {/*{_.map(trainings, (training) => {*/}
                {/*const startDate = moment(training.attributes.startDate);*/}
                {/*const day = startDate.isoWeekday()-1;*/}
                {/*const time = startDate.format('HH:mm');*/}
                {/*const posX = calGrid.firstColumn + day * calGrid.columnWidth + (day + 2) * calGrid.gap;*/}
                {/*const posY = timeToPixels(time);*/}
                {/*const width = calGrid.columnWidth - 20;*/}

                {/*return (*/}
                {/*<div key={training.id} className={'training'} style={{width: width, left: posX, top: (posY+7)}}>*/}
                {/*<CalendarTraining*/}
                {/*id={training.id}*/}
                {/*name={training.attributes.name}*/}
                {/*trainers={training.attributes.trainers.data}*/}
                {/*subject={training.attributes.subject.data}*/}
                {/*room={training.attributes.room.data}*/}
                {/*group={training.attributes.group.data}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*)*/}
                {/*})}*/}

                {_.map(trainingsData, (trainings, indexT) => {
                    return (
                        <React.Fragment key={indexT}>
                            {_.map(trainings, (training, index) => {
                                const startDate = moment(
                                    training.attributes.startDate,
                                );
                                const day = startDate.isoWeekday() - 1;
                                const time = startDate.format('HH:mm');

                                const width =
                                    (calGrid.columnWidth - 20) /
                                    trainings.length;
                                const posX =
                                    calGrid.firstColumn +
                                    day * calGrid.columnWidth +
                                    (day + 2) * calGrid.gap +
                                    width * index;
                                const posY = timeToPixels(time);

                                return (
                                    <div
                                        key={training.id}
                                        className={'training'}
                                        style={{
                                            width: width,
                                            left: posX,
                                            top: posY + 7,
                                        }}
                                    >
                                        <CalendarTraining
                                            id={training.id}
                                            name={training.attributes.name}
                                            trainers={
                                                training.attributes.trainers
                                                    .data
                                            }
                                            subject={
                                                training.attributes.subject.data
                                            }
                                            room={training.attributes.room.data}
                                            group={
                                                training.attributes.group.data
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}

                {moment(startWeek).isSame(moment().startOf('week'), 'days') && (
                    <div className={'now'} style={now}>
                        {/*<div className={'name'}>{moment().format('HH:mm')}</div>*/}
                        <div className={'dot'} />
                        <div
                            className={'line'}
                            style={{ width: calGrid.columnWidth }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
