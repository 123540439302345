import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CrmWindowSpin = (props) => {
    if (props.spinning) {
        return <Spin indicator={antIcon} className={'crm-window-spin'} />;
    } else {
        return null;
    }
};

export default CrmWindowSpin;
