import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { Link } from 'react-router-dom';
import { LogoutOutlined, MenuOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { getNextSchoolDay } from '../helpers/getNextSchoolDay';
import logo from '../images/Logo.svg';
import { Store } from '../model/store/Store';
import {
    ACTIVITIES,
    ADMIN,
    CHILDREN,
    CURATOR,
    GROUP,
    homeworks,
    INDEX,
    journal,
    LOGOUT,
    ORDERS,
    PARENT,
    plan,
    progress,
    ROOM,
    SETTINGS,
    SUBJECT,
    TEACHER,
    YEARS,
} from '../routes';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function CrmHeader(props) {
    const { auth } = useInstance(Store);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let firstName = '';
    let lastName = '';
    let color = null;

    if (auth.data !== null) {
        firstName = auth.data.firstName;
        lastName = auth.data.lastName;
        color = auth.data.color;
    }

    const menu = (
        <Menu>
            <Menu.Item key={'index'}>
                <Link to={INDEX}>🗓 Расписание</Link>
            </Menu.Item>
            <Menu.Item key={'task'}>
                <Link to={homeworks(0, 0, moment(getNextSchoolDay(moment())).format('YYYY-MM-DD'))}>
                    ✅ Задания
                </Link>
            </Menu.Item>
            {(auth.role === 'Authenticated' ||
                auth.role === 'Curator' ||
                auth.role === 'Teacher' ||
                auth.role === 'Admin') && (
                <Menu.Item key={'journal'}>
                    <Link to={journal(0, 0, 0, 0)}>📔 Журнал</Link>
                </Menu.Item>
            )}
            {(auth.role === 'Authenticated' ||
                auth.role === 'Curator' ||
                auth.role === 'Teacher' ||
                auth.role === 'Admin') && (
                <Menu.Item key={'plan'}>
                    <Link to={plan(0, 0, 0, 0)}>🖌 Учебный план</Link>
                </Menu.Item>
            )}
            <Menu.Item key={'progress'}>
                <Link to={progress(0, 0, 0)}>⭐ Успеваемость</Link>
            </Menu.Item>
            {(auth.role !== 'Child') &&
                <>
                    <Menu.Item key={'group'}>
                        <Link to={GROUP}>👥 Классы</Link>
                    </Menu.Item>
                    <Menu.Item key={'client'}>
                        <Link to={CHILDREN}>👦 Ученики</Link>
                    </Menu.Item>
                </>
            }
            <Menu.Item key={'teacher'}>
                <Link to={TEACHER}>🧑‍🏫 Преподаватели</Link>
            </Menu.Item>

            {(auth.role === 'Authenticated' ||
                auth.role === 'Curator' ||
                auth.role === 'Teacher' ||
                auth.role === 'Admin') && (
                <>
                    <Menu.Item key={'curator'} disabled>
                        <Link to={CURATOR}>🙎 Кураторы</Link>
                    </Menu.Item>
                    <Menu.Item key={'parent'} disabled>
                        <Link to={PARENT}>🧓 Родители</Link>
                    </Menu.Item>
                </>
            )}

            {(auth.role === 'Authenticated' || auth.role === 'Admin') && (
                <>
                    <Menu.Item key={'admin'}>
                        <Link to={ADMIN}>🦋 Администраторы</Link>
                    </Menu.Item>

                    <Menu.Item key={'room'}>
                        <Link to={ROOM}>🚪 Кабинеты</Link>
                    </Menu.Item>

                    <Menu.Item key={'subject'}>
                        <Link to={SUBJECT}>📚 Предметы</Link>
                    </Menu.Item>

                    <Menu.Item key={'activities'}>
                        <Link to={ACTIVITIES}>🙋️ Виды деятельности</Link>
                    </Menu.Item>

                    <Menu.Item key={'years'}>
                        <Link to={YEARS}>📌 Учебные года</Link>
                    </Menu.Item>

                    <Menu.Item key={'paid'}>
                        <Link to={ORDERS}>💰 Оплата питания</Link>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );

    const userMenu = (
        <Menu>
            <Menu.Item key={'settings'} disabled>
                <Link to={SETTINGS}>
                    <SettingOutlined /> Настройки
                </Link>
            </Menu.Item>
            <Menu.Item key={'logout'}>
                <Link to={LOGOUT}>
                    <LogoutOutlined /> Выйти
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <header className="crm-header-container">
            <div className="crm-header">
                <Space size={0}>
                    <Dropdown overlay={menu} trigger="click">
                        <MenuOutlined />
                    </Dropdown>
                    <Link to={INDEX}>
                        <img src={logo} className="crm-header-logo" alt="logo" />
                    </Link>
                </Space>

                {props.children}

                <Space size={0}>
                    <Button size="small" type="text">
                        {windowDimensions.width > 478 && `${firstName} ${lastName}`}
                    </Button>
                    <Dropdown overlay={userMenu} trigger="click">
                        <Avatar size="small" style={{ backgroundColor: color }}>
                            {firstName.charAt(0)}
                            {lastName.charAt(0)}
                        </Avatar>
                    </Dropdown>
                </Space>
            </div>
        </header>
    );
}

export default observer(CrmHeader);
