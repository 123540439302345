import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { message, Select, Spin } from 'antd';
import _ from 'lodash';

import { getIconByActivityName } from '../../config/activities';

const QUERY = gql`
    query users(
        $filters: ActivityFiltersInput
        $pagination: PaginationArg
        $sort: [String] = []
    ) {
        activities(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    coefficient
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const { Option } = Select;

export default function SelectActivity(field) {
    // Search
    const [searchText, setSearchText] = useState('');
    const onSearch = (value) => {
        setSearchText(value);
    };

    // Data
    const [data, setData] = useState([]);

    // Query
    const query = useQuery(QUERY, {
        variables: {
            filters: {
                name: { contains: searchText },
            },
            pagination: {
                limit: 200,
            },
            sort: ['coefficient:ASC'],
        },
        onCompleted: (data) => {
            setData(data.activities.data);
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка загрузки видов деятельности');
        },
    });

    // Render
    return (
        <Select
            {...field}
            filterOption={false}
            notFoundContent={query.loading ? <Spin size="small" /> : null}
            placeholder="Выберите вид деятельности"
            showSearch
            onSearch={onSearch}
            loading={query.loading}
        >
            <Option key={0} value={null}>
                Вид деятельности не выбран
            </Option>

            {data.length &&
                _.map(data, (value) => {
                    return (
                        <Option key={value.id} value={value.id}>
                            {getIconByActivityName(value.attributes.name)}{' '}
                            {value.attributes.name}
                        </Option>
                    );
                })}
        </Select>
    );
}
