export const colorGamma = [
    '#5b63fb',
    '#40fd5b',
    '#d042f1',
    '#f0974a',
    '#f86e69',
    '#f7d55c',
    '#f95c92',
    '#c6f550',
    '#faa066',
    '#e261ef',
    '#3e77fd',
    '#baf341',
    '#6ff5ab',
    '#5fa4ee',
];
// #5b63fb
// #40fd5b
// #d042f1
// #f0974a
// #f86e69
// #f7d55c
// #faa066
// #f95c92
// #c6f550
// #e261ef
// #3e77fd

function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

// Генерируем цвета пастельного оттенка
export function generateColor() {
    return hslToHex(
        360 * Math.random(),
        30 + 20 * Math.random(),
        80 + 10 * Math.random(),
    );
}
