import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Empty, message, Skeleton } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmHeader from '../../components/CrmHeader';
import FilterGroups from '../../components/Filters/FilterGroups';
import FilterQuarters from '../../components/Filters/FilterQuarters';
import Filters from '../../components/Filters/Filters';
import FilterSubjects from '../../components/Filters/FilterSubjects';
import FilterTrainers from '../../components/Filters/FilterTrainers';
import { Store } from '../../model/store/Store';
import { journal } from '../../routes';

import JournalGrid from './JournalGrid';

const GET_JOURNAL = gql`
    query journal(
        $groupId: ID!
        $quarterId: ID!
        $trainerId: ID!
        $subjectId: ID!
    ) {
        getJournal(
            groupId: $groupId
            quarterId: $quarterId
            trainerId: $trainerId
            subjectId: $subjectId
        ) {
            children {
                id
                attributes {
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                }
            }
            trainings {
                id
                attributes {
                    name
                    comment
                    startDate
                }
            }
            passes {
                id
            }
            marks {
                id
                attributes {
                    mark
                    activity {
                        data {
                            id
                            attributes {
                                name
                                coefficient
                            }
                        }
                    }
                    client {
                        data {
                            id
                        }
                    }
                    training {
                        data {
                            id
                        }
                    }
                }
            }
            quarter {
                id
                attributes {
                    name
                    startDate
                    endDate
                    year {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const _GET_YEAR = gql`
    query years($filters: YearFiltersInput, $pagination: PaginationArg = {}) {
        years(filters: $filters, pagination: $pagination) {
            data {
                id
                attributes {
                    name
                    quarters {
                        data {
                            id
                            attributes {
                                name
                                startDate
                                endDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

const _GET_TRAINIG = gql`
    query trainings(
        $filters: TrainingFiltersInput
        $groupId: ID
        $pagination: PaginationArg
        $sort: [String] = []
    ) {
        trainings(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    name
                    activities {
                        data {
                            id
                            attributes {
                                name
                                abbr
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
        group(id: $groupId) {
            data {
                id
                attributes {
                    name
                    users(
                        pagination: { limit: 200 }
                        sort: ["client.lastName:ASC", "client.firstName:ASC"]
                    ) {
                        data {
                            id
                            attributes {
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// функция определения фильтра
function _getTrainingFilter(years, quarter, trainer, group, subject) {
    let filters = {
        enabled: { eq: true },
    };

    if (years.length && quarter !== '0') {
        const quarters = years[0].attributes.quarters.data;
        const currentQuarter = _.find(quarters, { id: quarter });

        const startDate = moment(currentQuarter.attributes.startDate);
        const endDate = moment(currentQuarter.attributes.endDate);

        filters.startDate = { between: [startDate.format(), endDate.format()] };
    } else {
        return { id: { eq: '0' } };
    }

    if (trainer !== '0') {
        filters.trainers = { id: { in: [trainer] } };
    }

    if (group !== '0') {
        filters.group = { id: { eq: group } };
    }

    if (subject !== '0') {
        filters.subject = { id: { eq: subject } };
    }

    return filters;
}

function Journal() {
    const { quarter, trainer, group, subject } = useParams();
    const navigate = useNavigate();

    // если зашел в журналь учитель, то попал к себе
    const { auth } = useInstance(Store);
    useEffect(() => {
        if (trainer === '0') {
            const newTrainer = auth.role === 'Teacher' ? auth.id : 0;
            navigate(journal(quarter, newTrainer, group, subject), {
                replace: true,
            });
        }
    }, []);

    // изминение четверти
    function onChangeQuarter(value) {
        navigate(journal(value, trainer, group, subject));
    }

    // изминение класса
    function onChangeGroup(value) {
        navigate(journal(quarter, trainer, value, subject));
    }

    // изминение предмета
    function onChangeSubject(value) {
        navigate(journal(quarter, trainer, group, value));
    }

    // изминение учителя
    function onChangeTrainer(value) {
        navigate(journal(quarter, value, group, subject));
    }

    // загрузка данных учебного года
    // todo глобальный фильтр по учебному году
    // const [year, setYear] = useState([]);
    // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
    // const queryYear = useQuery(GET_YEAR, {
    //     variables: {
    //         filters: {
    //             startDate: {lte: '2022-09-01'},
    //         },
    //     },
    //     onCompleted: (data) => {
    //         setYear(data.years.data);
    //         // выбираем первую четверть и прокидываем в фильтр
    //         // const years = data.years.data;
    //         // если текущая четверть === 0, то перекидываем
    //         // if (quarter === '0') {
    //         //     const quarters = years[0].attributes.quarters.data;
    //         //     const firstQuarter = quarters[0].id;
    //         //     navigate(journal(firstQuarter, trainer, group, subject), {replace: true});
    //         // }
    //     },
    //     onError: (error) => {
    //         console.error(error);
    //         message.error('Ошибка получения данных');
    //     },
    // });

    // Главный запрос
    // для Teacher показывать только те  уроки, которые ведет Учитель
    // для Admin добавить фильтр по учителю / по классу
    const skip =
        quarter === '0' || trainer === '0' || subject === '0' || group === '0';
    // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
    const [data, setData] = useState();
    // const [groupData, setGroupData] = useState({});
    // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
    const query = useQuery(GET_JOURNAL, {
        variables: {
            quarterId: quarter,
            subjectId: subject,
            trainerId: trainer,
            groupId: group,
        },
        onCompleted: (data) => {
            setData(data.getJournal);
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения данных');
        },
        skip: skip,
    });

    // рендер
    return (
        <>
            <CrmHeader />

            <div className={'calendar-wrapper'}>
                <CrmCenteredTitle title={'Журнал'} />

                <div className={'crm-layout calendar-filter'}>
                    <Filters>
                        <FilterQuarters
                            withNull
                            value={quarter}
                            onChange={(value) => onChangeQuarter(value)}
                        />
                        <FilterGroups
                            withNull
                            value={group}
                            onChange={(value) => onChangeGroup(value)}
                        />
                        <FilterSubjects
                            withNull
                            value={subject}
                            onChange={(value) => onChangeSubject(value)}
                        />
                        <FilterTrainers
                            withNull
                            value={trainer}
                            filterSubject={subject}
                            onChange={(value) => onChangeTrainer(value)}
                        />
                    </Filters>
                </div>

                {query.loading && <Skeleton active />}
                {!query.loading && data && data.trainings.length === 0 && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {data && data.trainings.length > 0 && !query.loading && (
                    <JournalGrid
                        trainings={data.trainings}
                        users={data.children}
                        quarter={data.quarter}
                        marks={data.marks}
                        passes={data.passes}
                    />
                )}
            </div>
        </>
    );
}

export default observer(Journal);
