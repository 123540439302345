import React, { useEffect } from 'react';
import { Button, Form, Input, Radio } from 'antd';

import DateAndTimePicker from '../../components/Form/DateAndTimePicker';
import SelectGroup from '../../components/Form/SelectGroup';
import SelectRoom from '../../components/Form/SelectRoom';
import SelectSubject from '../../components/Form/SelectSubject';
import SelectTrainer from '../../components/Form/SelectTrainer';

export default function TrainingForm({
    data,
    onSubmit,
    loading,
    actionName,
    actionIcon,
    withSubject,
    withTrainer,
    withGroup,
    size,
}) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    const sizeData = size || 'large';

    useEffect(() => {
        form.resetFields();
    }, [data]);

    return (
        <Form
            name="task"
            form={form}
            onFinish={onFinish}
            initialValues={data}
            layout={'vertical'}
            size={sizeData}
            disabled={loading}
            scrollToFirstError
        >
            <Form.Item
                name={'startDate'}
                label={'Начало'}
                required
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <DateAndTimePicker
                    disabledHours={() => [
                        0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23,
                    ]}
                />
            </Form.Item>

            {withTrainer && (
                <Form.Item name={'trainers'} label={'Учитель'}>
                    <SelectTrainer />
                </Form.Item>
            )}

            {withSubject && (
                <Form.Item
                    name={'subject'}
                    label={'Предмет'}
                    required
                    rules={[{ required: true, message: 'Заполните поле' }]}
                >
                    <SelectSubject />
                </Form.Item>
            )}

            {withGroup && (
                <Form.Item
                    name={'group'}
                    label={'Класс'}
                    required
                    rules={[{ required: true, message: 'Заполните поле' }]}
                >
                    <SelectGroup />
                </Form.Item>
            )}

            <Form.Item name={'room'} label={'Кабинет'}>
                <SelectRoom />
            </Form.Item>

            <Form.Item name={'name'} label={'Тема урока'}>
                <Input />
            </Form.Item>

            <Form.Item name={'comment'} label={'Комментарий'}>
                <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>

            <Form.Item name={'massEdit'} label={'Внести изминения'} required rules={[{ required: true, message: 'Пожалуйста выберите' }]}>
                <Radio.Group>
                    <Radio.Button value={false}>Только этот урок</Radio.Button>
                    <Radio.Button value={true}>До конца четверти</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={actionIcon}
                    loading={loading}
                >
                    {actionName}
                </Button>
            </Form.Item>
        </Form>
    );
}
