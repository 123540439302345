import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';
import _ from 'lodash';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import FilterGroups from '../../components/Filters/FilterGroups';
import Filters from '../../components/Filters/Filters';
import RoleFunction from '../../config/roles';
import { group_info, GROUP_NEW } from '../../routes';

import './group.scss';
import '../../styles/grid.scss';

const QUERY = gql`
    query query($filters: GroupFiltersInput) {
        groups(
            filters: $filters
            pagination: { limit: 200 }
            sort: ["name:ASC"]
        ) {
            data {
                id
                attributes {
                    name
                    users(
                        pagination: { limit: 200 }
                        sort: ["client.lastName:ASC"]
                    ) {
                        data {
                            id
                            attributes {
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function getFilters(group) {
    let filters = {};

    if (group !== '0') {
        filters = { id: { eq: group } };
    }

    return filters;
}

export default function Groups() {
    const navigate = useNavigate();

    const [filterGroup, setFilterGroup] = useState('0');

    const [data, setData] = useState([]);

    const query = useQuery(QUERY, {
        variables: { filters: getFilters(filterGroup) },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setData(data.groups.data);
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Классы'}
                addAction={
                    RoleFunction.createGroup() &&
                    (() => {
                        navigate(GROUP_NEW);
                    })
                }
            />

            <Filters>
                <FilterGroups
                    className={'block'}
                    value={filterGroup}
                    withAll
                    onChange={(value) => setFilterGroup(value)}
                />
            </Filters>

            {query.loading && <Skeleton active />}

            {_.map(data, (group) => {
                // console.log('group', group);
                const users = group.attributes.users.data;

                return (
                    <div key={group.id} className={'crm-line group-list-item'}>
                        <Link to={group_info(group.id)}>
                            <span className={'bold'}>
                                {group.attributes.name}
                            </span>
                        </Link>
                        <Link to={group_info(group.id)}>
                            <span>Учеников: {users.length}</span>
                        </Link>
                    </div>
                );
            })}
        </CrmLayout>
    );
}
