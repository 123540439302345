import React from 'react';
import {CheckOutlined} from '@ant-design/icons';
import cn from 'classnames';

import CrmAvatar from '../../components/CrmAvatar';

export default function ClientSelectRow({user, group, selected, onClick}) {
    return (
        <div
            className={cn(['modal-selected-user-item', selected && 'selected'])}
            onClick={onClick}
        >
            <CrmAvatar
                firstName={user.attributes.firstName}
                lastName={user.attributes.lastName}
                color={user.attributes.color}
                caption={group && group.attributes.name}
            />
            {selected && <CheckOutlined/>}
        </div>
    );
}
