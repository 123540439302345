import _ from 'lodash';
import moment from 'moment/moment';

export function sortTrainings(trainings) {
    /*
    сгруппировать тренировки на дню по id предмета
    сортируем этот массив по id учителей, что бы подгруппы были в одном порядке
    при рендере проверять на длину массива[1]
    если массив -
        рендерим 2 или более тренировки в одной строке предмета в столбик
        при отрисовке оценок и пропусков сравниваем markTrainingId и trainingId
*/

    // 1 группируем по дням
    const groupByDays = _.values(
        _.groupBy(trainings, (days) => {
            return +moment(days.attributes.startDate).format('DDDD');
        }),
    );

    // 2 сортируем по дням
    const sortByDay = _.orderBy(
        groupByDays,
        (day) => {
            return +moment(day[0].attributes.startDate).format('DDDD');
        },
        'asc',
    );

    // 3 сортируем тренировки в каждом дне по id предмета, так же как и левую колонну предметов
    // порядок id предметов должен совпадать с порядком левого столбца предметов
    // важен только порядок дней и id предметов в этом дне, порядок часов и минут значения не имеет
    const sortById = _(sortByDay)
        .map((day) =>
            _(day)
                .sortBy((training) => +training.attributes.subject.data.id)
                .value(),
        )
        .value();

    // 4 идем по дням
    const groupById = _.map(sortById, (day) => {
        // 5 группируем уроки по id предмета - последовательность их на дню не важна
        const grouped = _.values(
            _.groupBy(day, (training) => {
                return training.attributes.subject.data.id;
            }),
        );

        // 6 сортируем подгруппы по id учителей (что бы все подгруппы в ряду предмета были в одном и том же порядке)
        return _.sortBy(grouped, (training) => {
            return +training[1] && training[0].attributes.trainers.data.id;
        });
    });

    // 7 избавляемся от группировки по дням
    // т.к. мы хотим итерироваться по общему массиву тренировок без привязки ко дню
    const flattened = _.flatten(groupById);

    // итоговый формат = [[урок],[урок-подгруппа],[урок],[урок]]
    // от одинарных массивов не избавляемся что бы было удобнее работать с данными

    return flattened;
}

export function groupTrainings(trainings) {
    const sortedTrainings = _.sortBy(trainings, (training) => {
        return [+moment(training.attributes.startDate).format('DDDD')];
    });

    const groupByMonths = _.values(
        // 2 конвертируем в массив
        _.groupBy(sortedTrainings, (months) => {
            // 1 группируем по месяцам
            return moment(months.attributes.startDate).format('MMMM');
        }),
    );

    const groupByDays = _.map(
        // 3 идем по месяцам
        groupByMonths,
        (month) => {
            const grouped = _.groupBy(month, (days) => {
                // 4 группируем по дням
                return moment(days.attributes.startDate).format('DD');
            });
            const groupedArray = _.values(
                // 5 конвертируем в массив
                grouped,
            );
            return groupedArray;
        },
    );

    const sortByDays = _.map(groupByDays, (month) => {
        // 6 идем по месяцам
        return _.orderBy(
            month,
            (day) => {
                // 7 сортируем по дням
                return +moment(day[0].attributes.startDate).format('DD');
            },
            'asc',
        );
    });

    // сортировка по id предметов тут не нужна - массив используется только для отрисовки месяцев и дней, а тренировки рисуем уже по sortTrainings()
    // &&
    // группировка по подгруппам (одинаковым id предмета на дню) тоже не нужна по той же причине

    const sortByMonths = _.sortBy(sortByDays, (arr) => {
        // 9 сортируем по месяцам
        return +moment(arr[0][0].attributes.startDate).format('M');
    });

    return sortByMonths;
}

export function groupMarks(marks) {
    // 1 группируем по тренировкам (если в одном занятии ученик получил 2 или более оценки)
    const groupedByTraining = _(marks)
        .groupBy((mark) => {
            return mark.attributes.training.data.id;
        })
        .values()
        .value();

    // сортировка и группировка по дням нужна для дальнейшей корректной сортировки по id предмета в каждом дне
    const sortByDay = _(groupedByTraining)
        .sortBy((mark) => {
            return +moment(mark[0].attributes.training.data.attributes.startDate).format('DDDD');
        })
        .value();

    const groupByDay = _(sortByDay)
        .groupBy((mark) => {
            return moment(mark[0].attributes.training.data.attributes.startDate).format('DDDD');
        })
        .values()
        .value();

    // 2 сортируем по id предмета
    const sortedMarks = _(groupByDay)
        .map((day) => {
            return _(day)
                .sortBy((mark) => {
                    return +mark[0].attributes.training.data.attributes.subject.data.id;
                })
                .value();
        })
        .flatten()
        .value();

    return sortedMarks;
}

export function groupMarksBySubject(marks) {
    return _(marks)
        .groupBy((mark) => {
            return mark.attributes.training.data.attributes.subject.data.id;
        })
        .values()
        .value();
}

export function sortPasses(passes) {
    const sortedPasses = _(passes)
        .groupBy((pass) => {
            return moment(pass.attributes.training.data.attributes.startDate).format('DDDD');
        })
        .values()
        .map((passGroup) => {
            return _(passGroup)
                .sortBy((pass) => {
                    return +pass.attributes.training.data.attributes.subject.data.id;
                })
                .value();
        })
        .flatten()
        .value();

    return sortedPasses;
}
