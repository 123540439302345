import React from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import _ from 'lodash';

import Mark from '../../components/Mark/Mark';

export default function AverageWeightMark({trainings}) {

    // let sum = 0;

    let allMarks = [];

    _.forEach(trainings, (training) => {
        const marks = training.marks;
        _.forEach(marks, (mark) => {
            if (mark) {
                allMarks.push(mark);
            }
        })
    });

    let activityError = false;
    let averageMarksSum = 0;
    let averageWeightsSum = 0;
    _.forEach(allMarks, (mark) => {
        const activity = mark.attributes.activity.data;
        let coefficient = 0;
        if (activity) {
            coefficient = activity.attributes.coefficient;
        } else {
            activityError = true;
        }
        averageMarksSum += mark.attributes.mark * coefficient;
        averageWeightsSum += coefficient;
    });
    const averageWeightMark = averageWeightsSum > 0 ? Math.round(averageMarksSum / averageWeightsSum * 10) / 10 : 0;


    return (
        <div className={'srvz'}>

            <Mark mark={averageWeightMark} activity={{attributes: {name: 'Средний взвешенный балл/\nИтоговая оценка'}}} comment={''} className={'____________'}/>
            {activityError && <span className={'color-danger'}> <ExclamationCircleOutlined/></span>}

        </div>
    );
}
