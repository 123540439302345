import React, {useEffect, useRef, useState} from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Empty, message, Skeleton } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmHeader from '../../components/CrmHeader';
import { FilterChild } from '../../components/Filters/FilterChild';
import FilterGroups from '../../components/Filters/FilterGroups';
import FilterQuarters from '../../components/Filters/FilterQuarters';
import Filters from '../../components/Filters/Filters';
import RoleFunctions from '../../config/roles';
import { Store } from '../../model/store/Store';
import { progress } from '../../routes';

import ProgressGrid from './ProgressGrid';

const GET_PROGRESS = gql`
    query getProgress($childId: ID!, $quarterId: ID!) {
        getProgress(childId: $childId, quarterId: $quarterId) {
            subjects {
                id
                attributes {
                    name
                }
            }
            trainings {
                id
                attributes {
                    name
                    comment
                    startDate
                    trainers {
                        data {
                            id
                        }
                    }
                    subject {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
            passes {
                id
                attributes {
                    training {
                        data {
                            id
                            attributes {
                                startDate
                                subject {
                                    data {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
            marks {
                id
                attributes {
                    mark
                    activity {
                        data {
                            id
                            attributes {
                                name
                                coefficient
                            }
                        }
                    }
                    client {
                        data {
                            id
                        }
                    }
                    training {
                        data {
                            id
                            attributes {
                                startDate
                                subject {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            quarter {
                id
                attributes {
                    name
                    startDate
                    endDate
                    year {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

function Progress() {
    const [data, setData] = useState({
        trainings: [],
        subjects: [],
        marks: [],
        passes: [],
    });

    const firstLoading = useRef(true)

    const { quarter, child, group } = useParams();
    const navigate = useNavigate();

    // если зашел в журнал ребенок, то попал к себе
    // если зашел родитель, то ему доступен фильтр с его детьми
    const { auth } = useInstance(Store);
    useEffect(() => {
        if (child === '0' && auth.role === 'Child') {
            navigate(progress(quarter, group, auth.data.personId), {
                replace: true,
            });
        }
    }, []);

    const query = useQuery(GET_PROGRESS, {
        variables: {
            quarterId: quarter,
            ...(auth.role === 'Child'
                ? { childId: auth.data.personId }
                : { childId: child }),
        },
        onCompleted: (data) => {
            firstLoading.current = false
            setData({
                trainings: data.getProgress.trainings,
                subjects: _.orderBy(data.getProgress.subjects, (el) => {
                    return +el.id
                }),
                marks: data.getProgress.marks,
                passes: data.getProgress.passes,
            });
        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения данных');
        },
        skip: child === '0' || quarter === '0',
    });

    // изменение четверти
    function onChangeQuarter(value) {
        navigate(progress(value, group, child));
    }

    // изменение класса
    function onChangeGroup(value) {
        navigate(progress(quarter, value, 0));
    }

    // изменение ребенка
    function onChangeChild(value) {
        navigate(progress(quarter, group, value));
    }

    return (
        <>
            <CrmHeader />

            <div className={'calendar-wrapper'}>
                <CrmCenteredTitle title={'Успеваемость'} />

                <div className={'crm-layout calendar-filter'}>
                    <Filters>
                        <FilterQuarters
                            withNull
                            value={quarter}
                            onChange={(value) => onChangeQuarter(value)}
                        />
                        {RoleFunctions.progressShowFilter() && (
                            <>
                                <FilterGroups
                                    withNull
                                    value={group}
                                    onChange={(value) => onChangeGroup(value)}
                                />
                                <FilterChild
                                    withNull
                                    value={child}
                                    onChange={(value) => onChangeChild(value)}
                                    group={group}
                                />
                            </>
                        )}
                    </Filters>
                </div>

                {query.loading && firstLoading.current && <Skeleton active />}

                {(child === '0' || quarter === '0') || (data && data.trainings.length === 0)
                    ?
                    query.loading && firstLoading.current || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    :
                    <ProgressGrid
                        trainings={data.trainings}
                        marks={data.marks}
                        passes={data.passes}
                        childId={child}
                        refetch={() => query.refetch()}
                    />
                }

            </div>
        </>
    );
}

export default observer(Progress);
