import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {SaveOutlined} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import {NOT_FOUND, YEARS} from '../../routes';

import { YearForm } from './YearForm';

const GET_YEAR = gql`
    query year($id: ID) {
        year(id: $id) {
            data {
                id
                attributes {
                    name
                    quarters {
                        data {
                            id
                            attributes {
                                name
                                startDate
                                endDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE_YEAR = gql`
    mutation updateYear($data: YearInput!, $id: ID!) {
        updateYear(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const UPDATE_QUARTER = gql`
    mutation updateQuarter($data: QuarterInput!, $id: ID!) {
        updateQuarter(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const CREATE_QUARTER = gql`
    mutation createQuarter($data: QuarterInput!) {
        createQuarter(data: $data) {
            data {
                id
            }
        }
    }
`;

const DELETE_QUATRE = gql`
    mutation deleteQuarter($id: ID!) {
        deleteQuarter(id: $id) {
            data {
                id
            }
        }
    }
`;

export const YearEdit = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [updateYear] = useMutation(UPDATE_YEAR);
    const [updateQuarter] = useMutation(UPDATE_QUARTER);
    const [createQuarter] = useMutation(CREATE_QUARTER);
    const [deleteQuarter] = useMutation(DELETE_QUATRE);

    const [loading, setLoading] = useState(false);

    const [yearData, setYearData] = useState();

    const initialQuarterLength = useRef([]);

    useQuery(GET_YEAR, {
        variables: {
            id: id,
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            !data.year.data && navigate(NOT_FOUND);
            setYearData(data.year.data);
            initialQuarterLength.current =
                data.year.data.attributes.quarters.data;
        },
    });

    function updateQuarters(modifyQuarters) {
        const initialQuarter = initialQuarterLength.current;

        if (initialQuarter.length === modifyQuarters.length) {
            // update
            _.map(modifyQuarters, async (quarter, i) => {
                await updateQuarter({
                    variables: {
                        data: {
                            startDate: moment(quarter.startDate)
                                .startOf('day')
                                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
                            endDate: moment(quarter.endDate)
                                .endOf('day')
                                .format('YYYY-MM-DD'),
                            name: quarter.name,
                        },
                        id: yearData.attributes.quarters.data[i].id,
                    },
                });
            });
        } else if (
            initialQuarter.length < modifyQuarters.length
        ) {
            // add
            _.map(initialQuarter, async (_el, i) => {
                await updateQuarter({
                    variables: {
                        data: {
                            startDate: moment(modifyQuarters[i].startDate)
                                .startOf('day')
                                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
                            endDate: moment(modifyQuarters[i].endDate)
                                .endOf('day')
                                .format('YYYY-MM-DD'),
                            name: modifyQuarters[i].name,
                        },
                        id: yearData.attributes.quarters.data[i].id,
                    },
                });
            });

            const newQuarters = _.drop(modifyQuarters, initialQuarter.length)

            _.map(newQuarters, async (quarter) => {
                await createQuarter({
                    variables: {
                        data: {
                            endDate: moment(quarter.endDate)
                                .endOf('day')
                                .format('YYYY-MM-DD'),
                            startDate: moment(quarter.startDate)
                                .startOf('day')
                                .format('YYYY-MM-DD'),
                            name: quarter.name,
                            year: id,
                        },
                    },
                })
            })
        } else {
            // delete
            _.map(modifyQuarters, async (_quarter, i) => {
                await updateQuarter({
                    variables: {
                        data: {
                            startDate: moment(modifyQuarters[i].startDate)
                                .startOf('day')
                                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
                            endDate: moment(modifyQuarters[i].endDate)
                                .endOf('day')
                                .format('YYYY-MM-DD'),
                            name: modifyQuarters[i].name,
                        },
                        id: yearData.attributes.quarters.data[i].id,
                    },
                });
            });

            const deletedQuarters = _.drop(initialQuarter, modifyQuarters.length)

            _.map(deletedQuarters, async (quarter) => {
                await deleteQuarter({
                    variables: {
                        id: quarter.id,
                    },
                });
            });
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);

        const yearData = {
            startDate: moment()
                .set('year', data.name.split('/')[0]) // достаем начальную дату из названия
                .startOf('year')
                .format('YYYY-MM-DD'), // в этом запросе дата должна идти в обратном порядке
            endDate: moment()
                .set('year', data.name.split('/')[0]) // достаем конечную дату из названия
                .endOf('year')
                .format('YYYY-MM-DD'),
            name: data.name
        };

        await updateYear({
            variables: {
                data: yearData,
                id: id,
            },
        });

        updateQuarters(data.quarters)

        navigate(YEARS);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Изменить учебный год'} />
            {yearData && (
                <YearForm
                    onSubmit={onSubmit}
                    loading={loading}
                    actionName={'Сохранить'}
                    actionIcon={<SaveOutlined />}
                    initialData={yearData}
                />
            )}
        </CrmLayout>
    );
};
