import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveOutlined} from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Skeleton } from 'antd';
import moment from 'moment';

import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import CrmLayout from '../../components/CrmLayout';
import { NOT_FOUND } from '../../routes';

import HomeworkForm from './HomeworkForm';

const GET_TASK = gql`
    query task($id: ID) {
        task(id: $id) {
            data {
                id
                attributes {
                    name
                    comment
                    group {
                        data {
                            id
                        }
                    }
                    trainingTo {
                        data {
                            id
                            attributes {
                                subject {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                startDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

const EDIT_TASK = gql`
    mutation updateTask($data: TaskInput!, $id: ID!) {
        updateTask(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export const HomeworkEdit = () => {
    const { id } = useParams();

    const [data, setData] = useState(null);
    const oldTaskData = useRef({
        name: '',
        date: '2000-00-00',
    });

    const query = useQuery(GET_TASK, {
        variables: { id: id },
        onCompleted: (data) => {
            !data.task.data && navigate(NOT_FOUND);

            oldTaskData.current = {
                name: data.task.data.attributes.trainingTo.data.attributes
                    .subject.data.attributes.name,
                date: moment(
                    data.task.data.attributes.trainingTo.data.attributes
                        .startDate,
                ).format('DD.MM.YYYY'),
            };

            setData({
                data: {
                    name: data.task.data.attributes.name,
                    comment: data.task.data.attributes.comment,
                    trainingTo: {
                        value: `${data.task.data.attributes.trainingTo.data.id}|${data.task.data.attributes.trainingTo.data.attributes.startDate}`,
                        label: `${oldTaskData.current.name}, ${oldTaskData.current.date}`,
                    },
                },
                subject:
                    data.task.data.attributes.trainingTo.data.attributes.subject
                        .data.id,
                group: data.task.data.attributes.group.data.id,
            });
        },
        onError: () => {
            message.error('Ошибка обработки данных');
        },
    });

    const [editTask, resEditTask] = useMutation(EDIT_TASK);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        await editTask({
            variables: {
                id: id,
                data: {
                    comment: data.comment,
                    name: data.name,
                    ...(data.trainingTo.includes(oldTaskData.current.name)
                        ? {}
                        : {
                            trainingTo: data.trainingTo,
                            dueDate: data.dueDate,
                        }),
                },
            },
        });
        navigate(-1);
    };

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Изменить задание'} />
            {query.loading && <Skeleton active />}

            {data && (
                <>
                    <HomeworkForm
                        onSubmit={onSubmit}
                        loading={resEditTask.loading}
                        actionName={'Сохранить'}
                        actionIcon={<SaveOutlined />}
                        subject={data.subject}
                        group={data.group}
                        data={data.data}
                    />
                </>
            )}
        </CrmLayout>
    );
};

