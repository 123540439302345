import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './filters.scss';

export default function FilterBack(field) {
    return (
        <span className={'filter'}>
            <Button
                type={'link'}
                size={'small'}
                icon={<ArrowLeftOutlined />}
                {...field}
            />
        </span>
    );
}
